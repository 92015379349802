import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { ConfigContext } from '../App/ConfigProvider'
import Agreement from './Agreement'

import './AgreementContent1.css'

export default function AgreementContent1() {
    const { language } = useContext(ConfigContext)
    return (
        <div id="agreement_content1_container">
            <Helmet>
                <title>{language.translations.titles.agree1}</title>
                <meta
                    name="description"
                    content="공유를 통해 혜택을 나누는 버즈링크"
                />
            </Helmet>
            <Agreement menu={0} />
            <div id="agreement_content1_wrapper">
                <div className="agreement_content1_template01">
                    서비스 이용약관
                </div>

                <div className="agreement_content1_template02">제1장 총칙</div>

                <div className="agreement_content1_template03">
                    제1조 약관의 목적
                </div>
                <ol>
                    <li className="agreement_content1_template04">
                        서비스 이용 약관(이하 ‘약관’이라 합니다)은 회원이
                        (주)BreezeLabs(이하 ‘회사‘라 합니다)가 제공하는
                        BreezeLabs의 웹사이트, 앱, API 등(이하 "버즈링크" 또는
                        "서비스"라 합니다) 사용에 관한 제반사항을 규정합니다
                    </li>
                </ol>

                <div className="agreement_content1_template03">
                    제2조 약관의 게시 및 효력
                </div>
                <ol>
                    <li className="agreement_content1_template04">
                        회원이 본 약관에 동의함은 본 서비스와 관련하여 회사에서
                        제공하거나 제공할 모든 서비스에 동의하는 것으로 봅니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회사는 이 약관의 내용을 이용자가 쉽게 알 수 있도록
                        서비스 초기 화면 또는 연결화면을 통해 게시하며, 필요한
                        경우 관련 법령을 위배하지 않는 범위 내에서 약관을 개정할
                        수 있습니다.
                    </li>
                    <li className="agreement_content1_template04">
                        약관을 개정할 경우 개정내용과 적용일자 및 개정사유를
                        명시하여 적용일자 30일 전부터 적용일자 전일까지
                        공지합니다. 다만, 사용자에게 불리하게 개정되는 경우
                        적용일자 30일전부터 공지합니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회원이 명시적으로 거부의 의사표시를 하지 않은 경우
                        회원이 이 개정 약관에 동의한 것으로 봅니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회원은 개정 약관에 동의하지 않는 경우 적용일자 전일까지
                        회사에 거부의사를 표시하고 이용계약을 해지할 수
                        있습니다.
                    </li>
                    <li className="agreement_content1_template04">
                        본 약관에 규정되지 않은 사항에 대해서는 대한민국의 관련
                        법령 또는 회사가 정한 서비스의 개별 이용약관, 운영정책
                        및 규칙 등(이하 ‘세부지침’이라 합니다)의 규정에
                        따릅니다. 또한 본 약관과 세부지침의 내용이 충돌할 경우
                        세부지침에 따릅니다.
                    </li>
                </ol>

                <div className="agreement_content1_template02">
                    제2장 서비스의 설명
                </div>

                <div className="agreement_content1_template03">
                    제3조 용어의 정의
                </div>
                <ol>
                    <li className="agreement_content1_template04">
                        약관에서 사용하는 정의는 아래와 같습니다. 본 조에서
                        정의되지 아니한 용어의 정의는 회사가 영위하고 있는
                        사업의 동종 업계에서 통용되는 용어 및 이에 준하는 용어의
                        정의에 따릅니다.
                    </li>
                    <li className="agreement_content1_template04">
                        ‘회원‘이라 함은 회사의 서비스에 접속하여 이 약관에 따라
                        회사와 이용계약을 체결하고 회사가 제공하는 서비스를
                        이용하는 고객을 말합니다.
                    </li>
                    <li className="agreement_content1_template04">
                        ‘프로듀서’는 버즈링크를 통해 특정 컨텐츠 및 상품을
                        홍보하거나 확산시킬 목적으로 약관에 따라 회사의 제공
                        서비스를 이용하는 회원입니다.{' '}
                    </li>
                    <li className="agreement_content1_template04">
                        ‘버저’는 버즈링크를 통해 링크를 생성하여, 공유하는
                        행위를 통해 확산에 기여하고 이에 따른 리워드를 수령하며
                        약관에 따라 회사의 제공 서비스를 이용하는 회원입니다.{' '}
                    </li>
                    <li className="agreement_content1_template04">
                        버즈링크를 통해 생성된 링크(이하‘링크‘라고 합니다)로
                        진행되는 공유만 기여에 따른 리워드를 수령할 수 있으며,
                        ’링크’는 회사가 지정한 방법을 통해서만 생성할 수
                        있습니다.{' '}
                    </li>
                    <li className="agreement_content1_template04">
                        ‘링크’는 블록체인을 통해 기록되며, 리워드의 규칙은
                        블록체인상의 스마트 컨트랙트를 통해 정의됩니다.{' '}
                    </li>
                    <li className="agreement_content1_template04">
                        ‘리워드‘는 ’프로듀서’가 지정한 행동이 수행 되었을 때,
                        ’버저’ 에게 지급하는 환금성 있는 일종의 포인트로 회사가
                        지정한 방법으로 사용이 가능합니다.{' '}
                    </li>
                </ol>

                <div className="agreement_content1_template03">
                    제4조 서비스의 내용
                </div>
                <ol>
                    <li className="agreement_content1_template04">
                        버즈링크는 ‘프로듀서‘가 특정 컨텐츠 및 상품을 확산,
                        홍보를 목적으로 버즈링크를 통해 URL 형태 링크로 공유를
                        요청하고,{' '}
                    </li>
                    <li className="agreement_content1_template04">
                        공유 및 확산과정에서 이를 접한 피공유자가 지정된 행동을
                        수행했을 때,{' '}
                    </li>
                    <ol>
                        <li className="agreement_content1_template05">
                            ‘지정된 행동‘은 가입, 구매, 피드백 행위, 설치 등
                            객관적인 데이터로 행동의 진위여부를 확인할 수 있는
                            것으로 회사가 지정한 방법을 따릅니다.{' '}
                        </li>
                    </ol>
                    <li className="agreement_content1_template04">
                        이에 따른 리워드를 공유행위를 통해 확산에 기여한
                        ’버저’들에게 일정한 비율로 배분하는 서비스입니다.{' '}
                    </li>
                    <ol>
                        <li className="agreement_content1_template05">
                            ‘기여‘는 지정된 행동이 일어난 직전 공유자를 기준으로
                            차등 분배를 기본으로 하며 분배 비율은 프로듀서와
                            회사가 협의하여 정할 수 있습니다.{' '}
                        </li>
                    </ol>
                </ol>

                <div className="agreement_content1_template02">
                    제3장 서비스의 이용
                </div>

                <div className="agreement_content1_template03">
                    제5조 서비스의 제공
                </div>
                <ol>
                    <li className="agreement_content1_template04">
                        회사가 본건 서비스에서 제공하는 사항은 다음과 같습니다.{' '}
                    </li>
                    <ol>
                        <li className="agreement_content1_template05">
                            공유를 위한 링크의 생성 및 관리
                        </li>
                        <li className="agreement_content1_template05">
                            블록체인을 이용한 리워드의 분배 및 정산
                        </li>
                        <li className="agreement_content1_template05">
                            리워드의 사용을 위한 리워드 전환 방법
                        </li>
                    </ol>
                    <li className="agreement_content1_template04">
                        회사는 서비스를 운영함에 있어 필요하다고 인정되는 다양한
                        정보나 광고 등을 서비스 화면에 게재하거나 이메일, 서비스
                        알림, SMS 등의 방법으로 회원에게 제공할 수 있습니다.
                        이를 수신한 회원은 수신을 거부할 수 있으며, 회사는 수신
                        거부를 위한 방법을 회원에게 제공합니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회사는 제 2항의 정보나 광고 등을 회원에게 이메일, 알림,
                        SMS 등을 통하여 발송하는 경우 수신 동의 여부를 확인하여,
                        수신 동의한 회원에 한하여 이를 발송합니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회사는 관련법령과 약관을 준수하며, 계속적이고 안정적으로
                        서비스를 제공하기 위하여 최선을 다하여 노력합니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회사는 서비스이용과 관련하여 회원으로부터 제기된
                        의견이나 불만이 정당하다고 인정할 경우에는 이를
                        처리하여야 합니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회사는 운영상, 기술상의 필요 등 상당한 이유가 있는
                        경우에 제공하고 있는 본건 서비스의 전부 또는 일부를
                        변경할 수 있습니다. 이 경우, 회사는 사전에 변경 이유와
                        내용, 일자 등을 공지사항 또는 본건 서비스 페이지에
                        게시하여야 합니다.
                    </li>
                </ol>

                <div className="agreement_content1_template03">
                    제6조 계정의 관리
                </div>
                <ol>
                    <li className="agreement_content1_template04">
                        ‘계정’이란 서비스에서 회원을 식별하기 위하여 미리 등록한
                        문자, 특수문자, 숫자 등의 조합으로 본 서비스에서는
                        이메일 주소로 표시합니다. 회원이 본 약관에 동의하고
                        회원의 계정과 비밀번호를 등록한 후 필요한 경우 회사가
                        요구한 이메일 주소 또는 휴대전화번호 본인확인 서비스를
                        통하여 회원이 본인 인증 절차를 이행하면 회사가 가입을
                        승낙하는 절차로 생성됩니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회원이 서비스를 이용하기 위하여 계정을 필요로 하는 경우,
                        회원은 자신의 진실된 정보를 기재하여 생성한 계정을
                        이용하여야 하고, 타인의 개인정보를 도용하거나 또는 이에
                        준하는 방법으로 관련 법령을 위반하여서는 아니됩니다.
                        회사는 회원이 제공한 정보가 사실과 일치하는지 여부를
                        확인하기 위하여 법령에 의하여 허용된 범위에서 전문기관을
                        통한 실명확인 또는 본인인증을 요청할 수 있습니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회원의 아이디와 비밀번호에 관한 관리책임은 회원에게
                        있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고
                        있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의
                        안내에 따라야 합니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회원은 가입신청 시 기재한 사항이 변경되었을 경우
                        정보수정 에서 변경을 하거나 회사의 고객센터를 통하여
                        회사에게 그 변경사항을 알려야 합니다.
                    </li>
                    <li className="agreement_content1_template04">
                        제4항 및 제5항의 경우에 해당 회원이 회사에 그 사실을
                        통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지
                        않아 발생한 불이익에 대하여 회사는 책임지지 않습니다
                    </li>
                </ol>

                <div className="agreement_content1_template03">
                    제7조 리워드의 적립 및 사용
                </div>
                <ol>
                    <li className="agreement_content1_template04">
                        리워드는 버즈포인트 BuzPoint(이하 BP 라 합니다)로
                        지급되며 서비스 이용에 따라 회사가 회원에게 지급하는
                        포인트입니다.{' '}
                    </li>
                    <ol>
                        <li className="agreement_content1_template05">
                            BP는 ‘링크’ 공유를 통해 링크를 전달받은 이용자가
                            약관에 명시된 지정된 행동을 수행하여 그 진위가
                            확인이 되었을 때 지급됩니다.{' '}
                        </li>
                        <li className="agreement_content1_template05">
                            지정된 행동의 진위여부 확인은 회사와 ‘프로듀서‘가
                            사전에 약정 및 협의한 방법을 따릅니다.
                        </li>
                    </ol>
                    <li className="agreement_content1_template04">
                        회원이 보유한 BP는 사용을 위해 회사가 정한 방법을 통해
                        전환이 가능합니다. 전환 시 BP는 1BP당 1원(1₩)으로
                        전환함을 원칙으로 합니다. 단, 블록체인 토큰등으로의
                        전환하는 경우 그에 따른 전환비율은 변경될 수 있으며 해당
                        비율은 전환 시점에 회원이 인지할 수 있도록 해당 페이지
                        내에 게시합니다.{' '}
                    </li>
                    <li className="agreement_content1_template04">
                        BP의 적립 및 사용을 위하여 회원에게 일정한 자격요건이
                        요구될 수 있으며, 회원은 BP를 타인에게 양도하거나 대여
                        또는 담보의 목적으로 이용할 수 없습니다. 다만, 회사가
                        인정하는 적법한 절차를 따른 경우에는 예외로 합니다.{' '}
                    </li>
                    <li className="agreement_content1_template04">
                        회사와 ‘프로듀서‘간의 정산과정에서 결제하지 않은 금액이
                        발생하거나 ’프로듀서‘의 파산, 부도 등 지급불능 상태가
                        발생할 때에는 이용자에게 부여된 BP가 취소될 수 있습니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회원에게 지급된 BP는 제8조[이용 방법 및 주의점]에 따라
                        회사가 회수 및 사용 제한 조치를 취할 수 있습니다.{' '}
                    </li>
                </ol>

                <div className="agreement_content1_template03">
                    제8조 이용 방법 및 주의점
                </div>
                <ol>
                    <li className="agreement_content1_template04">
                        회원은 생성된 계정을 통해 서비스를 자유롭게 이용할 수
                        있으나, 아래와 같은 행위가 있는 경우 서비스 이용이
                        제한될 수 있습니다.{' '}
                    </li>
                    <ol>
                        <li className="agreement_content1_template05">
                            회원가입을 신청하거나 회원정보 변경을 신청할 때 허위
                            또는 타인의 정보를 입력하는 행위
                        </li>
                        <li className="agreement_content1_template05">
                            회사 또는 제 3자의 저작권 등 지적재산권을 침해하는
                            행위
                        </li>
                        <li className="agreement_content1_template05">
                            회사 또는 제 3자의 명예를 손상시키거나 업무를
                            방해하는 행위
                        </li>
                        <li className="agreement_content1_template05">
                            회사의 서비스에 게시된 정보를 변경하거나 서비스를
                            이용하여 얻은 정보를 회사의 동의 없이 영리 또는
                            비영리의 목적으로 사용하는 행위
                        </li>
                        <li className="agreement_content1_template05">
                            부정한 방법을 사용하여 링크를 생성 및 공유하는 행위{' '}
                        </li>
                        <li className="agreement_content1_template05">
                            회사가 지정한 방법이 아닌 다른 방법으로 리워드를
                            사용하는 행위{' '}
                        </li>
                        <li className="agreement_content1_template05">
                            서비스 이용 권한을 타인에게 공유, 제공, 양도, 중개,
                            재판매하는 행위
                        </li>
                        <li className="agreement_content1_template05">
                            회원”이 회사와 계약된 방법 이외의 부정한 수익을
                            추구하여 회사에 고의적으로 손실을 입히는 행위
                        </li>
                        <li className="agreement_content1_template05">
                            기타 불법적이거나 부당한 행위
                        </li>
                    </ol>
                    <li className="agreement_content1_template04">
                        회원이 관련 법령, 회사의 모든 약관 또는 정책을 준수하지
                        않는 경우, 회사는 회원의 위반행위 등을 조사하거나 회원의
                        서비스 이용을 잠시 또는 계속하여 중단할 수 있고, 회원의
                        서비스 재가입에 제한을 둘 수 있습니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회사는 회원이 ‘저작권법‘ 및 '컴퓨터프로그램보호법’을
                        위반한 불법프로그램의 제공 및 운영방해, ‘정보통신망법’을
                        위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한
                        초과행위 등과 같이 관련법을 위반한 경우에는 즉시
                        영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지
                        시 ‘서비스’ 이용을 통해 획득한 ‘리워드’ 및 기타 혜택
                        등도 모두 소멸되며, 회사는 이에 대해 별도로 보상하지
                        않습니다.
                    </li>
                    <li className="agreement_content1_template04">
                        본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는
                        경우에는 회사는 제10조[통지 및 공지]에 따라 통지합니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회원은 본 조에 따른 이용제한 등에 대해 회사가 정한
                        절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가
                        정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의
                        이용을 재개합니다.
                    </li>
                </ol>

                <div className="agreement_content1_template03">
                    제9조 이용 계약의 해지
                </div>
                <ol>
                    <li className="agreement_content1_template04">
                        회원은 언제든지 고객센터 또는 내 정보 관리 메뉴 등을
                        통해 이용계약의 해지를 신청할 수 있으며, 회사는 관련
                        법령이 정하는 바에 따라 이를 즉시 처리하여야 합니다.
                        다만, 회원의 해지 신청 시 미정산 내역이 있는 경우 회사가
                        별도로 정한 운영정책에 따라 정산절차 완료 후 계약을
                        해지합니다.
                    </li>
                </ol>

                <div className="agreement_content1_template03">
                    제10조 개인정보의 보호
                </div>
                <ol>
                    <li className="agreement_content1_template04">
                        회사는 ‘정보통신망법’ 등 관계 법령이 정하는 바에 따라
                        회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의
                        보호 및 사용에 대해서는 관련법 및 회사의
                        개인정보처리방침이 적용됩니다. 다만, 회사의 공식 사이트
                        이외의 링크된 사이트에서는 회사의 개인정보처리방침이
                        적용되지 않습니다.
                    </li>
                </ol>

                <div className="agreement_content1_template02">
                    제4장 기타 안내
                </div>

                <div className="agreement_content1_template03">
                    제11조 통지 및 공지
                </div>
                <ol>
                    <li className="agreement_content1_template04">
                        회사가 회원에 대하여 통지를 하는 경우, ‘약관’에 별도의
                        규정이 없는 한 회원이 제공한 전자우편주소, 휴대 전화번호
                        등으로 할 수 있습니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회사는 회원전체에 대하여 통지하는 경우 7일 이상 서비스내
                        게시판에 게시함으로써 전항의 통지를 갈음할 수 있습니다.
                        다만, 서비스이용에 중대한 영향을 주는 사항에 대해서는
                        전항에 따른 방법으로 통지합니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회사가 제 1항의 방법에 따라 정상적으로 발송하거나, 제
                        2항의 방법에 따라 게시하는 경우 통지가 도달한 것으로
                        보며, 회사에 제공한 전자우편주소, 휴대 전화번호 등이
                        사실과 다르거나 회원이 통지를 확인하지 않아 발생한
                        문제에 대하여 회사는 책임을 지지 않습니다.
                    </li>
                </ol>

                <div className="agreement_content1_template03">
                    제12조 책임의 제한
                </div>
                <ol>
                    <li className="agreement_content1_template04">
                        회사는 천재지변 또는 이에 준하는 불가항력으로 인하여
                        서비스를 제공할 수 없는 경우에는 서비스 제공에 관한
                        책임이 면제됩니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회사는 회원의 귀책사유로 인한 서비스 이용의 장애에
                        대하여는 책임을 지지 않습니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회사는 회원이 서비스와 관련하여 게재한 정보, 자료,
                        사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지
                        않습니다.
                    </li>
                </ol>

                <div className="agreement_content1_template03">
                    제13조 준거법
                </div>
                <ol>
                    <li className="agreement_content1_template04">
                        회사와 회원간 발생한 분쟁에 대하여는 대한민국법을
                        준거법으로 합니다.
                    </li>
                    <li className="agreement_content1_template04">
                        회사와 “회원간 발생한 분쟁에 관한 소송은 민사소송법 상의
                        관할법원에 제소합니다.
                    </li>
                </ol>
            </div>
        </div>
    )
}
