import React, { useEffect } from 'react'
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom'
import Nav from '../Nav/Nav'
import { Footer } from '../Footer/Footer'
import Main from '../Main/Main'

import ErrorView from './ErrorView/ErrorView'
import { ConfigProvider } from './ConfigProvider'

import { PopupProvider } from './PopupProvider'
import CommonPopup from './Common/CommonPopup'
import Support from '../Support/Support'
import AgreementContent2 from '../Agreement/AgreementContent2'
import AgreementContent1 from '../Agreement/AgreementContent1'
import BuzFriends from '../BuzFriends/BuzFriends'
import WhatIsBuz from '../WhatIsBuz/WhatIsBuz'
import BuzmapIntro from '../Buzmap/BuzmapIntro/BuzmapIntro'
import BuzPointIntro from '../BuzPointIntro/BuzPointIntro'

export const PATHS = {
    home: '/',
    buzfriends: '/buz_friends',
    buzpoint: '/buzpoint',
    history: '/history',
    support: '/support',
    sdk_sample: '/sdk_sample',
    error: '/error',
    whatisbuz: '/whatisbuz',
    agreement: '/agreement',
    terms: '/terms',
    buzmap: '/buzmap',
}

export var lastPath = []
export default function App() {
    const isIE = /*@cc_on!@*/ false || !!document.documentMode
    if (isIE) {
        alert(
            '인터넷 익스플로러에서는 정상적으로 동작하지 않습니다.\n다른 브라우저에서 실행해 주세요.\n(Microsoft Edge, Chrome, Safari)'
        )
    }

    return (
        <PopupProvider>
            <ConfigProvider>
                <Router className="wrapper">
                    <div>
                        <Nav />
                        <div>
                            <Switch>
                                <Route
                                    exact
                                    path={PATHS.home}
                                    render={(props) => {
                                        lastPath.push(PATHS.home)
                                        return <Main {...props} />
                                    }}
                                />

                                <Route
                                    path={PATHS.whatisbuz}
                                    render={(props) => {
                                        lastPath.push(PATHS.whatisbuz)
                                        return <WhatIsBuz {...props} />
                                    }}
                                />

                                <Route
                                    path={PATHS.buzmap}
                                    render={(props) => {
                                        lastPath.push(PATHS.buzmap)
                                        return <BuzmapIntro {...props} />
                                    }}
                                />

                                <Route
                                    path={PATHS.buzpoint}
                                    render={(props) => {
                                        lastPath.push(PATHS.buzpoint)
                                        return <BuzPointIntro {...props} />
                                    }}
                                />

                                <Route
                                    path={PATHS.buzfriends}
                                    render={(props) => {
                                        lastPath.push(PATHS.buzfriends)
                                        return <BuzFriends {...props} />
                                    }}
                                />

                                <Route
                                    path={PATHS.agreement}
                                    render={(props) => {
                                        lastPath.push(PATHS.agreement)
                                        return <AgreementContent1 {...props} />
                                    }}
                                />

                                <Route
                                    path={PATHS.terms}
                                    render={(props) => {
                                        lastPath.push(PATHS.terms)
                                        return <AgreementContent2 {...props} />
                                    }}
                                />

                                <Route
                                    path={PATHS.support}
                                    render={(props) => {
                                        lastPath.push(PATHS.support)
                                        return <Support {...props} />
                                    }}
                                />

                                <Route
                                    exact
                                    path={PATHS.error}
                                    component={ErrorView}
                                />

                                <Redirect from="/*" to={PATHS.error} />
                            </Switch>
                        </div>
                        <Footer />
                        <CommonPopup />
                    </div>
                </Router>
            </ConfigProvider>
        </PopupProvider>
    )
}
