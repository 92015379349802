import React, { useContext, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { PATHS } from "../App";
import { ConfigContext } from "../ConfigProvider";
import "./ErrorView.css"

export default function Error() {
  const { language } = useContext(ConfigContext)
  const history = useHistory()

  const timer = useRef(null);
  useEffect(() => {
    timer.current = setTimeout(() => {
      history.replace(PATHS.home)
    }, 5000);

    return () => {
      if (timer.current != null) {
        clearTimeout(timer.current)
        timer.current = null;
      }
    }
  }, [])

  return (
    <div id='errorview_wrapper'>

      <Helmet>
        <title>{language.translations.titles.error}</title>
        <meta name="description" content="공유를 통해 혜택을 나누는 버즈링크" />
      </Helmet>

      <img
        src={require('./resources/img-page-not-found.svg')}
        alt=''
        id='errorview_image' />
      <div id='errorview_text1'>
        {'Sorry, Page not found.'}
      </div>
      <div id='errorview_text2'>
        {'We\'ll move to \'BuzLink?\' page in 5 seconds.'}
      </div>

      <div id='errorview_button'>
        {'Go to Homepage'}
      </div>
    </div>
  );
}
