import React, {
    useEffect,
    useContext,
    useState,
    useRef,
    useCallback,
} from 'react'

import './BuzmapIntro.css'
import animationData from './resources/buzmap_web_loading.json'
import animationData_m from './resources/buzmap_m_loading.json'

import { ConfigContext } from '../../App/ConfigProvider.js'
import Lottie from 'react-lottie'

import { PATHS } from '../../App/App'
import * as Utils from '../../App/Utils'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function BuzmapIntro({ ...props }) {
    const history = useHistory()

    const { language, formattedTranslations } = useContext(ConfigContext)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const lottieView = useRef(null)
    const [step, setStep] = useState(0)

    const onResize = useCallback(() => {
        if (lottieView.current) {
            lottieView.current.stop()
            setStep(0)
            lottieView.current.play()
        }
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize)
        return () => window.removeEventListener('resize', onResize)
    }, [onResize])

    return (
        <div className="buzmapintro_container">
            <Helmet>
                <title>{language.translations.titles.buzmap}</title>
                <meta
                    name="description"
                    content="공유를 통해 혜택을 나누는 버즈링크"
                />
            </Helmet>
            <div
                className="buzmapintro_wrapper"
                onClick={() => {
                    if (step === 1) {
                        console.log('click')
                        if (lottieView.current) {
                            lottieView.current.stop()
                            setStep(0)
                            lottieView.current.play()
                        }
                    }
                }}
            >
                <div
                    id="buzmapintro_bg_off"
                    style={{ opacity: step === 0 ? 1 : 0 }}
                />
                <div
                    id="buzmapintro_bg_on"
                    style={{ opacity: step === 0 ? 0 : 1 }}
                />
                {/* 로딩 깜빡임으로인해 opacity조정(사전로딩되게 하기위해) */}

                {step === 0 ? (
                    <div id="buzmapintro_text1">
                        {formattedTranslations(
                            language.translations.buzmapIntro.copy1
                        )}
                    </div>
                ) : (
                    <div id="buzmapintro_text2">
                        {formattedTranslations(
                            language.translations.buzmapIntro.copy2
                        )}
                    </div>
                )}

                <div id="buzmapintro_lottie">
                    <Lottie
                        ref={(v) => {
                            lottieView.current = v
                        }}
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: Utils.isMobile()
                                ? animationData_m
                                : animationData,
                            preserveAspectRatio: 'xMidYMid slice',
                        }}
                        eventListeners={[
                            {
                                eventName: 'complete',
                                callback: () => {
                                    // console.log('play done')
                                    setStep(1)
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    )
}
