import React, { useContext, useEffect } from "react";
import { ConfigContext } from "../../App/ConfigProvider";

import "./MainCard1.css";

export default function MainCard1({ page }) {
    const {
        language,
        formattedTranslations,
    } = useContext(ConfigContext);


    useEffect(() => {
        const copy = document.getElementById('maincard1_copy')
        const copy_mobile = document.getElementById('maincard1_copy_mobile')

        const icon = document.querySelectorAll('.maincard1_icon')
        const footer0 = document.getElementById('maincard1_bg_image')
        const footer0_mobile = document.getElementById('maincard1_bg_image_mobile')

        const footer1 = document.getElementById('maincard1_bg_footer')
        const footer2 = document.getElementById('maincard1_image')

        try {

            if (page === 0) {
                setTimeout(() => {
                    copy.style.opacity = 1
                    copy_mobile.style.opacity = 1
                }, 1500);

                setTimeout(() => {
                    for (let i = 0; i < icon.length; i++) {
                        icon[i].style.transform = 'translateY(0px)'
                    }
                }, 1800);

                setTimeout(() => {
                    footer0.style.transform = 'translateX(-50%) translateY(0px)'
                    footer0_mobile.style.transform = 'translateX(-50%) translateY(0px)'
                }, 1700);

                setTimeout(() => {
                    footer1.style.transform = 'translateY(0px)'
                    footer2.style.transform = 'translateX(-50%) translateY(0px)'
                }, 1600);

            } else {
                setTimeout(() => {
                    copy.style.opacity = 0
                    copy_mobile.style.opacity = 0
                }, 500);

                for (let i = 0; i < icon.length; i++) {
                    icon[i].style.transform = 'translateY(' + window.innerHeight + 'px)'
                }

                setTimeout(() => {
                    footer0.style.transform = 'translateX(-50%) translateY(' + window.innerHeight + 'px)'
                    footer0_mobile.style.transform = 'translateX(-50%) translateY(' + window.innerHeight + 'px)'
                }, 250);

                setTimeout(() => {
                    footer1.style.transform = 'translateY(' + window.innerHeight + 'px)'
                    footer2.style.transform = 'translateX(-50%) translateY(' + window.innerHeight + 'px)'

                }, 500);
            }
        } catch (e) {
            console.log(e)
        }


    }, [page])

    return (
        <div id='maincard1_bg'>

            <div id='maincard1_wrapper'>



                <div id='maincard1_icon1' className='maincard1_icon' />
                <div id='maincard1_icon2' className='maincard1_icon' />
                <div id='maincard1_icon3' className='maincard1_icon' />
                <div id='maincard1_icon4' className='maincard1_icon' />

                <div id='maincard1_icon5' className='maincard1_icon' />
                <div id='maincard1_icon6' className='maincard1_icon' />
                <div id='maincard1_icon7' className='maincard1_icon' />
                <div id='maincard1_icon8' className='maincard1_icon' />
                <div id='maincard1_icon9' className='maincard1_icon' />
                <div id='maincard1_icon10' className='maincard1_icon' />

                <div id='maincard1_circle1' className='maincard1_icon' />
                <div id='maincard1_circle2' className='maincard1_icon' />
                <div id='maincard1_circle3' className='maincard1_icon' />

                <div id='maincard1_dot1' className='maincard1_icon' />
                <div id='maincard1_dot2' className='maincard1_icon' />
                <div id='maincard1_dot3' className='maincard1_icon' />
                <div id='maincard1_dot4' className='maincard1_icon' />
                <div id='maincard1_dot5' className='maincard1_icon' />
                <div id='maincard1_dot6' className='maincard1_icon' />
                <div id='maincard1_dot7' className='maincard1_icon' />
                <div id='maincard1_dot8' className='maincard1_icon' />
                <div id='maincard1_dot9' className='maincard1_icon' />
                <div id='maincard1_dot10' className='maincard1_icon' />
                <div id='maincard1_dot11' className='maincard1_icon' />
                <div id='maincard1_dot12' className='maincard1_icon' />
                <div id='maincard1_dot13' className='maincard1_icon' />
                <div id='maincard1_dot14' className='maincard1_icon' />
                <div id='maincard1_dot15' className='maincard1_icon' />
                <div id='maincard1_dot16' className='maincard1_icon' />
                <div id='maincard1_dot17' className='maincard1_icon' />
                <div id='maincard1_dot18' className='maincard1_icon' />



                <div id='maincard1_copy'>
                    {formattedTranslations(language.translations.main.card1.copy1)}
                </div>

                <div id='maincard1_copy_mobile'>
                    {formattedTranslations(language.translations.main.card1.copy2)}
                </div>
            </div>

            <img id='maincard1_bg_image' src={require('./resources/MainCard1/bg-01.png')} alt='' />
            <img id='maincard1_bg_image_mobile' src={require('./resources/MainCard1/m-bg-01.png')} alt='' />
            <div id='maincard1_bg_footer' />
            <img id='maincard1_image' src={require('./resources/MainCard1/img-character-01.png')} alt='' />
        </div >
    )
}