import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { ConfigContext } from '../App/ConfigProvider'
import Agreement from './Agreement'

import './AgreementContent2.css'

export default function AgreementContent2() {
    const { language } = useContext(ConfigContext)
    return (
        <div id="agreement_content2_container">
            <Helmet>
                <title>{language.translations.titles.agree2}</title>
                <meta
                    name="description"
                    content="공유를 통해 혜택을 나누는 버즈링크"
                />
            </Helmet>
            <Agreement menu={1} />
            <div id="agreement_content2_wrapper">
                <div className="agreement_content2_template01">
                    개인정보 처리방침
                </div>

                <div className="agreement_content2_template03">
                    (주)BreezeLabs (이하 회사라고 합니다)는 정보통신망 이용촉진
                    및 정보보호 등에 관한 법률, 개인정보보호법, 통신비밀보호법,
                    전기통신사업법 등 정보통신서비스제공자가 준수하여야 할 관련
                    법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한
                    개인정보취급방침을 정하여 이용자 권익 보호에 최선을 다하고
                    있습니다.
                </div>
                <div className="agreement_content2_template03">
                    개인정보 처리방침은 다음과 같은 내용을 담고 있습니다.
                </div>
                <ol>
                    <li className="agreement_content2_template03_2">
                        회사는 이용자에게 회사가 수집하는 개인정보의 내역, 이용
                        방법, 제공 또는 위탁되는 정보, 파기 방법 등을 개인정보
                        처리방침을 통해 알려드리고 있습니다.
                    </li>
                    <li className="agreement_content2_template03_2">
                        이용자의 개인정보에 대한 법률상 권리의 행사 방법을
                        안내하며, 개인정보 보호에 대한 내용을 안내합니다.
                    </li>
                    <li className="agreement_content2_template03_2">
                        개인정보 침해사고를 예방하고, 사고가 이미 발생하였을 때
                        피해를 복구하기 위한 방법을 안내합니다.
                    </li>
                </ol>

                <div className="agreement_content2_template02">
                    1. 개인정보 처리 목적
                </div>
                <div className="agreement_content2_template03">
                    회사는 개인정보를 다음의 목적을 위해 처리합니다. 처리한
                    개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용
                    목적이 변경될 시에는 사전동의를 받습니다.
                </div>
                <ol>
                    <li className="agreement_content2_template03">
                        회원(또는 서비스) 가입 및 관리{' '}
                    </li>
                    <ol>
                        <li className="agreement_content2_template04">
                            회원 가입 확인, 회원 자격의 유지 및 관리, 회원
                            정보의 변경사항 적용, 서비스의 부정이용 방지, 고객
                            상담 및 고충처리 등을 목적으로 개인정보를
                            처리합니다.{' '}
                        </li>
                    </ol>
                    <li className="agreement_content2_template03">
                        서비스 제공
                    </li>
                    <ol>
                        <li className="agreement_content2_template04">
                            서비스 및 정보, 컨텐츠, 혜택 제공, 수수료 및 리워드
                            정산, 요금 결제 등을 목적으로 개인정보를 처리합니다.{' '}
                        </li>
                    </ol>
                    <li className="agreement_content2_template03">
                        광고 및 홍보 목적
                    </li>
                    <ol>
                        <li className="agreement_content2_template04">
                            신규 서비스 안내, 이벤트 및 광고성 정보 제공, 맞춤
                            서비스 및 컨텐츠 제공, 공유 및 확산의 행태나
                            인구통계학적 분석과 통계 작성등을 목적으로
                            개인정보를 처리합니다.{' '}
                        </li>
                    </ol>
                </ol>

                <div className="agreement_content2_template02">
                    2. 개인정보 수집항목 및 보유기간
                </div>
                <ol>
                    <li className="agreement_content2_template03">
                        개인정보 수집항목
                    </li>
                    <ol>
                        <li className="agreement_content2_template04">
                            회사는 회원(또는 서비스) 가입, 원활한 고객상담, 각종
                            서비스 및 정보 제공을 위해 아래와 같은 개인정보를
                            수집하고 있습니다{' '}
                        </li>
                        <ol>
                            <li className="agreement_content2_template05">
                                회원가입 : 이메일 주소, 휴대전화번호
                            </li>
                            <li className="agreement_content2_template05">
                                본인인증 : 휴대전화번호, 통신사, 생년월일, 성별,
                                내외국인 정보, 성명, 개인 식별정보(CI)
                            </li>
                            <li className="agreement_content2_template05">
                                계좌인증 : 예금주, 은행명, 계좌번호
                            </li>
                        </ol>
                        <li className="agreement_content2_template04">
                            서비스 이용과정이나 처리 과정에서 아래와 같은
                            정보들이 자동으로 생성되거나 추가로 수집될 수
                            있습니다.{' '}
                        </li>
                        <ol>
                            <li className="agreement_content2_template05">
                                서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보,
                                결제 및 정산기록
                            </li>
                        </ol>
                        <li className="agreement_content2_template04">
                            회사가 제공하는 소식지, 마케팅 이벤트에 참여하는
                            비회원의 경우에는 아래와 같은 개인정보를 소집할 수
                            있습니다.
                        </li>
                        <ol>
                            <li className="agreement_content2_template05">
                                이메일 주소, 휴대전화번호, 성명, 주소(선택항목){' '}
                            </li>
                        </ol>
                    </ol>
                    <li className="agreement_content2_template03">
                        개인정보 보유기간{' '}
                    </li>
                    <ol>
                        <li className="agreement_content2_template04">
                            이용자의 회원가입일로부터 서비스를 제공 하는 기간
                            동안에 한하여 개인정보를 보유 및 이용하게 됩니다.
                            또한 이용자의 개인정보는 원칙적으로 개인정보의 수집
                            및 이용목적이 달성되면 지체 없이 파기합니다. 단,
                            다음의 정보에 대해서는 아래의 이유로 명기한 기간
                            동안 보존합니다.
                        </li>
                    </ol>
                </ol>

                <div id="agreement_content2_table">
                    {/* <div className='agreement_content2_column' style={{flex:1, borderLeft: 'solid 0px #000'}}>
                        <div className='agreement_content2_table_title'>계약 또는 청약철회 등에 관한 기록</div>
                        <div className='agreement_content2_table_content'>계약 또는 청약철회 등에 관한 기록</div>
                        <div className='agreement_content2_table_content'>대금결제 및 재화 등의 공급에 관한 기록</div>
                        <div className='agreement_content2_table_content'>소비자의 불만 또는 분쟁처리에 관한 기록</div>
                        <div className='agreement_content2_table_content'>표시/광고에 관한 기록</div>
                        <div className='agreement_content2_table_content'>보상 및 정산에 관한 기록</div>
                        <div className='agreement_content2_table_content'>전자금융 거래에 관한 기록</div>
                        <div className='agreement_content2_table_content'>세법이 규정하는 모든 거래에 관한 장부 및 증빙서류</div>
                        <div className='agreement_content2_table_content'>사이트 방문에 관한 기록/ 로그인</div>
                    </div>
                    <div className='agreement_content2_column' style={{flex:1}}>
                        <div className='agreement_content2_table_title'>관련 법률</div>
                        <div className='agreement_content2_table_content'>전자상거래 등에서의 소비자보호에 관한 법률</div>
                        <div className='agreement_content2_table_content'>전자상거래 등에서의 소비자보호에 관한 법률</div>
                        <div className='agreement_content2_table_content'>전자상거래 등에서의 소비자보호에 관한 법률</div>
                        <div className='agreement_content2_table_content'>전자상거래 등에서의 소비자보호에 관한 법률</div>
                        <div className='agreement_content2_table_content'>전자상거래 등에서의 소비자보호에 관한 법률</div>
                        <div className='agreement_content2_table_content'>전자금융거래</div>
                        <div className='agreement_content2_table_content'>국세기본법</div>
                        <div className='agreement_content2_table_content'>통신비밀보호법</div>

                    </div>

                    <div className='agreement_content2_column'>
                        <div className='agreement_content2_table_title'>보존기간</div>
                        <div className='agreement_content2_table_content'>5년</div>
                        <div className='agreement_content2_table_content'>5년</div>
                        <div className='agreement_content2_table_content'>3년</div>
                        <div className='agreement_content2_table_content'>6개월</div>
                        <div className='agreement_content2_table_content'>5년</div>
                        <div className='agreement_content2_table_content'>5년</div>
                        <div className='agreement_content2_table_content'>5년</div>
                        <div className='agreement_content2_table_content'>3개월</div>
                    </div> */}

                    <div className="agreement_content2_column">
                        <div
                            className="agreement_content2_table_title"
                            style={{ borderLeft: 'solid 0px #000' }}
                        >
                            계약 또는 청약철회 등에 관한 기록
                        </div>
                        <div className="agreement_content2_table_title">
                            관련 법률
                        </div>
                        <div
                            className="agreement_content2_table_title"
                            style={{ flex: 0.4 }}
                        >
                            보존기간
                        </div>
                    </div>
                    <div className="agreement_content2_column">
                        <div
                            className="agreement_content2_table_content"
                            style={{ borderLeft: 'solid 0px #000' }}
                        >
                            계약 또는 청약철회 등에 관한 기록
                        </div>
                        <div className="agreement_content2_table_content">
                            전자상거래 등에서의 소비자보호에 관한 법률
                        </div>
                        <div
                            className="agreement_content2_table_content"
                            style={{ flex: 0.4 }}
                        >
                            5년
                        </div>
                    </div>
                    <div className="agreement_content2_column">
                        <div
                            className="agreement_content2_table_content"
                            style={{ borderLeft: 'solid 0px #000' }}
                        >
                            대금결제 및 재화 등의 공급에 관한 기록
                        </div>
                        <div className="agreement_content2_table_content">
                            전자상거래 등에서의 소비자보호에 관한 법률
                        </div>
                        <div
                            className="agreement_content2_table_content"
                            style={{ flex: 0.4 }}
                        >
                            5년
                        </div>
                    </div>
                    <div className="agreement_content2_column">
                        <div
                            className="agreement_content2_table_content"
                            style={{ borderLeft: 'solid 0px #000' }}
                        >
                            소비자의 불만 또는 분쟁처리에 관한 기록
                        </div>
                        <div className="agreement_content2_table_content">
                            전자상거래 등에서의 소비자보호에 관한 법률
                        </div>
                        <div
                            className="agreement_content2_table_content"
                            style={{ flex: 0.4 }}
                        >
                            3년
                        </div>
                    </div>
                    <div className="agreement_content2_column">
                        <div
                            className="agreement_content2_table_content"
                            style={{ borderLeft: 'solid 0px #000' }}
                        >
                            표시/광고에 관한 기록
                        </div>
                        <div className="agreement_content2_table_content">
                            전자상거래 등에서의 소비자보호에 관한 법률
                        </div>
                        <div
                            className="agreement_content2_table_content"
                            style={{ flex: 0.4 }}
                        >
                            6개월
                        </div>
                    </div>
                    <div className="agreement_content2_column">
                        <div
                            className="agreement_content2_table_content"
                            style={{ borderLeft: 'solid 0px #000' }}
                        >
                            보상 및 정산에 관한 기록
                        </div>
                        <div className="agreement_content2_table_content">
                            전자상거래 등에서의 소비자보호에 관한 법률
                        </div>
                        <div
                            className="agreement_content2_table_content"
                            style={{ flex: 0.4 }}
                        >
                            5년
                        </div>
                    </div>
                    <div className="agreement_content2_column">
                        <div
                            className="agreement_content2_table_content"
                            style={{ borderLeft: 'solid 0px #000' }}
                        >
                            전자금융 거래에 관한 기록
                        </div>
                        <div className="agreement_content2_table_content">
                            전자금융거래
                        </div>
                        <div
                            className="agreement_content2_table_content"
                            style={{ flex: 0.4 }}
                        >
                            5년
                        </div>
                    </div>
                    <div className="agreement_content2_column">
                        <div
                            className="agreement_content2_table_content"
                            style={{ borderLeft: 'solid 0px #000' }}
                        >
                            세법이 규정하는 모든 거래에 관한 장부 및 증빙서류
                        </div>
                        <div className="agreement_content2_table_content">
                            국세기본법
                        </div>
                        <div
                            className="agreement_content2_table_content"
                            style={{ flex: 0.4 }}
                        >
                            5년
                        </div>
                    </div>
                    <div className="agreement_content2_column">
                        <div
                            className="agreement_content2_table_content"
                            style={{ borderLeft: 'solid 0px #000' }}
                        >
                            사이트 방문에 관한 기록/ 로그인
                        </div>
                        <div className="agreement_content2_table_content">
                            통신비밀보호법
                        </div>
                        <div
                            className="agreement_content2_table_content"
                            style={{ flex: 0.4 }}
                        >
                            3개월
                        </div>
                    </div>
                </div>

                <div className="agreement_content2_template02">
                    3. 개인정보 파기 절차 및 방법
                </div>
                <div className="agreement_content2_template03">
                    회사는 이용자의 개인정보 처리목적이 달성된 경우에는
                    원칙적으로 지체없이 해당 개인정보를 파기합니다. 단,
                    이용자에게 별도의 동의를 받은 경우 또는 다른 법령에서 일정
                    기간 보존 의무를 부과하는 경우에는 해당 기간 동안 다른 개인
                    정보와 분리하여 보관합니다.
                </div>
                <ol>
                    <li className="agreement_content2_template03">
                        전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적
                        방법을 사용합니다.{' '}
                    </li>
                    <li className="agreement_content2_template03">
                        종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을
                        통하여 파기합니다.
                    </li>
                </ol>

                <div className="agreement_content2_template02">
                    4. 이용자의 권리 및 행사방법
                </div>
                <div className="agreement_content2_template03">
                    이용자는 개인정보주체로서 언제든지 개인정보의 열람,
                    정정요구, 삭제요구, 처리 정지 요구를 할 수 있습니다.{' '}
                </div>
                <div className="agreement_content2_template03">
                    단, 개인정보보호법 제35조 제4항, 제36조 제1항, 제37조 제2항
                    및 기타 관계 법령에 따라 제한될 수 있으며, 다른 법령에서
                    수집 대상으로 명시되어 있는 개인 정보는 삭제, 처리정지 요구
                    권한이 제한될 수 있습니다.
                </div>
                <ol>
                    <li className="agreement_content2_template03">
                        개인정보의 열람 및 정정은 ‘마이 페이지’ 기능을 통해
                        진행할 수 있습니다.{' '}
                    </li>
                    <li className="agreement_content2_template03">
                        개인정보의 삭제 및 처리정지는 ‘회원탈퇴’ 기능을 통해
                        진행 할 수 있습니다.{' '}
                    </li>
                    <li className="agreement_content2_template03">
                        이용자가 개인정보의 오류 등에 대한 정정 또는 삭제를
                        요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지
                        당해 개인정보를 이용하거나 제공하지 않습니다.{' '}
                    </li>
                    <li className="agreement_content2_template03">
                        회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는
                        삭제된 개인정보는 "개인정보의 보유 및 이용기간"에 명시된
                        바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수
                        없도록 처리하고 있습니다.
                    </li>
                </ol>

                <div className="agreement_content2_template02">
                    5. 개인정보의 보호 및 관리
                </div>
                <div className="agreement_content2_template03">
                    회사는 다음과 같이 개인정보의 안정성확보애 필요한 기술적,
                    관리적 조치를 하고 있습니다.{' '}
                </div>
                <ol>
                    <li className="agreement_content2_template03">
                        개인정보 접근통제시스템 설치, 고유식별정보 등의 개인정보
                        암호화, 보안프로그램 설치
                    </li>
                    <li className="agreement_content2_template03">
                        개인정보처리시스템 등의 접근 권한 관리, 개인정보가
                        포함된 문서의 물리적 보안 장치 사용, 비인가자에 대한
                        출입 통제
                    </li>
                    <li className="agreement_content2_template03">
                        개인정보 내부 관리를 위한 정기적 교육 지원, 내부관리
                        계획 수입 및 시행
                    </li>
                    <li className="agreement_content2_template03">
                        회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출
                        및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인
                        갱신·점검을 하며 외부로부터 접근이 통제된 구역에
                        시스템을 설치하고 기술적/물리적으로 감시 및 차단하고
                        있습니다.
                    </li>
                </ol>

                <div className="agreement_content2_template02">
                    6. 개인정보 보호책임자
                </div>
                <ol>
                    <li className="agreement_content2_template03">
                        회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고,
                        개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제
                        등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고
                        있습니다.
                    </li>
                    <ol>
                        <li className="agreement_content2_template04">
                            이름 : 주영민
                        </li>
                        <li className="agreement_content2_template04">
                            직책 : 운영이사
                        </li>
                        <li className="agreement_content2_template04">
                            연락 : support@breezelabs.io
                        </li>
                    </ol>
                    <li className="agreement_content2_template03">
                        이용자 개인정보와 관련한 아이디(ID) 및 패스워드에 대한
                        보안유지책임은 해당 이용자 자신에게 있습니다. 회사는
                        패스워드에 대해 어떠한 방법으로도 이용자에게 직접적으로
                        질문하는 경우는 없으므로 타인에게 패스워드가 유출되지
                        않도록 각별히 주의하시기 바랍니다. 특히 공공장소에서
                        온라인상에서 접속해 있을 경우에는 더욱 유의하셔야
                        합니다.
                    </li>
                    <li className="agreement_content2_template03">
                        기타 개인정보침해에 대한 신구나 상담이 필요하신 경우에는
                        아래 기관에 문의 하시기 바랍니다.{' '}
                    </li>
                    <ol>
                        <li className="agreement_content2_template04">
                            개인분쟁조정위원회 (www.1336.or.kr
                            (http://www.1336.or.kr/) / 1336)
                        </li>
                        <li className="agreement_content2_template04">
                            정보보호마크인증위원회 (www.eprivacy.or.kr
                            (http://www.eprivacy.or.kr/) / 02-580-0533~4)
                        </li>
                        <li className="agreement_content2_template04">
                            대검찰청 첨단범죄수사과 (http://www.spo.go.kr
                            (http://www.spo.go.kr/) / 02-3480-2000)
                        </li>
                        <li className="agreement_content2_template04">
                            경찰청 사이버테러대응센터 (www.ctrc.go.kr
                            (http://www.ctrc.go.kr/) / 02-392-0330)
                        </li>
                    </ol>
                </ol>

                <div className="agreement_content2_template02">
                    7. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
                </div>
                <div className="agreement_content2_template03">
                    이용자 개개인에게 개인화되고 맞춤화된 서비스를 제공하기
                    위해서 회사는 이용자의 정보를 저장하고 수시로 불러오는
                    '쿠키(cookie)'를 사용합니다.
                </div>
                <div className="agreement_content2_template03">
                    쿠키는 웹사이트를 운영하는데 이용되는 서버가 사용자의
                    브라우저에게 보내는 조그마한 데이터 꾸러미로 이용자 컴퓨터의
                    하드디스크에 저장됩니다.
                </div>
                <ol>
                    <li className="agreement_content2_template03">
                        쿠키의 사용 목적
                    </li>
                    <ol>
                        <li className="agreement_content2_template04">
                            쿠키를 통해 이용자가 선호하는 설정 등을 저장하여
                            이용자에게 더욱 빠른 웹 환경을 지원하며, 편리한
                            이용을 위한 서비스 개선에 활용합니다. 이를 통해
                            이용자는 더욱 손쉽게 서비스를 이용할 수 있게 됩니다.
                        </li>
                    </ol>
                    <li className="agreement_content2_template03">
                        쿠키의 설치∙운영 및 거부
                    </li>
                    <ol>
                        <li className="agreement_content2_template04">
                            이용자는 쿠키 설치에 대한 선택권을 가지고 있으며,
                            언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수
                            있습니다.
                        </li>
                    </ol>
                    <li className="agreement_content2_template03">
                        쿠키 설정 거부 방법
                    </li>
                    <ol>
                        <li className="agreement_content2_template04">
                            이용자는 쿠키 설치에 대한 선택권을 가지고 있으며,
                            언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수
                            있습니다.
                        </li>
                        <ol>
                            <li className="agreement_content2_template05">
                                {
                                    'Internet Explorer : 도구 메뉴 선택 > 인터넷 옵션 선택 > 개인정보 탭 클릭 > 고급 개인정보 설정 > 쿠키 수준 설정'
                                }
                            </li>
                            <li className="agreement_content2_template05">
                                {
                                    'Chrome : 설정 메뉴 선택 > 고급 설정 표시 선택 > 개인정보 및 보안 > 콘텐츠 설정 선택 > 쿠키 수준 설정'
                                }
                            </li>
                            <li className="agreement_content2_template05">
                                {
                                    'Safari : 환경설정 메뉴 선택 > 개인정보 탭 선택 > 쿠키 및 웹 사이트 데이터 수준 설정'
                                }
                            </li>
                        </ol>
                    </ol>
                </ol>

                <div className="agreement_content2_template02">
                    8. 개인정보 처리방침의 변경
                </div>
                <div className="agreement_content2_template03">
                    이 개인정보 처리방침은 시행일로부터 적용되며, 법령 및 방침에
                    따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는
                    변경사항의 시행 7일 전부터 공지사항을 통하여 고지됩니다.
                </div>
            </div>
        </div>
    )
}
