import React, { useContext, useEffect } from "react";
import { ConfigContext } from "../../App/ConfigProvider";

import "./MainCard2.css";

export default function MainCard2({ page }) {
    const {
        language,
        formattedTranslations,
    } = useContext(ConfigContext);

    useEffect(() => {
        const copy = document.getElementById('maincard2_copy')
        const copy_mobile = document.getElementById('maincard2_copy_mobile')
        const copy2 = document.getElementById('maincard2_icon5')
        const copy3 = document.getElementById('maincard2_icon6')
        const others = document.querySelectorAll('.maincard2_others')

        const icon1 = document.getElementById('maincard2_icon1')
        const icon2 = document.getElementById('maincard2_icon2')
        const icon3 = document.getElementById('maincard2_icon3')
        const icon4 = document.getElementById('maincard2_icon4')
        try {
            if (page === 1) {
                setTimeout(() => {
                    copy.style.opacity = 1
                    copy_mobile.style.opacity = 1
                }, 1500);

                setTimeout(() => {
                    copy2.style.opacity = 1
                    copy2.style.transform = 'translateY(0px)'
                    copy3.style.opacity = 1
                    copy3.style.transform = 'translateY(0px)'
                }, 2000);

                setTimeout(() => {
                    icon1.style.opacity = 1
                }, 2100);
                setTimeout(() => {
                    icon2.style.opacity = 1
                }, 2200);
                setTimeout(() => {
                    icon3.style.opacity = 1
                }, 2300);
                setTimeout(() => {
                    icon4.style.opacity = 1
                }, 2400);
                setTimeout(() => {
                    for (let i = 0; i < others.length; i++) {
                        others[i].style.opacity = 1
                    }
                }, 2500);


            } else {
                setTimeout(() => {
                    copy.style.opacity = 0
                    copy_mobile.style.opacity = 0
                }, 500);

                copy2.style.opacity = 0
                copy2.style.transform = 'translateY(500px)'
                copy3.style.opacity = 0
                copy3.style.transform = 'translateY(-500px)'

                icon1.style.opacity = 0
                icon2.style.opacity = 0
                icon3.style.opacity = 0
                icon4.style.opacity = 0

                for (let i = 0; i < others.length; i++) {
                    others[i].style.opacity = 0
                }

            }
        } catch (e) {

        }

    }, [page])

    return (
        <div id='maincard2_bg'>

            <div id='maincard2_wrapper'>

                <div id='maincard2_icon5' />
                <div id='maincard2_icon5_dummy' />
                <div id='maincard2_icon6' />
                <div id='maincard2_icon6_dummy' />


                <div id='maincard2_icon1' />
                <div id='maincard2_icon2' />
                <div id='maincard2_icon3' />
                <div id='maincard2_icon4' />





                <div id='maincard2_dot1' className='maincard2_others' />
                <div id='maincard2_dot2' className='maincard2_others' />
                <div id='maincard2_dot3' className='maincard2_others' />
                <div id='maincard2_dot4' className='maincard2_others' />

                <img id='maincard2_line1' src={require('./resources/MainCard2/line-01.png')} alt='' className='maincard2_others' />
                <img id='maincard2_line2' src={require('./resources/MainCard2/line-02.png')} alt='' className='maincard2_others' />
                <img id='maincard2_line3' src={require('./resources/MainCard2/line-03.png')} alt='' className='maincard2_others' />
                <img id='maincard2_line4' src={require('./resources/MainCard2/line-04.png')} alt='' className='maincard2_others' />

                <img id='maincard2_line1_mobile' src={require('./resources/MainCard2/buzmap-line-01.png')} alt='' className='maincard2_others' />
                <img id='maincard2_line2_mobile' src={require('./resources/MainCard2/buzmap-line-02.png')} alt='' className='maincard2_others' />
                <img id='maincard2_line3_mobile' src={require('./resources/MainCard2/buzmap-line-03.png')} alt='' className='maincard2_others' />
                <img id='maincard2_line4_mobile' src={require('./resources/MainCard2/buzmap-line-04.png')} alt='' className='maincard2_others' />



                <div id='maincard2_copy'>
                    {formattedTranslations(language.translations.main.card2.copy1)}
                </div>

                <div id='maincard2_copy_mobile'>
                    {formattedTranslations(language.translations.main.card2.copy2)}
                </div>
            </div>
        </div >
    )
}