
export function commaFormat(num) {
  var regexp = /\B(?=(\d{3})+(?!\d))/g;
  const num1 = num.toString().split('.')[0]
  const num2 = num.toString().split('.')[1]

  let n = num1.replace(regexp, ",");
  if (num2 !== null && num2 !== undefined && num2 !== '') {
    n += '.' + num2;
  }
  return n
}

export function validEmail(mail) {
  if (mail === "") {
    return false;
  }
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(mail).toLowerCase());
}

export function isASCII(str) {
  return /^[\x00-\x7F]*$/.test(str);
}

export function validPw(pw) {
  if (pw === "" || String(pw).length < 10) {
    //10자 미만.
    return false;
  }

  if (!isASCII(pw)) {
    return false
  }

  //규칙
  //특수문자포함 10자이상

  const lowerPw = String(pw).toLowerCase()
  let newpw = ''

  for (var i = 0; i < String(pw).length; i++) {

    const ch = lowerPw[i];
    const parsed = parseInt(ch);

    if (!isNaN(parsed) || isAlphaOrParen(ch)) {
      //숫자일때, 알파벳일때
      newpw = newpw + ch
    }
  }

  if (newpw.length === lowerPw.length) {
    //특수문자가 포함되어 있지 않음.
    return false
  }

  return true
}

export function unixtimeToString(time) {
  var date = new Date(time);

  const m = date.getMonth() + 1;
  const month = m < 10 ? "0" + m : m;

  const d = date.getDate();
  const day = d < 10 ? "0" + d : d;

  const h = date.getHours();
  const hour = h < 10 ? "0" + h : h;

  const m2 = date.getMinutes();
  const minutes = m2 < 10 ? "0" + m2 : m2;

  const s = date.getSeconds();
  const second = s < 10 ? "0" + s : s;

  return (
    date.getFullYear() +
    "." +
    month +
    "." +
    day +
    " " +
    hour +
    ":" +
    minutes +
    ":" +
    second
  );
}

export function isAlphaOrParen(str) {
  return /^[a-zA-Z()]+$/.test(str);
}

export function getDateFormat3(timestamp) {
  const date = new Date(timestamp);
  // MMM d. yyyy

  const m = date.getMonth() + 1;
  const month = m < 10 ? "0" + m : m;

  const d = date.getDate();
  const day = d < 10 ? "0" + d : d;


  return date.getFullYear() + "." + month + "." + day;
}

export function getDateFormat4(timestamp) {
  const date = new Date(timestamp);
  // hh:mm:ss

  const h = date.getHours();
  const hour = h < 10 ? "0" + h : h;

  const m = date.getMinutes();
  const minute = m < 10 ? "0" + m : m;

  const s = date.getSeconds();
  const second = s < 10 ? "0" + s : s;


  return hour + ":" + minute + ":" + second
}

export function getDateFormat5(timestamp) {
  const date = new Date(timestamp);
  // mm.dd

  const m = date.getMonth() + 1;
  const month = m < 10 ? "0" + m : m;

  const d = date.getDate();
  const day = d < 10 ? "0" + d : d;


  return month + "." + day;
}

export function findJsonFamily(name, data) {
  //특정키를 가진 json으로부터 자식들을 모두 찾는다.
  return findJsonChild(findJsonKey(name, data))
}

export function findJsonKey(id, currentNode) {
  //특정키를 가진 json 탐색.
  if (id === currentNode.name) {
    return currentNode;
  } else {

    if (currentNode.children) {
      for (let i = 0; i < currentNode.children.length; i++) {
        let currentChild = currentNode.children[i];
        let result = findJsonKey(id, currentChild);

        if (result !== false) {
          return result;
        }
      }
    }
    return false;
  }
}

export function findJsonChild(json) {
  //최하위 까지의 자식들.

  //recursive
  let list = []
  list.push(json.name)
  if (json.children && json.children.length > 0) {
    for (let i = 0; i < json.children.length; i++) {
      const result = findJsonChild(json.children[i])
      for (let j = 0; j < result.length; j++) {
        list.push(result[j])
      }
    }
  }
  return list
}

export function isMobile() {
  return window.innerWidth <= 1000
}

export function loadLocalStorage(key, defaultValue) {

  const item = window.localStorage.getItem(key)
  return JSON.parse(item) ?? defaultValue
}

export function saveLocalStorage(key, value) {
  console.log("saveLocalStorage ")
  console.log(value)
  window.localStorage.setItem(key, JSON.stringify(value))
}