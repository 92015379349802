import React, {
    useEffect,
    useContext,
} from "react";

import "./Agreement.css";


import { ConfigContext } from "../App/ConfigProvider.js";
import { Link } from "react-router-dom";
import { PATHS } from "../App/App";

export default function Agreement({ menu }) {
    const { language } = useContext(ConfigContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='agreement_container'>
            <div id='agreement_menu'>
                <div id='agreement_menu_dummy' />
                <div className='agreement_title'>{language.translations.agreement.copy0}</div>
                <Link
                    to={PATHS.agreement}
                    className={menu === 0 ? 'agreement_leftmenu_selected' : 'agreement_leftmenu'}
                >{language.translations.agreement.copy1}</Link>
                <Link
                    to={PATHS.terms}
                    className={menu === 1 ? 'agreement_leftmenu_selected' : 'agreement_leftmenu'}
                >{language.translations.agreement.copy2}</Link>
            </div>
            <div id='agreement_separator' />
        </div>
    )
}