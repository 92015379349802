import React, { useEffect, useContext } from 'react'

import './BuzPointIntro.css'

import { ConfigContext } from '../App/ConfigProvider.js'
import { Helmet } from 'react-helmet'

export default function BuzPointIntro({ ...props }) {
    const { language, formattedTranslations } = useContext(ConfigContext)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="buzpointintro_container">
            <Helmet>
                <title>{language.translations.titles.buzpoint}</title>
                <meta
                    name="description"
                    content="공유를 통해 혜택을 나누는 버즈링크"
                />
            </Helmet>
            <div className="buzpointintro_wrapper">
                <div id="buzpointintro_text1">
                    {formattedTranslations(
                        language.translations.buzpointIntro.copy1
                    )}
                </div>
                <div id="buzpointintro_text2">
                    {formattedTranslations(
                        language.translations.buzpointIntro.copy2
                    )}
                </div>

                <div id="buzpointintro_image" />
            </div>
        </div>
    )
}
