import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useContext,
} from 'react'

import './Main.css'
import { ConfigContext } from '../App/ConfigProvider.js'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import 'url-search-params-polyfill'
import { lastPath, PATHS } from '../App/App'
import MainCard1 from './Cards/MainCard1'
import MainCard2 from './Cards/MainCard2'
import MainCard3 from './Cards/MainCard3'
import MainCard4 from './Cards/MainCard4'
import MainCard5 from './Cards/MainCard5'

export default function Main({ ...props }) {
    const [popupShow, setPopupShow] = useState(false)
    const history = useHistory()
    const { setNavTheme } = useContext(ConfigContext)

    const { language, formattedTranslations } = useContext(ConfigContext)

    useEffect(() => {
        //read popup cookie
        // const v = cookie.load('main_popup_show5')
        // if (!v) {
        setPopupShow(true)
        // }
    }, [])

    useEffect(() => {
        document.querySelector('body').style.overflow = 'hidden'
        return () => {
            setNavTheme(0)
            document.querySelector('body').style.overflow = 'auto'
        }
    }, [])

    useEffect(() => {
        if (
            lastPath.length >= 3 &&
            lastPath[lastPath.length - 3] === PATHS.home
        ) {
            const last = lastPath[lastPath.length - 2]
            if (last === PATHS.whatisbuz) {
                setPage(1)
            } else if (last === PATHS.buzfriends) {
                setPage(2)
            } else if (last === PATHS.buzmap) {
                setPage(3)
            } else if (last === PATHS.buzpoint) {
                setPage(4)
            }
        }

        if (
            lastPath.length >= 4 &&
            lastPath[lastPath.length - 4] === PATHS.home
        ) {
            if (lastPath[lastPath.length - 3] === PATHS.buzmap) {
                setPage(3)
            }
        }

        if (
            lastPath.length >= 4 &&
            lastPath[lastPath.length - 4] === PATHS.home
        ) {
            if (lastPath[lastPath.length - 3] === PATHS.buzpoint) {
                setPage(3)
            }
        }
    }, [])

    const lastPage = useRef(-1)
    const [page, setPage] = useState(0)
    const transition = 1000

    //페이지뷰 이동.
    useEffect(() => {
        setTimeout(() => {
            let cards = document.querySelectorAll('.main_card')
            if (!cards) {
                return
            }

            let heights = 0
            for (let i = 0; i < page; i++) {
                heights += cards[i + 1].clientHeight
                //다음페이지의 높이를 가져와야함.
            }

            for (let i = 0; i < cards.length; i++) {
                cards[i].style.transform = 'translateY(' + heights * -1 + 'px)'
            }
        }, transition)
    }, [page])

    //center dot button.
    useEffect(() => {
        if (page < 0 || page > 5) {
            return
        }

        const dot = document.getElementById('main_card_dot')
        const label = document.getElementById('main_card_dot_text')
        const handwrapper = document.getElementById(
            'main_card_dot_hand_wrapper'
        )
        const hand = document.getElementById('main_card_dot_hand')

        if (!dot || !label) {
            return
        }

        const buttonText = [
            formattedTranslations(language.translations.main.card1.button),
            formattedTranslations(language.translations.main.card2.button),
            formattedTranslations(language.translations.main.card3.button),
            formattedTranslations(language.translations.main.card4.button),
            formattedTranslations(language.translations.main.card5.button),
        ]

        const dotColors = ['#ffa500', '#ffa500', 'white', '#ffa500', '#ffa500']
        const fontColors = ['white', 'white', 'black', 'white', 'white']

        if (page !== 5 && lastPage.current !== 5) {
            setTimeout(() => {
                dotTransition(2)
            }, transition / 2)
        } else if (page === 4 || lastPage.current === 5) {
            //카피라이트에서 마지막 페이지로 돌아올때
            setTimeout(() => {
                dotTransition(4)
            }, transition)
        } else if (page === 5) {
            //마지막 페이지에서 카피라이트로 들어갈때
            setTimeout(() => {
                dotTransition(3)
            }, transition)
        }

        setTimeout(() => {
            //dot의 컬러와 텍스트의 컬러, 내용을 변경.
            handwrapper.style.opacity = 1
            dot.style.background = dotColors[page]
            label.style.color = fontColors[page]

            if (page === 2) {
                hand.src = require('./resources/ico-click-btn-w2.svg')
            } else {
                hand.src = require('./resources/ico-click-btn-w1.svg')
            }

            if (page <= 4) {
                setButtonText(buttonText[page])
            }
        }, transition)

        if (page <= 4) {
            //카피라이트 페이지에선 텍스트내용을 변경하지 않는다.
            //카피라이트에선 축소 액션을 하지 않는다.
            setTimeout(() => {
                dotTransition(1)
            }, transition * 1.5)
        }
    }, [page])

    //navigation theme
    useEffect(() => {
        if (page >= 0 && page <= 5) {
            setTimeout(() => {
                if (page === 0 || page === 3) {
                    setNavTheme(1)
                } else if (page === 1 || page === 4) {
                    setNavTheme(2)
                } else {
                    setNavTheme(3)
                }
            }, transition)
        }
    }, [page])

    //우측 중앙 pager, 좌하단 스크롤 가이드
    useEffect(() => {
        const pagerImage = document.querySelector('.main_card_pager_image')
        const pagerLabels = document.querySelectorAll('.main_card_pager_text')

        const scrollGuideView = document.getElementById('main_card_scrollguide')
        const scrollGuideImage = document.getElementById(
            'main_card_scrollguide_icon'
        )
        const scrollGuideText = document.getElementById(
            'main_card_scrollguide_text'
        )

        if (!pagerImage || !pagerLabels) {
            return
        }

        const pagerColor = ['white', 'black', 'black', 'white', 'black']
        const pagerImages = [
            require('./resources/nav-01-p.svg'),
            require('./resources/nav-02-p.svg'),
            require('./resources/nav-03-p.svg'),
            require('./resources/nav-04-p.svg'),
            require('./resources/nav-05-p.svg'),
        ]
        const scrollGuideColor = ['black', 'black', 'black', 'white', 'black']
        const scrollGuideImages = [
            require('./resources/ico-scroll.png'),
            require('./resources/ico-scroll.png'),
            require('./resources/ico-scroll.png'),
            require('./resources/ico-scroll-2.png'),
            require('./resources/ico-scroll.png'),
        ]

        if (page < 0 && page > 5) {
            return
        }

        setTimeout(() => {
            //pager
            if (page <= 4) {
                pagerImage.src = pagerImages[page]
                for (let i = 0; i < pagerLabels.length; i++) {
                    pagerLabels[i].style.color = pagerColor[page]
                }
                setPagerNumber(page + 1)
            }
        }, transition)

        setTimeout(() => {
            //scroll guide
            scrollGuideText.style.color = scrollGuideColor[page]
            if (page === 5) {
                scrollGuideView.style.opacity = 0
            } else {
                scrollGuideImage.src = scrollGuideImages[page]
                scrollGuideView.style.opacity = 1
            }
        }, transition)
    }, [page])

    function dotTransition(direction) {
        const dot = document.getElementById('main_card_dot')
        const label = document.getElementById('main_card_dot_text_wrapper')
        const hand = document.getElementById('main_card_dot_hand_wrapper')

        if (!dot || !label) {
            return
        }

        if (direction === 1) {
            //center
            dot.style.transform = 'scale(1.0)'
            label.style.transform = 'scale(1.0)'
            hand.style.transform = 'scale(1.0)'
        } else if (direction === 2) {
            //down
            dot.style.transform = 'scale(0.125)'
            label.style.transform = 'scale(0)'
            hand.style.transform = 'scale(0)'
        } else if (direction === 3) {
            //enter copyright footer(page 4 => 5)
            const footer = document.getElementById('main_card6')
            dot.style.marginTop = footer.clientHeight * -1 + 'px'
            label.style.marginTop = footer.clientHeight * -1 + 'px'
            hand.style.marginTop = footer.clientHeight * -1 + 'px'
        } else if (direction === 4) {
            dot.style.marginTop = '0px'
            label.style.marginTop = '0px'
            hand.style.marginTop = '0px'
        }
    }

    const [pagerNumber, setPagerNumber] = useState('1')
    const [buttonText, setButtonText] = useState('')

    //0: undefined
    //1: up
    //2: down

    const scrolling = useRef(false)
    const handleScroll = useCallback(
        (e) => {
            if (scrolling.current || popupShow) {
                return
            }

            if (e.deltaY > 0) {
                // console.log('down')
                scrolling.current = true
                lastPage.current = page
                setPage(Math.min(page + 1, 4))
            } else if (e.deltaY < 0) {
                // console.log('up')
                scrolling.current = true
                lastPage.current = page
                setPage(Math.max(page - 1, 0))
            }

            setTimeout(() => {
                scrolling.current = false
            }, 2500)
        },
        [page, popupShow]
    )

    const touchY = useRef(0)
    const touchStart = useCallback((e) => {
        touchY.current = e.touches[0].clientY
    }, [])

    const touchEnd = useCallback(
        (e) => {
            const touch = e.changedTouches[e.changedTouches.length - 1]
            const touchoffsetY = touch.clientY - touchY.current

            if (Math.abs(touchoffsetY) >= 10) {
                if (scrolling.current || popupShow) {
                    return
                }

                if (touchoffsetY < 0) {
                    scrolling.current = true
                    lastPage.current = page
                    setPage(Math.min(page + 1, 5))
                } else if (touchoffsetY > 0) {
                    scrolling.current = true
                    lastPage.current = page
                    setPage(Math.max(page - 1, 0))
                }

                setTimeout(() => {
                    scrolling.current = false
                }, 2500)
            }
        },
        [page, popupShow]
    )

    useEffect(() => {
        window.addEventListener('wheel', handleScroll)
        window.addEventListener('touchstart', touchStart)
        window.addEventListener('touchend', touchEnd)
        return () => {
            window.removeEventListener('wheel', handleScroll)
            window.removeEventListener('touchstart', touchStart)
            window.removeEventListener('touchend', touchEnd)
        }
    }, [handleScroll, touchStart, touchEnd])

    return (
        <div className="main_container">
            <Helmet>
                <title>{language.translations.titles.main}</title>
                <meta
                    name="description"
                    content="공유를 통해 혜택을 나누는 버즈링크"
                />
            </Helmet>

            <div id="main_card_wrapper">
                <div className="main_card" id="main_card1">
                    <MainCard1 page={page} />
                </div>
                <div className="main_card" id="main_card2">
                    <MainCard2 page={page} />
                </div>
                <div className="main_card" id="main_card3">
                    <MainCard3 page={page} />
                </div>
                <div className="main_card" id="main_card4">
                    <MainCard4 page={page} />
                </div>

                <div className="main_card" id="main_card5">
                    <MainCard5 page={page} />
                </div>

                <div id="main_card_dot_wrapper">
                    <div id="main_card_dot" />
                    <div id="main_card_dot_text_wrapper">
                        <div id="main_card_dot_text">{buttonText}</div>
                    </div>
                    <div
                        id="main_card_dot_hand_wrapper"
                        onClick={() => {
                            if (page === 0) {
                                history.push(PATHS.buzfriends)
                            } else if (page === 1) {
                                history.push(PATHS.whatisbuz)
                            } else if (page === 2) {
                                history.push(PATHS.buzfriends)
                            } else if (page === 3) {
                                history.push(PATHS.buzmap)
                            } else if (page >= 4) {
                                history.push(PATHS.buzpoint)
                            }
                        }}
                    >
                        <img
                            id="main_card_dot_hand"
                            src={require('./resources/ico-click-btn-w1.svg')}
                            alt=""
                        />
                    </div>
                </div>

                <div id="main_card_pager_wrapper">
                    <div className="main_card_pager_text">
                        {'0' + pagerNumber + '.'}
                    </div>
                    <img
                        className="main_card_pager_image"
                        src={require('./resources/nav-01-p.svg')}
                        alt=""
                    />
                    <div className="main_card_pager_text">{'05.'}</div>

                    <div
                        id="main_card_pager_button1"
                        className="main_card_pager_button"
                        onClick={() => {
                            if (page !== 0) {
                                setPage(0)
                            }
                        }}
                    />
                    <div
                        id="main_card_pager_button2"
                        className="main_card_pager_button"
                        onClick={() => {
                            if (page !== 1) {
                                setPage(1)
                            }
                        }}
                    />
                    <div
                        id="main_card_pager_button3"
                        className="main_card_pager_button"
                        onClick={() => {
                            if (page !== 2) {
                                setPage(2)
                            }
                        }}
                    />
                    <div
                        id="main_card_pager_button4"
                        className="main_card_pager_button"
                        onClick={() => {
                            if (page !== 3) {
                                setPage(3)
                            }
                        }}
                    />
                    <div
                        id="main_card_pager_button5"
                        className="main_card_pager_button"
                        onClick={() => {
                            if (page !== 4) {
                                setPage(4)
                            }
                        }}
                    />
                </div>

                {/* <div id='main_card6' className='main_card'>
          <FooterView />
        </div> */}

                <div id="main_card_scrollguide">
                    <div id="main_card_scrollguide_text">Scroll</div>
                    <img
                        id="main_card_scrollguide_icon"
                        src={require('./resources/ico-scroll.png')}
                        alt=""
                    />
                </div>
            </div>

            {/* {
                popupShow ?
                    <DelayedPopup onLaterPress={() => {
                        cookie.save("main_popup_show5", true, { path: '/', maxAge: 86400 })

                        setPopupShow(false)
                    }}
                        onPress={() => {
                            setPopupShow(false)
                        }} /> :
                    <div />
            } */}
        </div>
    )
}

// function DelayedPopup({ onLaterPress, onPress }) {
//     const { language } = useContext(ConfigContext)
//     const { setNavTheme } = useContext(ConfigContext)

//     const history = useHistory()

//     return (
//         <div id="main_delay_popup_bg" onClick={() => {
//             console.log('popup')
//         }}>
//             <div className="main_delay_popup_wrapper">

//                 {/* <img id="main_delay_popup_image" src={require('./resources/pop-img.svg')} alt='' /> */}
//                 <div id="main_delay_popup_image3">
//                     출금 지연 안내<br /><br />일부 부적절한 형태의 입금 이벤트 참여로 인하여 잠시 출금이 지연되고 있습니다.<br /><br />빠른 시간안에 확인을 통해 정상적인 참여에 대해서 출금이 진행되도록 하겠습니다.<br /><br />감사합니다.<br />버즈링크팀 드림
//                 </div>
//                 {/* <img id="main_delay_popup_image2" src={require('./resources/img_deposit.gif')} alt='' /> */}

//                 {/* <div id="main_delay_popup_label" onClick={() => {
//             setNavTheme(0)
//             onPress()
//             setTimeout(() => {
//               history.push(PATHS.harumain)
//             }, 100)
//           }}>
//             <div style={{ marginLeft: '6px' }}>{language.translations.main.delayedtitle}</div>
//           </div> */}
//                 <div
//                     id="main_delay_popup_buttons"
//                 >
//                     <div style={{ flex: 1 }} />
//                     <div id='main_delay_popup_button2' onClick={() => {
//                         onPress()
//                     }}>
//                         {language.translations.main_popup_common5}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }
