import React, { useEffect, useState, useCallback, useContext } from 'react'
import './Nav.scss'
import { Link, useLocation } from 'react-router-dom'
import { ConfigContext, EN, KR } from '../App/ConfigProvider'
import { PATHS } from '../App/App'
import classNames from 'classnames'

export default function Navigation() {
    const {
        language,
        formattedTranslations,
        myPoint,
        setMyPoint,
        navTheme,
    } = useContext(ConfigContext)

    const [showMenu, setShowMenu] = useState(false)

    const navMenus = [
        { path: PATHS.whatisbuz, title: language.translations.nav.whatisbuz },
        { path: PATHS.buzfriends, title: language.translations.nav.buzfriends },
        { path: PATHS.buzmap, title: language.translations.nav.buzmap },
        { path: PATHS.buzpoint, title: language.translations.nav.buzpoint },
    ]

    const location = useLocation()

    useEffect(() => {
        document.querySelector('body').style.overflow = showMenu
            ? 'hidden'
            : 'auto'
    }, [showMenu])

    const onResize = useCallback(() => {
        setShowMenu(false)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize)
        return () => window.removeEventListener('resize', onResize)
    }, [onResize])

    return (
        <div style={{ display: 'flex' }}>
            <div
                className={classNames(
                    'navigation',
                    { white: navTheme === 0 },
                    { transparent: navTheme !== 0 }
                )}
            >
                <div className="nav_inner">
                    <Link to={PATHS.home}>
                        <div
                            className={classNames(
                                'nav_inner',
                                'logo',
                                { yellow: navTheme === 0 || navTheme === 2 },
                                { white: navTheme !== 0 && navTheme !== 2 }
                            )}
                        />
                    </Link>

                    {/* pc */}
                    <div id="nav_buttons_pc">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {navMenus.map((item, index) => {
                                return (
                                    <NavButton
                                        linkTo={item.path}
                                        text={item.title}
                                    />
                                )
                            })}
                        </div>

                        <div style={{ flex: 1 }} />

                        <Language />
                    </div>

                    {/* mobile */}
                    <div id="nav_buttons_mobile">
                        <div
                            className={classNames(
                                'nav_menu_icon',
                                { black: navTheme === 0 || navTheme === 2 },
                                { white: navTheme !== 0 && navTheme !== 2 }
                            )}
                            onClick={() => {
                                setShowMenu(true)
                            }}
                            alt=""
                        />
                    </div>
                </div>

                <div
                    className={classNames('nav_bottom_line', {
                        show: navTheme === 0,
                    })}
                />
            </div>

            <SlideMenu
                navMenus={navMenus}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
            />
        </div>
    )
}

function NavButton({ text, linkTo }) {
    const { navTheme } = useContext(ConfigContext)
    const location = useLocation()
    const [over, setOver] = useState(false)

    // console.log(navMenu + ", " + linkTo)
    const [selected, setSelected] = useState(false)

    useEffect(() => {
        setSelected(location.pathname === linkTo)

        if (linkTo === PATHS.buzmap) {
            setSelected(location.pathname === PATHS.buzmap)
        } else if (linkTo === PATHS.buzpoint) {
            setSelected(
                location.pathname === PATHS.buzpoint ||
                    location.pathname === PATHS.history
            )
        } else if (linkTo === PATHS.buzfriends) {
            setSelected(location.pathname === PATHS.buzfriends)
        } else {
            setSelected(location.pathname === linkTo)
        }
    }, [location])

    return (
        <Link className="nav_button_wrapper" to={linkTo}>
            <div
                className="nav_menu_button"
                onMouseOver={() => {
                    setOver(true)
                }}
                onMouseLeave={() => {
                    setOver(false)
                }}
            >
                <div
                    className={classNames(
                        'nav_menu_button_text',
                        {
                            theme0: navTheme == 0,
                            theme1: navTheme == 1,
                            theme2: navTheme == 2,
                            theme_else:
                                navTheme != 0 && navTheme != 1 && navTheme != 2,
                        },
                        {
                            selected: selected || over,
                        }
                    )}
                >
                    {selected || over ? <b>{text}</b> : text}
                </div>

                {selected || over ? (
                    <div
                        className={classNames('nav_underbar', {
                            highlight: navTheme !== 3,
                        })}
                    />
                ) : (
                    <div />
                )}
            </div>
        </Link>
    )
}

export function Language() {
    const { language, setLanguage, navTheme } = useContext(ConfigContext)

    const [isOver, setOver] = useState(false)

    const current = language.type === EN ? 'ENG' : 'KOR'
    const other = language.type !== EN ? 'ENG' : 'KOR'

    return (
        <div
            id="nav_language"
            style={{
                backgroundColor: navTheme === 0 ? 'white' : 'transparent',
                border:
                    navTheme === 0 || navTheme === 1 || navTheme === 2
                        ? 'solid 1px #e6e6e6'
                        : 'solid 1px white',
            }}
            onMouseOver={() => {
                setOver(true)
            }}
            onMouseLeave={() => {
                setOver(false)
            }}
        >
            <div
                id="nav_language_label1"
                style={{
                    color:
                        navTheme === 0 || navTheme === 1 || navTheme === 2
                            ? '#a0a0a0'
                            : 'white',
                }}
            >
                {current}
            </div>
            <div
                id="nav_language_label2"
                style={{
                    color:
                        navTheme === 0 || navTheme === 1 || navTheme === 2
                            ? '#a0a0a0'
                            : 'white',
                }}
                hidden={isOver ? false : true}
                onClick={() => {
                    setLanguage(language.type === EN ? KR : EN)
                }}
            >
                {other}
            </div>

            {navTheme === 0 || navTheme === 2 ? (
                <img
                    className="nav_language_arrow"
                    src={
                        isOver
                            ? require('./resources/arrow_up.png')
                            : require('./resources/arrow_down.png')
                    }
                    alt=""
                />
            ) : (
                <img
                    className="nav_language_arrow"
                    src={
                        isOver
                            ? require('./resources/arrow_up2.png')
                            : require('./resources/arrow_down2.png')
                    }
                    alt=""
                />
            )}
        </div>
    )
}

function SlideMenu({ navMenus, showMenu, setShowMenu }) {
    const { language, setLanguage } = useContext(ConfigContext)

    function hideMenu() {
        setShowMenu(false)
    }

    return (
        <div
            id="nav_menu_wrapper"
            className={classNames('cls_nav_menu_wrapper', {
                visible: showMenu,
            })}
            onClick={hideMenu}
        >
            <div className={classNames('nav_menu_view', { visible: showMenu })}>
                <div id="nav_menu_close" onClick={hideMenu} />

                <div
                    style={{
                        marginTop: '30px',
                        height: '1px',
                        backgroundColor: '#e6e6e6',
                    }}
                />

                <div
                    style={{
                        flex: 1,
                        marginTop: '15px',
                    }}
                >
                    {navMenus.map((item, index) => {
                        return (
                            <Link className="nav_menu_item" to={item.path}>
                                {item.title}
                            </Link>
                        )
                    })}
                </div>

                <div id="nav_menu_language_box">
                    <div
                        style={{ height: '1px', backgroundColor: '#e6e6e6' }}
                    />
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flex: 1,
                            flexDirection: 'row',
                        }}
                    >
                        <div style={{ width: '30px' }} />
                        <div
                            className="nav_menu_language_text"
                            onClick={() => {
                                setLanguage(KR)
                            }}
                        >
                            {language.type === EN ? <b>{'KOR'}</b> : 'KOR'}
                        </div>

                        <div
                            style={{
                                marginLeft: '10px',
                                marginRight: '10px',
                                height: '10px',
                                width: '1px',
                                backgroundColor: '#d8d8d8',
                            }}
                        />

                        <div
                            className="nav_menu_language_text"
                            onClick={() => {
                                setLanguage(EN)
                            }}
                        >
                            {language.type === EN ? <b>{'ENG'}</b> : 'ENG'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
