import React, {
    useState,
    useContext,
    useEffect,
} from "react";

import "./WhatIsBuz.css";
import { ConfigContext } from "../App/ConfigProvider";
import Webtoon from "../Webtoon/Webtoon";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function WhatIsBuz({ ...props }) {
    const { language, formattedTranslations } = useContext(ConfigContext);
    const location = useLocation()

    const [showWebtoon, setShowWebtoon] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    function webtoonClick() {
        setShowWebtoon(true)
    }

    if (showWebtoon) {
        return (
            <Webtoon closePressed={() => {
                setShowWebtoon(false)
            }} />
        )
    }

    return (
        <div id='whatisbuz_wrapper'>
            <Helmet>
                <title>{language.translations.titles.whatisbuz}</title>
                <meta name="description" content="공유를 통해 혜택을 나누는 버즈링크" />
            </Helmet>
            <div id='whatisbuz_banner' onClick={() => {
                webtoonClick()
            }}>
                <div id='whatisbuz_banner_image'>
                    <div id='whatisbuz_banner_text'>
                        {language.translations.main.footer.header}
                    </div>
                </div>
            </div>
            <div id='whatisbuz_content' >
                <SectionTitle text={language.translations.main.footer.copy1_1} />

                <div id='whatisbuz_content1'>
                    <div id='whatisbuz_content1_1'>
                        <div id='whatisbuz_content1_1_1' className='whatisbuz_content_title'>{formattedTranslations(language.translations.main.footer.copy1_2)}</div>
                        <div id='whatisbuz_content1_1_2' className='whatisbuz_content_subtitle'>{formattedTranslations(language.translations.main.footer.copy1_4)}</div>
                    </div>
                    {/* <div style={{ flex: 1 }} /> */}
                    <img id='whatisbuz_content1_2' src={require('./resources/img-w-buzlink.png')} alt='' />
                </div>

                <div className='whatisbuz_dummy' />

                <SectionTitle text={language.translations.main.footer.copy5_1} />

                <div id='whatisbuz_content5'>
                    <div id='whatisbuz_content5_2'>
                        <div id='whatisbuz_content5_2_1'>
                            <img className='whatisbuz_content5_2_image' src={require('./resources/img-limitless-01.png')} alt='' />
                            <div className='whatisbuz_content5_2_text'>{language.translations.main.footer.copy5_2}</div>
                            <div className='whatisbuz_content5_2_text2'>{language.translations.main.footer.copy5_5}</div>
                        </div>

                        <div id='whatisbuz_content5_2_2'>
                            <img className='whatisbuz_content5_2_image' src={require('./resources/img-limitless-02.png')} alt='' />
                            <div className='whatisbuz_content5_2_text'>{language.translations.main.footer.copy5_3}</div>
                            <div className='whatisbuz_content5_2_text2'>{language.translations.main.footer.copy5_6}</div>
                        </div>

                        <div id='whatisbuz_content5_2_3'>
                            <img className='whatisbuz_content5_2_image' src={require('./resources/img-limitless-03.png')} alt='' />
                            <div className='whatisbuz_content5_2_text'>{language.translations.main.footer.copy5_4}</div>
                            <div className='whatisbuz_content5_2_text2'>{language.translations.main.footer.copy5_7}</div>
                        </div>
                    </div>
                </div>


                <div className='whatisbuz_dummy' />

                <SectionTitle text={language.translations.main.footer.copy2_1} />
                <div id='whatisbuz_content2'>
                    <div id='whatisbuz_content2_1'>
                        <div id='whatisbuz_content2_1_1' className='whatisbuz_content_title'>{formattedTranslations(language.translations.main.footer.copy2_2)}</div>
                        <div id='whatisbuz_content2_1_2' className='whatisbuz_content_subtitle'>{formattedTranslations(language.translations.main.footer.copy2_3)}</div>

                    </div>
                    <img src={require('./resources/img-01.png')} id='whatisbuz_content2_2_images1' alt='' />
                    <img src={require('./resources/img-01-mobile.png')} id='whatisbuz_content2_2_images1_mobile' alt='' />
                </div>

                <div id='whatisbuz_content3'>
                    <div id='whatisbuz_content3_1'>
                        <div id='whatisbuz_content3_1_1' className='whatisbuz_content_title_pc'>{language.translations.main.footer.copy3_1}</div>
                        <div id='whatisbuz_content3_1_1' className='whatisbuz_content_title_mobile'>{formattedTranslations(language.translations.main.footer.copy3_1_2)}</div>
                        <div id='whatisbuz_content3_1_2' className='whatisbuz_content_subtitle'>{formattedTranslations(language.translations.main.footer.copy3_2)}</div>
                        <div id='whatisbuz_content3_1_3' className='whatisbuz_content_subsubtitle'>{formattedTranslations(language.translations.main.footer.copy3_4)}</div>
                        <div id='whatisbuz_content3_1_4'>
                            <img src={require('./resources/img-w-h.png')} id='whatisbuz_content3_1_4_images1' className='whatisbuz_content3_1_4_images' alt='' />
                            <img src={require('./resources/img-w-c.png')} id='whatisbuz_content3_1_4_images2' className='whatisbuz_content3_1_4_images' alt='' />
                            <img src={require('./resources/img-w-t.png')} id='whatisbuz_content3_1_4_images3' className='whatisbuz_content3_1_4_images' alt='' />
                        </div>
                    </div>
                    <img src={require('./resources/img-w-coin.png')} id='whatisbuz_content3_2' alt='' />
                </div>

                <div id='whatisbuz_content4'>
                    <div id='whatisbuz_content4_1'>
                        <div id='whatisbuz_content4_1_1' className='whatisbuz_content_title_pc'>{language.translations.main.footer.copy4_1}</div>
                        <div id='whatisbuz_content4_1_1' className='whatisbuz_content_title_mobile'>{formattedTranslations(language.translations.main.footer.copy4_1_2)}</div>
                        <div id='whatisbuz_content4_1_2' className='whatisbuz_content_subtitle'>{formattedTranslations(language.translations.main.footer.copy4_2)}</div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <a target="_blank" rel="noopener noreferrer" href='https://terra.money' id='whatisbuz_content4_1_3'>{language.translations.main.footer.copy4_3}</a>
                            <div style={{ flex: 1 }} />
                        </div>
                    </div>
                    <BaasContents />
                </div>
            </div>

            <img id='whatisbuz_floating' src={require('./resources/too-floating-copy.png')} alt='' onClick={() => {
                webtoonClick()
            }} />

        </div>
    )
}

function SectionTitle({ text }) {
    return (
        <div className='whatisbuz_section'>
            <div className='whatisbuz_section_text'>{text}</div>
            <div className='whatisbuz_section_line' />
        </div>
    )
}

function BaasContents() {
    const [baasSelected, setBaasSelected] = useState(0)



    return (
        <div id='whatisbuz_content4_2'>
            <div id='whatisbuz_content4_2_1'>
                <div onClick={() => {
                    setBaasSelected(0)
                }}>
                    <div id={baasSelected === 0 ? 'whatisbuz_content4_2_1_image1_checked' : 'whatisbuz_content4_2_1_image1'} className='whatisbuz_content4_2_1_image' />
                </div>
                <img src={require('./resources/ico-w-n.png')} className='whatisbuz_content4_2_1_dash' alt='' />
                <div onClick={() => {
                    setBaasSelected(1)
                }}>
                    <div id={baasSelected === 1 ? 'whatisbuz_content4_2_1_image2_checked' : 'whatisbuz_content4_2_1_image2'} className='whatisbuz_content4_2_1_image' />
                </div>
                <img src={require('./resources/ico-w-n.png')} className='whatisbuz_content4_2_1_dash' alt='' />
                <div onClick={() => {
                    setBaasSelected(2)
                }}>
                    <div id={baasSelected === 2 ? 'whatisbuz_content4_2_1_image3_checked' : 'whatisbuz_content4_2_1_image3'} className='whatisbuz_content4_2_1_image' />
                </div>
                <img src={require('./resources/ico-w-n.png')} className='whatisbuz_content4_2_1_dash' alt='' />
                <div onClick={() => {
                    setBaasSelected(3)
                }}>
                    <div id={baasSelected === 3 ? 'whatisbuz_content4_2_1_image4_checked' : 'whatisbuz_content4_2_1_image4'} className='whatisbuz_content4_2_1_image' />
                </div>
                <img src={require('./resources/ico-w-n.png')} className='whatisbuz_content4_2_1_dash' alt='' />
                <div onClick={() => {
                    setBaasSelected(4)
                }}>
                    <div id={baasSelected === 4 ? 'whatisbuz_content4_2_1_image5_checked' : 'whatisbuz_content4_2_1_image5'} className='whatisbuz_content4_2_1_image' />
                </div>
            </div>
            <div id='whatisbuz_content4_2_2'>
                {
                    baasSelected === 0 ?
                        <BaasContent1 /> :
                        baasSelected === 1 ?
                            <BaasContent2 /> :
                            baasSelected === 2 ?
                                <BaasContent3 /> :
                                baasSelected === 3 ?
                                    <BaasContent4 /> :
                                    <BaasContent5 />
                }
            </div>
        </div>
    )
}

function BaasContent1() {
    const { language, formattedTranslations } = useContext(ConfigContext);

    return (
        <div>
            <div id='whatisbuz_baascontent1_1'>
                {formattedTranslations(language.translations.main.footer.bass1.copy1)}
            </div>

            <img src={require('./resources/img-baas-01.png')} id='whatisbuz_baascontent1_2' alt='' />


            <div id='whatisbuz_baascontent1_3'>
                {formattedTranslations(language.translations.main.footer.bass1.copy2)}
            </div>
        </div>
    )
}

function BaasContent2() {
    const { language, formattedTranslations } = useContext(ConfigContext);

    return (
        <div>
            <div id='whatisbuz_baascontent2_1'>
                {formattedTranslations(language.translations.main.footer.bass2.copy1)}
            </div>

            <div id='whatisbuz_baascontent2_2'>
                {formattedTranslations(language.translations.main.footer.bass2.copy2)}
            </div>

            <img src={require('./resources/img-baas-02.svg')} id='whatisbuz_baascontent2_3' alt='' />
        </div>
    )
}

function BaasContent3() {
    const { language, formattedTranslations } = useContext(ConfigContext);

    return (
        <div>
            <div id='whatisbuz_baascontent3_1'>
                {formattedTranslations(language.translations.main.footer.bass3.copy1)}
            </div>

            <div id='whatisbuz_baascontent3_3'>
                {formattedTranslations(language.translations.main.footer.bass3.copy2)}
            </div>
            <img src={require('./resources/img-baas-03-01.svg')} id='whatisbuz_baascontent3_2' alt='' />

            <div id='whatisbuz_baascontent3_4'>
                <div id='whatisbuz_baascontent3_4_1'>
                    <img src={require('./resources/img-baas-03-02.svg')} id='whatisbuz_baascontent3_4_image1' alt='' />
                    <div id='whatisbuz_baascontent3_4_text1' className='whatisbuz_baascontent3_4_text'>{formattedTranslations(language.translations.main.footer.bass3.copy3)}</div>
                </div>
                <div id='whatisbuz_baascontent3_4_2'>
                    <img src={require('./resources/img-baas-03-03.svg')} id='whatisbuz_baascontent3_4_image2' alt='' />
                    <div id='whatisbuz_baascontent3_4_text1' className='whatisbuz_baascontent3_4_text'>{formattedTranslations(language.translations.main.footer.bass3.copy4)}</div>
                </div>
                <div id='whatisbuz_baascontent3_4_3'>
                    <img src={require('./resources/img-baas-03-04.svg')} id='whatisbuz_baascontent3_4_image3' alt='' />
                    <div id='whatisbuz_baascontent3_4_text1' className='whatisbuz_baascontent3_4_text'>{formattedTranslations(language.translations.main.footer.bass3.copy5)}</div>
                </div>
            </div>
        </div>
    )
}

function BaasContent4() {
    const { language, formattedTranslations } = useContext(ConfigContext);

    return (
        <div>
            <div id='whatisbuz_baascontent4_1'>
                {formattedTranslations(language.translations.main.footer.bass4.copy1)}
            </div>
            <div id='whatisbuz_baascontent4_2'>
                <img src={require('./resources/img-baas-04.svg')} id='whatisbuz_baascontent4_2_image' alt='' />
                <div id='whatisbuz_baascontent4_2_text'>
                    {formattedTranslations(language.translations.main.footer.bass4.copy2)}
                </div>
            </div>
        </div>
    )
}

function BaasContent5() {
    const { language, formattedTranslations } = useContext(ConfigContext);

    return (
        <div>
            <div id='whatisbuz_baascontent5_1'>
                {formattedTranslations(language.translations.main.footer.bass5.copy1)}
            </div>
            <img src={require('./resources/img-baas-05.svg')} id='whatisbuz_baascontent5_4' alt='' />
            <div id='whatisbuz_baascontent5_2'>
                {formattedTranslations(language.translations.main.footer.bass5.copy2)}
            </div>
            <img src={require('./resources/img-baas-05.svg')} id='whatisbuz_baascontent5_3' alt='' />
        </div>
    )
}

