import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useContext,
} from "react";
import { useCountUp } from "react-countup";

import "./Webtoon.css";

import { ConfigContext, EN } from "../App/ConfigProvider.js";
import 'url-search-params-polyfill';
import { useLocation } from "react-router-dom";

export default function Webtoon({ closePressed }) {
    const { language, formattedTranslations } = useContext(ConfigContext)
    const location = useLocation()

    const [countUpShow, setCountUpShow] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const pageChanged = useCallback(() => {
        const p = toonIndex.current;
        if (p < 0) {
            return;
        }

        if (p >= 0) {
            document.querySelector("#toon1_2").style.opacity = 1.0;
            document.querySelector("#toon1_2").style.transform =
                "scaleX(1.0) scaleY(1.0) translateX(0%) translateY(0%)";
            document.querySelector("#toon1_3").style.opacity = 1.0;
            document.querySelector("#toon1_4").style.opacity = 1.0;
            document.querySelector("#toon1_4_desc").style.opacity = 1.0;
            document.querySelector("#toon1_5_desc").style.opacity = 1.0;
        }

        if (p >= 1) {
            document.querySelector("#toon2_3").style.opacity = 1.0;
            document.querySelector("#toon2_3").style.transform =
                "scaleX(1.0) scaleY(1.0) translateY(0%)";
            document.querySelector("#toon2_4").style.opacity = 1.0;
            document.querySelector("#toon2_4_desc").style.opacity = 1.0;
        }

        if (p >= 2) {
            document.querySelector("#toon3_3").style.opacity = 1.0;
            document.querySelector("#toon3_4").style.opacity = 1.0;
            document.querySelector("#toon3_4_desc").style.opacity = 1.0;
            document.querySelector("#toon3_2").classList.add("bounce");

            document.querySelector("#toon3_5").style.opacity = 1.0;
            document.querySelector("#toon3_5_desc").style.opacity = 1.0;
            document.querySelector("#toon3_6_desc").style.opacity = 1.0;
        }

        if (p >= 3) {
            document.querySelector("#toon4_2").classList.add("bounce");
            document.querySelector("#toon4_4").classList.add("bounce");

            document.querySelector("#toon4_26").style.opacity = 1.0;
            //0.02s

            document.querySelector("#toon4_7").classList.add("bounce");
            document.querySelector("#toon4_10").classList.add("bounce");
            document.querySelector("#toon4_5").classList.add("bounce");
            document.querySelector("#toon4_8").classList.add("bounce");
            document.querySelector("#toon4_11").classList.add("bounce");
            document.querySelector("#toon4_9").classList.add("bounce");
            document.querySelector("#toon4_6").classList.add("bounce");

            document.querySelector("#toon4_27").style.opacity = 1.0;
            //0.14s

            document.querySelector("#toon4_20").classList.add("bounce");
            document.querySelector("#toon4_15").classList.add("bounce");
            document.querySelector("#toon4_21").classList.add("bounce");
            document.querySelector("#toon4_19").classList.add("bounce");
            document.querySelector("#toon4_18").classList.add("bounce");
            document.querySelector("#toon4_14").classList.add("bounce");
            document.querySelector("#toon4_12").classList.add("bounce");
            document.querySelector("#toon4_22").classList.add("bounce");
            document.querySelector("#toon4_13").classList.add("bounce");
            document.querySelector("#toon4_16").classList.add("bounce");
            document.querySelector("#toon4_17").classList.add("bounce");

            document.querySelector("#toon4_28").style.opacity = 1.0;
            //0.36s

            document.querySelector("#toon4_23").style.opacity = 1.0;

            document.querySelector("#toon4_29").style.opacity = 1.0;

            document.querySelector("#toon4_24").style.opacity = 1.0;
            document.querySelector("#toon4_25").style.opacity = 1.0;
            document.querySelector("#toon4_30").style.opacity = 1.0;

            document.querySelector("#toon4_32").style.opacity = 0;

            //0.42s
        }

        if (p >= 5) {
            document.querySelector("#toon6_4").classList.add("bounce");
            document.querySelector("#toon6_9").classList.add("bounce");
            document.querySelector("#toon6_9_desc").style.opacity = 1.0;
            document.querySelector("#toon6_12").style.opacity = 1.0;

            document.querySelector("#toon6_3").classList.add("bounce");
            document.querySelector("#toon6_8").classList.add("bounce");
            document.querySelector("#toon6_8_desc").style.opacity = 1.0;
            document.querySelector("#toon6_11").style.opacity = 1.0;

            document.querySelector("#toon6_2").classList.add("bounce");
            document.querySelector("#toon6_7").classList.add("bounce");
            document.querySelector("#toon6_7_desc").style.opacity = 1.0;
            document.querySelector("#toon6_10").style.opacity = 1.0;

            document.querySelector("#toon6_1").classList.add("bounce");
            document.querySelector("#toon6_6").classList.add("bounce");
            document.querySelector("#toon6_6_desc").style.opacity = 1.0;

            document.querySelector("#toon6_13_desc").style.opacity = 1.0;

        }

        if (p >= 6) {
            if (!countUpShow) {
                setCountUpShow(true);
            }
        }
    }, [countUpShow]);

    const toonIndex = useRef(-1);
    const handleScroll = useCallback((e) => {


        //중앙기준
        for (var i = 0; i < 8; i++) {
            if (i === 4) {
                //4는 제거되었다.
                continue
            }

            const obj = document.querySelector("#toon" + (i + 1));

            const a = obj.getBoundingClientRect().top;
            //이미지의 현재화면 기준 margin top
            const b = window.innerHeight - (a + obj.clientHeight);
            //이미지의 현재화면 기준 margin bottom

            const c = a / window.innerHeight;
            //image top percent;

            //p1 : top60%
            //p2 : 그대로
            //p3 : top30%
            //p4 : top40% 느리게
            //p5 : 그대로
            //p6 : top30%
            //p7 : top60%

            if (i === 0 && c <= 0.6 && toonIndex.current < i) {
                //a가 b보다 같거나 작아지면 중앙을 지나 위로 치우쳐진 것.
                toonIndex.current = i;
                pageChanged();
            } else if (i === 1 && a <= b && toonIndex.current < i) {
                //a가 b보다 같거나 작아지면 중앙을 지나 위로 치우쳐진 것.
                toonIndex.current = i;
                pageChanged();
            } else if (i === 2 && c <= 0.3 && toonIndex.current < i) {
                //a가 b보다 같거나 작아지면 중앙을 지나 위로 치우쳐진 것.
                toonIndex.current = i;
                pageChanged();
            } else if (i === 3 && c <= 0.4 && toonIndex.current < i) {
                //a가 b보다 같거나 작아지면 중앙을 지나 위로 치우쳐진 것.
                toonIndex.current = i;
                pageChanged();
            } else if (i === 4 && a <= b && toonIndex.current < i) {
                //a가 b보다 같거나 작아지면 중앙을 지나 위로 치우쳐진 것.
                toonIndex.current = i;
                pageChanged();
            } else if (i === 5 && c <= 0.3 && toonIndex.current < i) {
                //a가 b보다 같거나 작아지면 중앙을 지나 위로 치우쳐진 것.
                toonIndex.current = i;
                pageChanged();
            } else if (i === 6 && c <= 0.6 && toonIndex.current < i) {
                //a가 b보다 같거나 작아지면 중앙을 지나 위로 치우쳐진 것.
                toonIndex.current = i;
                pageChanged();
            } else if (i === 7 && a <= b && toonIndex.current < i) {
                //a가 b보다 같거나 작아지면 중앙을 지나 위로 치우쳐진 것.
                toonIndex.current = i;
                pageChanged();
            }
        }

    }, [pageChanged]);

    return (
        <div className="webtoon_container" onScroll={(e) => {
            handleScroll(e)
        }}>



            <div id="webtoon_wrapper">
                <img id='webtoon_close_pc' src={require('./resources/map-close.png')} alt='' onClick={() => {
                    closePressed()
                }} />
                <img id='webtoon_close_mobile' src={require('./resources/map-close-mobile.png')} alt='' onClick={() => {
                    closePressed()
                }} />
                <div id='webtoon_header'>

                    <div id='webtoon_header_image'>
                        <div id='webtoon_header_copy'>{formattedTranslations(language.translations.toonheader.copy1)}</div>
                    </div>
                </div>
                <Toons
                    countUpShow={countUpShow}
                />
                <div id='toons_footer' />
            </div>
        </div>
    );
}

function Toons({ countUpShow }) {
    const { formattedTranslations, language } = useContext(ConfigContext);
    const toon4delay = 0.04;

    return (
        <div id="toons">
            <div id="toon1">
                <img
                    id="toon1_1"
                    src={require("./resources/toon1_1.png")}
                    alt="버즈링크 생성하기"
                />
                <img
                    id="toon1_2"
                    style={{ transitionDelay: "0.25s", transitionDuration: "0.3s" }}
                    src={require("./resources/toon1_2.png")}
                    alt=""
                />
                <img
                    id="toon1_3"
                    style={{ transitionDelay: "0.0s", transitionDuration: "0.0s" }}
                    src={require("./resources/toon1_3.png")}
                    alt=""
                />
                <img
                    id="toon1_4"
                    style={{ transitionDelay: "0.8s", transitionDuration: "0.0s" }}
                    src={require("./resources/toon1_4.png")}
                    alt=""
                />
                <div
                    id="toon1_4_desc"
                    style={{ transitionDelay: "0.8s", transitionDuration: "0.0s" }}
                >
                    {formattedTranslations(language.translations.toon1.msg1)}
                </div>
                <div
                    id="toon1_5_desc"
                    style={{ transitionDelay: "0.8s", transitionDuration: "0.0s" }}
                >
                    {language.translations.toon1.msg2}
                </div>
            </div>

            <div id="toon2">
                <img
                    id="toon2_1"
                    src={require("./resources/toon2_1.png")}
                    alt="버즈링크 공유하기"
                />
                <img
                    id="toon2_2"
                    src={require("./resources/toon2_2.png")}
                    alt=""
                />
                <img
                    id="toon2_3"
                    style={{ transitionDelay: "0.0s", transitionDuration: "0.3s" }}
                    src={require("./resources/toon2_3.png")}
                    alt=""
                />
                <img
                    id="toon2_4"
                    style={{ transitionDelay: "0.5s", transitionDuration: "0.0s" }}
                    src={require("./resources/toon2_4.png")}
                    alt=""
                />
                <div
                    id="toon2_4_desc"
                    style={{ transitionDelay: "0.5s", transitionDuration: "0.0s" }}
                >
                    {formattedTranslations(language.translations.toon2.msg1)}
                </div>
            </div>
            <div id="toon3">
                <img
                    id="toon3_1"
                    src={require("./resources/toon3_1.png")}
                    alt="버즈링크 친구에게 공유하기"
                />
                <img
                    id="toon3_2"
                    style={{ animationDelay: "1.3s", animationDuration: "0.5s" }}
                    src={require("./resources/toon3_2.png")}
                    alt=""
                />
                <img
                    id="toon3_3"
                    style={{ transitionDelay: "0.0s", transitionDuration: "0.0s" }}
                    src={require("./resources/toon3_3.png")}
                    alt=""
                />
                <img
                    id="toon3_4"
                    style={{ transitionDelay: "0.25s", transitionDuration: "0.0s" }}
                    src={require("./resources/toon3_4.png")}
                    alt=""
                />
                <div
                    id="toon3_4_desc"
                    style={{ transitionDelay: "0.25s", transitionDuration: "0.0s" }}
                >
                    {language.translations.toon3.msg1}
                </div>
                <img
                    id="toon3_5"
                    style={{ transitionDelay: "1.55s", transitionDuration: "0.0s" }}
                    src={require("./resources/toon3_5.png")}
                    alt=""
                />
                <div
                    id="toon3_5_desc"
                    style={{ transitionDelay: "1.55s", transitionDuration: "0.0s" }}
                >
                    {formattedTranslations(language.translations.toon3.msg2)}
                </div>
                <div
                    id="toon3_6_desc"
                    style={{ transitionDelay: "1.55s", transitionDuration: "0.0s" }}
                >
                    {language.translations.toon3.msg3}
                </div>
            </div>

            <div id="toon4">
                <img
                    id="toon4_1"
                    src={require("./resources/toon4_1.png")}
                    alt="버즈링크 확산모습"
                />

                <img
                    id="toon4_2"
                    style={{
                        animationDelay: toon4delay * 1 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_2.png")}
                    alt=""
                />
                <img
                    id="toon4_3"
                    src={require("./resources/toon4_3.png")}
                    alt=""
                />
                <img
                    id="toon4_4"
                    style={{
                        animationDelay: toon4delay * 0 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_4.png")}
                    alt=""
                />

                <img
                    id="toon4_5"
                    style={{
                        animationDelay: toon4delay * 4 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_5.png")}
                    alt=""
                />
                <img
                    id="toon4_6"
                    style={{
                        animationDelay: toon4delay * 8 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_6.png")}
                    alt=""
                />
                <img
                    id="toon4_7"
                    style={{
                        animationDelay: toon4delay * 2 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_7.png")}
                    alt=""
                />
                <img
                    id="toon4_8"
                    style={{
                        animationDelay: toon4delay * 5 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_8.png")}
                    alt=""
                />
                <img
                    id="toon4_9"
                    style={{
                        animationDelay: toon4delay * 7 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_9.png")}
                    alt=""
                />
                <img
                    id="toon4_10"
                    style={{
                        animationDelay: toon4delay * 3 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_10.png")}
                    alt=""
                />
                <img
                    id="toon4_11"
                    style={{
                        animationDelay: toon4delay * 6 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_11.png")}
                    alt=""
                />

                <img
                    id="toon4_12"
                    style={{
                        animationDelay: toon4delay * 15 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_12.png")}
                    alt=""
                />
                <img
                    id="toon4_13"
                    style={{
                        animationDelay: toon4delay * 17 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_13.png")}
                    alt=""
                />
                <img
                    id="toon4_14"
                    style={{
                        animationDelay: toon4delay * 14 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_14.png")}
                    alt=""
                />
                <img
                    id="toon4_15"
                    style={{
                        animationDelay: toon4delay * 10 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_15.png")}
                    alt=""
                />
                <img
                    id="toon4_16"
                    style={{
                        animationDelay: toon4delay * 18 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_16.png")}
                    alt=""
                />
                <img
                    id="toon4_17"
                    style={{
                        animationDelay: toon4delay * 19 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_17.png")}
                    alt=""
                />
                <img
                    id="toon4_18"
                    style={{
                        animationDelay: toon4delay * 13 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_18.png")}
                    alt=""
                />
                <img
                    id="toon4_19"
                    style={{
                        animationDelay: toon4delay * 12 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_19.png")}
                    alt=""
                />
                <img
                    id="toon4_20"
                    style={{
                        animationDelay: toon4delay * 9 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_20.png")}
                    alt=""
                />
                <img
                    id="toon4_21"
                    style={{
                        animationDelay: toon4delay * 11 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_21.png")}
                    alt=""
                />
                <img
                    id="toon4_22"
                    style={{
                        animationDelay: toon4delay * 16 + "s",
                        animationDuration: "0.2s",
                    }}
                    src={require("./resources/toon4_22.png")}
                    alt=""
                />

                <img
                    id="toon4_23"
                    style={{
                        transitionDelay: toon4delay * 20 + "s",
                        transitionDuration: "0.3s",
                    }}
                    src={require("./resources/toon4_23.png")}
                    alt=""
                />
                <img
                    id="toon4_24"
                    style={{
                        transitionDelay: toon4delay * 21 + "s",
                        transitionDuration: "0.5s",
                    }}
                    src={require("./resources/toon4_24.png")}
                    alt=""
                />
                <img
                    id="toon4_25"
                    style={{
                        transitionDelay: toon4delay * 22 + "s",
                        transitionDuration: "0.5s",
                    }}
                    src={require("./resources/toon4_25.png")}
                    alt=""
                />
                <img
                    id="toon4_30"
                    style={{
                        transitionDelay: toon4delay * 23 + "s",
                        transitionDuration: "0.5s",
                    }}
                    src={require("./resources/toon4_30.png")}
                    alt=""
                />

                <img
                    id="toon4_26"
                    style={{
                        transitionDelay: toon4delay * 1 + "s",
                        transitionDuration: "0.6s",
                    }}
                    src={require("./resources/toon4_26.png")}
                    alt=""
                />
                <img
                    id="toon4_27"
                    style={{
                        transitionDelay: toon4delay * 8 + "s",
                        transitionDuration: "0.8s",
                    }}
                    src={require("./resources/toon4_27.png")}
                    alt=""
                />
                <img
                    id="toon4_28"
                    style={{
                        transitionDelay: toon4delay * 19 + "s",
                        transitionDuration: "0.8s",
                    }}
                    src={require("./resources/toon4_28.png")}
                    alt=""
                />
                <img
                    id="toon4_29"
                    style={{
                        transitionDelay: toon4delay * 20 + "s",
                        transitionDuration: "0.8s",
                    }}
                    src={require("./resources/toon4_29.png")}
                    alt=""
                />

                <img
                    id="toon4_31"
                    src={require("./resources/toon4_31.png")}
                    alt=""
                />

                <img
                    id="toon4_32"
                    style={{
                        transitionDelay: toon4delay * 0 + "s",
                        transitionDuration: "0.5s",
                    }}
                    src={require("./resources/toon4_32.png")}
                    alt=""
                />
            </div>

            <div id="toon6">
                <img
                    id="toon6_5"
                    // style={{ animationDelay: "1.2s", animationDuration: "0.3s" }}
                    src={require("./resources/toon6_5.png")}
                    alt=""
                />


                <img
                    id="toon6_1"
                    style={{ animationDelay: "0.0s", animationDuration: "0.3s" }}
                    src={require("./resources/toon6_1.png")}
                    alt="버즈링크를 따라 리워드 받기"
                />
                <img
                    id="toon6_2"
                    style={{ animationDelay: "0.4s", animationDuration: "0.3s" }}
                    src={require("./resources/toon6_2.png")}
                    alt=""
                />
                <img
                    id="toon6_3"
                    style={{ animationDelay: "0.8s", animationDuration: "0.3s" }}
                    src={require("./resources/toon6_3.png")}
                    alt=""
                />
                <img
                    id="toon6_4"
                    style={{ animationDelay: "1.2s", animationDuration: "0.3s" }}
                    src={require("./resources/toon6_4.png")}
                    alt=""
                />

                <img
                    id="toon6_6"
                    style={{ animationDelay: "0.2s", animationDuration: "0.0s" }}
                    src={require("./resources/toon6_6.png")}
                    alt=""
                />
                <div
                    id="toon6_6_desc"
                    style={{ transitionDelay: "0.2s", transitionDuration: "0.0s" }}
                >
                    {language.translations.toon6.msg4}
                </div>
                <img
                    id="toon6_7"
                    style={{ animationDelay: "0.6s", animationDuration: "0.0s" }}
                    src={require("./resources/toon6_7.png")}
                    alt=""
                />
                <div
                    id="toon6_7_desc"
                    style={{ transitionDelay: "0.6s", transitionDuration: "0.0s" }}
                >
                    {language.translations.toon6.msg3}
                </div>
                <img
                    id="toon6_8"
                    style={{ animationDelay: "1.0s", animationDuration: "0.0s" }}
                    src={require("./resources/toon6_8.png")}
                    alt=""
                />
                <div
                    id="toon6_8_desc"
                    style={{ transitionDelay: "1.0s", transitionDuration: "0.0s" }}
                >
                    {language.translations.toon6.msg2}
                </div>
                <img
                    id="toon6_9"
                    style={{ animationDelay: "1.4s", animationDuration: "0.0s" }}
                    src={require("./resources/toon6_9.png")}
                    alt=""
                />
                <div
                    id="toon6_9_desc"
                    style={{ transitionDelay: "1.4s", transitionDuration: "0.0s" }}
                >
                    {language.translations.toon6.msg1}
                </div>

                <img
                    id="toon6_10"
                    style={{ transitionDelay: "0.2s", transitionDuration: "0.3s" }}
                    src={require("./resources/toon6_10.png")}
                    alt=""
                />
                <img
                    id="toon6_11"
                    style={{ transitionDelay: "0.5s", transitionDuration: "0.3s" }}
                    src={require("./resources/toon6_11.png")}
                    alt=""
                />
                <img
                    id="toon6_12"
                    style={{ transitionDelay: "0.8s", transitionDuration: "0.3s" }}
                    src={require("./resources/toon6_12.png")}
                    alt=""
                />

                <div
                    id="toon6_13_desc"
                    style={{ transitionDelay: "1.4s", transitionDuration: "0.0s" }}
                >
                    {formattedTranslations(language.translations.toon6.msg5)}
                </div>
            </div>

            <div id="toon7">
                <img
                    id="toon7_1"
                    src={require("./resources/toon7_1.png")}
                    alt="버즈링크 리워드 확인"
                />

                <img
                    id="toon7_2"
                    src={require("./resources/toon7_2.png")}
                    alt=""
                />
                <div id="toon7_2_desc1">
                    {language.translations.toon7.msg1}
                </div>
                {/* <CountUp1/> */}
                {countUpShow ? <CountUp1 /> : <div id="toon7_2_desc2">{language.type === EN ? '$ 0' : '0 원'}</div>}
                <img
                    id="toon7_3"
                    src={require("./resources/toon7_3.png")}
                    alt=""
                />
                <div id="toon7_3_desc1">
                    {language.translations.toon7.msg1}
                </div>

                {/* <CountUp2/> */}
                {countUpShow ? <CountUp2 /> : <div id="toon7_3_desc2">{language.type === EN ? '$ 0' : '0 원'}</div>}
            </div>

            <div id="toon8">
                <img
                    id="toon8_1"
                    src={require("./resources/toon8_1.png")}
                    alt="버즈링크 이야기"
                />
            </div>
        </div>
    );

    function CountUp1() {
        const { countUp } = useCountUp({
            start: 0,
            end: language.translations.toon7.price1,
            separator: ",",
            duration: 5,
            decimals: 0,
            decimal: ".",
            prefix: language.translations.toon7.prefix,
            suffix: language.translations.toon7.suffix,
        });
        return (
            <div id="toon7_2_desc2">
                <div>{countUp}</div>
            </div>
        );
    }

    function CountUp2() {
        const { countUp } = useCountUp({
            start: 0,
            end: language.translations.toon7.price2,
            separator: ",",
            duration: 5,
            decimals: 0,
            decimal: ".",
            prefix: language.translations.toon7.prefix,
            suffix: language.translations.toon7.suffix,
        });
        return (
            <div id="toon7_3_desc2">
                <div>{countUp}</div>
            </div>
        );
    }
}