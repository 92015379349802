import React, { useContext, useEffect } from "react";
import { ConfigContext } from "../../App/ConfigProvider";

import "./MainCard4.css";

export default function MainCard4({ page }) {
    const {
        language,
        formattedTranslations,
    } = useContext(ConfigContext);

    useEffect(() => {
        const copy = document.getElementById('maincard4_copy')
        const copy_mobile = document.getElementById('maincard4_copy_mobile')
        const bg1 = document.getElementById('maincard4_bg1')

        try {
            if (page === 3) {
                setTimeout(() => {
                    copy.style.opacity = 1
                    copy_mobile.style.opacity = 1
                }, 1500);

                setTimeout(() => {
                    bg1.style.transform = 'translateX(-50%) scaleX(1.0) scaleY(1.0)'
                }, 2000);
            } else {
                setTimeout(() => {
                    copy.style.opacity = 0
                    copy_mobile.style.opacity = 0
                }, 500);

                bg1.style.transform = 'translateX(-50%) scaleX(0.1) scaleY(0.1)'
            }
        } catch (e) {

        }
    }, [page])

    return (
        <div id='maincard4_bg'>

            <div id='maincard4_wrapper'>

                <div id='maincard4_bg1' />


                <div id='maincard4_copy'>
                    {formattedTranslations(language.translations.main.card4.copy1)}
                </div>
                <div id='maincard4_copy_mobile'>
                    {formattedTranslations(language.translations.main.card4.copy2)}
                </div>
            </div>
        </div >
    )
}