import React, {
    useEffect,
    useContext,
    useState,
} from "react";

import "./Support.css";


import { ConfigContext } from "../App/ConfigProvider.js";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

export default function Support({ ...props }) {
    const { language } = useContext(ConfigContext);
    const location = useLocation()

    const [selected, setSelected] = useState(-1)

    useEffect(() => {
        window.scrollTo(0, 0);

        const params = new URLSearchParams(location.search);
        const id = params.get("id");
        if (id !== null) {
            setSelected(id)
            setTimeout(() => {
                window.scrollTo(0, 10000)
            }, 500);
        } else {

        }
    }, []);

    const list = [
        {
            title: language.translations.support.contents.faq11.title,
            body: language.translations.support.contents.faq11.body,
            image_pc: require('./resources/faq-img.png'),
            image_mobile: require('./resources/m-faq-img.png')
        },
        {
            title: language.translations.support.contents.faq1.title,
            body: language.translations.support.contents.faq1.body,
        },
        {
            title: language.translations.support.contents.faq2.title,
            body: language.translations.support.contents.faq2.body,
        },
        {
            title: language.translations.support.contents.faq3.title,
            body: language.translations.support.contents.faq3.body,
        },
        {
            title: language.translations.support.contents.faq4.title,
            body: language.translations.support.contents.faq4.body,
        },
        {
            title: language.translations.support.contents.faq5.title,
            body: language.translations.support.contents.faq5.body,
        },
        {
            title: language.translations.support.contents.faq6.title,
            body: language.translations.support.contents.faq6.body,
        },
        {
            title: language.translations.support.contents.faq7.title,
            body: language.translations.support.contents.faq7.body,
        },
        {
            title: language.translations.support.contents.faq8.title,
            body: language.translations.support.contents.faq8.body,
        },
        {
            title: language.translations.support.contents.faq9.title,
            body: language.translations.support.contents.faq9.body,
        },
        {
            title: language.translations.support.contents.faq10.title,
            body: language.translations.support.contents.faq10.body,
        },
        {
            title: language.translations.support.contents.faq13.title,
            body: language.translations.support.contents.faq13.body,
        },
        {
            title: language.translations.support.contents.faq14.title,
            body: language.translations.support.contents.faq14.body,
        },
        {
            title: language.translations.support.contents.faq12.title,
            body: language.translations.support.contents.faq12.body,
        },
        {
            title: language.translations.support.contents.faq15.title,
            body: language.translations.support.contents.faq15.body,
        }
    ]


    return (
        <div className='support_container'>
            <Helmet>
                <title>{language.translations.titles.support}</title>
                <meta name="description" content="버즈링크 서비스의 자주 묻는 질문" />
            </Helmet>
            <div id='support_bg'>
                <div id='support_title'>{language.translations.support.title}</div>
                <div id='support_title2'>{language.translations.support.title2}</div>
                <div id='support_subtitle'>{language.translations.support.subtitle1 + " "}<a style={{ color: '#0e3ca5' }} href='mailto:support@buzlink.io'>{language.translations.support.subtitle2}</a>{language.translations.support.subtitle3}</div>

                {
                    list.map((item, index) => {
                        return (
                            <ItemView key={index} index={index} selected={selected} item={item} />
                        )
                    })
                }

                <div id='support_bottom' />
            </div>
        </div>
    )
}

function ItemView({ item, index, selected }) {
    const {
        formattedTranslations,
    } = useContext(ConfigContext);
    // selected={index === selected && selected >= 0}
    console.log(index + ", " + selected)
    const [collapsed, setCollapsed] = useState(true)

    useEffect(() => {
        setCollapsed(index != selected)
    }, [index, selected])

    if (collapsed) {
        return (
            <div id='support_itemview_collapsed' onClick={() => {
                setCollapsed(false)
            }}>
                <div className='support_itemview_title'>
                    {item.title}
                </div>
                <img className='support_icon' src={require('./resources/ico-faq-arrow-01.png')} alt='' />
            </div>
        )
    } else {
        return (
            <div id='support_itemview_expanded'>
                <div style={{ height: '90px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    onClick={() => {
                        setCollapsed(true)
                    }}>
                    <div className='support_itemview_title'>
                        {item.title}
                    </div>
                    <img className='support_icon' src={require('./resources/ico-faq-arrow-02.png')} alt='' />
                </div>
                <div style={{ marginLeft: '20px', marginRight: '20px', height: '1px', backgroundColor: '#e6e6e6' }} />

                <div id='support_itemview_expanded_body'>
                    {formattedTranslations(item.body)}
                </div>

                {
                    item.image_pc ?
                        <img className='support_itemview_expanded_image_pc' src={item.image_pc} alt='' /> :
                        <div />
                }
                {
                    item.image_mobile ?
                        <img className='support_itemview_expanded_image_mobile' src={item.image_mobile} alt='' /> :
                        <div />
                }
            </div>
        )
    }

}