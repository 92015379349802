import React, { useContext, useEffect } from "react";
import { ConfigContext } from "../../App/ConfigProvider";
import * as Utils from '../../App/Utils'
import "./MainCard5.css";

export default function MainCard5({ page }) {
    const {
        language,
        formattedTranslations,
    } = useContext(ConfigContext);

    useEffect(() => {
        const copy = document.getElementById('maincard5_copy')
        const copy_mobile = document.getElementById('maincard5_copy_mobile')
        const coin1 = document.getElementById('maincard5_coin1')
        const coin2 = document.getElementById('maincard5_coin2')
        const coin3 = document.getElementById('maincard5_coin3')
        const coin4 = document.getElementById('maincard5_coin4')
        const coin5 = document.getElementById('maincard5_coin5')
        const coin6 = document.getElementById('maincard5_coin6')


        try {
            if (page === 4) {
                setTimeout(() => {
                    copy.style.opacity = 1
                    copy_mobile.style.opacity = 1
                }, 1500);



                setTimeout(() => {

                    coin1.style.opacity = 1
                    coin2.style.opacity = 1
                    coin3.style.opacity = 1
                    coin4.style.opacity = 1
                    coin5.style.opacity = 1
                    coin6.style.opacity = 1

                    if (!Utils.isMobile()) {
                        coin1.style.left = '615.8px'
                        coin1.style.top = (738.2 / 1080 * window.innerHeight) + 'px'
                        coin2.style.left = '1158.6px'
                        coin2.style.top = (737.2 / 1080 * window.innerHeight) + 'px'
                        coin3.style.left = '678px'
                        coin3.style.top = (496 / 1080 * window.innerHeight) + 'px'
                        coin4.style.left = '595.4px'
                        coin4.style.top = (254.4 / 1080 * window.innerHeight) + 'px'
                        coin5.style.left = '1202px'
                        coin5.style.top = (258 / 1080 * window.innerHeight) + 'px'
                        coin6.style.left = '1128px'
                        coin6.style.top = (591 / 1080 * window.innerHeight) + 'px'
                    } else {
                        coin1.style.left = (10 / 320 * window.innerWidth) + 'px'
                        coin1.style.top = (355 / 551 * window.innerHeight) + 'px'
                        coin2.style.left = (213 / 320 * window.innerWidth) + 'px'
                        coin2.style.top = (397 / 551 * window.innerHeight) + 'px'
                        coin3.style.left = (20 / 320 * window.innerWidth) + 'px'
                        coin3.style.top = (205 / 551 * window.innerHeight) + 'px'
                        coin4.style.left = (16 / 320 * window.innerWidth) + 'px'
                        coin4.style.top = (85 / 551 * window.innerHeight) + 'px'
                        coin5.style.left = (250 / 320 * window.innerWidth) + 'px'
                        coin5.style.top = (133 / 551 * window.innerHeight) + 'px'
                        coin6.style.left = (236 / 320 * window.innerWidth) + 'px'
                        coin6.style.top = (289 / 551 * window.innerHeight) + 'px'
                    }
                }, 2000);

                setTimeout(() => {
                    const icons = document.querySelectorAll('.maincard5_icon')

                    if (icons) {

                        for (let i = 0; i < icons.length; i++) {
                            icons[i].style.opacity = 1;
                        }
                    }
                }, 2500);

                setTimeout(() => {
                    coin1.classList.add('maincard5_shake1')
                    coin2.classList.add('maincard5_shake2')
                    coin3.classList.add('maincard5_shake3')
                    coin4.classList.add('maincard5_shake4')
                    coin5.classList.add('maincard5_shake5')
                    coin6.classList.add('maincard5_shake6')
                }, 2500);




            } else if (page < 4) {
                setTimeout(() => {
                    copy.style.opacity = 0
                    copy_mobile.style.opacity = 0
                }, 500);

                const wrapper = document.getElementById('maincard5_wrapper')
                const coins = document.querySelectorAll('.maincard5_coin')
                for (let i = 0; i < coins.length; i++) {
                    coins[i].style.left = wrapper.clientWidth / 2 + 'px'
                    coins[i].style.top = window.innerHeight / 2 + 'px'
                    coins[i].style.opacity = 0;
                }

                coin1.classList.add('maincard5_shake1')
                coin2.classList.add('maincard5_shake2')
                coin3.classList.add('maincard5_shake3')
                coin4.classList.add('maincard5_shake4')
                coin5.classList.add('maincard5_shake5')
                coin6.classList.add('maincard5_shake6')


                const icons = document.querySelectorAll('.maincard5_icon')
                for (let i = 0; i < icons.length; i++) {
                    icons[i].style.opacity = 0;
                }
            }
        } catch (e) {

        }

    }, [page])


    return (
        <div id='maincard5_bg'>

            <div id='maincard5_wrapper'>

                <div id='maincard5_coin1' className='maincard5_coin' />
                <div id='maincard5_coin2' className='maincard5_coin' />
                <div id='maincard5_coin3' className='maincard5_coin' />
                <div id='maincard5_coin4' className='maincard5_coin' />
                <div id='maincard5_coin5' className='maincard5_coin' />
                <div id='maincard5_coin6' className='maincard5_coin' />


                <div id='maincard5_icon1' className='maincard5_icon' />
                <div id='maincard5_icon2' className='maincard5_icon' />
                <div id='maincard5_icon3' className='maincard5_icon' />
                <div id='maincard5_icon4' className='maincard5_icon' />
                <div id='maincard5_icon5' className='maincard5_icon' />
                <div id='maincard5_icon6' className='maincard5_icon' />
                <div id='maincard5_icon7' className='maincard5_icon' />
                <div id='maincard5_icon8' className='maincard5_icon' />

                <div id='maincard5_copy'>
                    {formattedTranslations(language.translations.main.card5.copy1)}
                </div>
                <div id='maincard5_copy_mobile'>
                    {formattedTranslations(language.translations.main.card5.copy2)}
                </div>
            </div>
        </div >
    )
}