import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { PATHS } from '../App/App'
import { ConfigContext } from '../App/ConfigProvider'
import './Footer.scss'

export function Footer() {
    const location = useLocation()

    const [hide, setHide] = useState(false)

    useEffect(() => {
        if (location.pathname === PATHS.fixing) {
            setHide(true)
        } else if (location.pathname === PATHS.home) {
            setHide(true)
        } else if (location.pathname === PATHS.buzpoint) {
            setHide(true)
        } else if (location.pathname === PATHS.buzmap) {
            setHide(true)
        } else {
            setHide(false)
        }
    }, [location])

    if (hide) {
        return <div />
    } else {
        return <FooterView />
    }
}

export function FooterView() {
    const { language, formattedTranslations } = useContext(ConfigContext)

    return (
        <div id="footer_container">
            <div
                style={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    width: '100%',
                    height: '1px',
                    backgroundColor: '#e6e6e6',
                }}
            />

            {/* contents */}
            <div id="g_footer_wrapper">
                <div>
                    {/* logo */}
                    <div id="footer_info">
                        {formattedTranslations(
                            language.translations.footer.copy6
                        )}
                    </div>
                </div>
                {/* copies */}
                <div id="footer_copies">
                    {/* row 0 */}
                    <div id="footer_buttons">
                        <Link className="footer_copy" to={PATHS.agreement}>
                            <div>{language.translations.footer.copy1}</div>
                        </Link>

                        <Link className="footer_copy" to={PATHS.terms}>
                            <div>
                                <b>{language.translations.footer.copy2}</b>
                            </div>
                        </Link>

                        <Link className="footer_copy" to={PATHS.support}>
                            <div>{language.translations.nav.support}</div>
                        </Link>
                    </div>

                    {/* row 1 */}
                </div>
            </div>

            {/* mobile */}
            <div id="g_footer_wrapper_mobile">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {/* copies */}
                    <div id="footer_copies">
                        {/* row 0 */}
                        <div id="footer_buttons">
                            <Link className="footer_copy" to={PATHS.agreement}>
                                <div>{language.translations.footer.copy1}</div>
                            </Link>
                            <Link className="footer_copy" to={PATHS.terms}>
                                <div>
                                    <b>{language.translations.footer.copy2}</b>
                                </div>
                            </Link>
                            <Link className="footer_copy" to={PATHS.support}>
                                <div>{language.translations.nav.support}</div>
                            </Link>
                        </div>

                        {/* row 1 */}
                    </div>
                </div>
                <div>
                    {/* logo */}
                    <div id="footer_copyright">
                        {formattedTranslations(
                            language.translations.footer.copy6_mobile
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
