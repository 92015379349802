import React from 'react'

export function getTranslation(str) {
    const a = { __html: str }
    return <div dangerouslySetInnerHTML={a}></div>
}

export const en = {
    titles: {
        main: 'BuzLink',
        login: 'Login - BuzLink',
        login_forgot: 'Find password - BuzLink',
        change_password: 'Change Password - BuzLink',

        join: 'Join - BuzLink',

        reset_complete: 'Reset Password - BuzLink',
        event: 'Event - BuzLink',

        buzfriends: 'Buz Friends - BuzLink',
        buzpoint: 'Buz Point - BuzLink',

        whatisbuz: 'What is buz? - BuzLink',
        buzmap: 'BuzMap - BuzLink',

        history: 'History - BuzLink',

        agree1: 'Agreement - BuzLink',
        agree2: 'Privacy Policy - BuzLink',
        contactus: 'Contact Us - BuzLink',
        support: 'Support - BuzLink',
        myinfo: 'My Info - BuzLink',
        share: 'BuzLink',

        unsubscribe: 'BuzLink',
        error: 'Oops - BuzLink',
        fixing: 'Now Fixing - BuzLink',
    },

    nav: {
        whatisbuz: 'BuzLink?',
        event: 'Event',
        buzmap: 'Buz Map',
        buzfriends: 'Buz Friends',
        buzpoint: 'Buz Point',
        myinfo: 'My Info',
        support: 'Support',
    },
    copied: 'Complete.',
    copied2: 'Link copied',

    main: {
        card1: {
            copy1:
                'Easy to Share but Get Super Rewards<br/>Get rewards from everyday sharing',
            copy2:
                'Easy to Share but Get Super Rewards<br/> Get rewards from everyday sharing',
            button: 'Start',
        },
        card2: {
            copy1: "Let's get the BuzLink!",
            copy2: "Let's get the BuzLink!",
            button: 'What is<br/>BuzLink?',
        },
        card3: {
            copy1: 'How can we <b>make</b> BuzLink?',
            copy2: 'How can we<br/><b>make</b> BuzLink?',
            button: 'Buz<br/>Friends',
        },
        card4: {
            copy1: 'What shape is my BuzLink?',
            copy2: 'What shape is my BuzLink?',
            button: 'Buz<br/>Map',
        },
        card5: {
            copy1: 'BuzPoints, the most <b>useful</b> reward!',
            copy2: 'BuzPoints,<br/>the most <b>useful</b> reward!',
            button: 'Buz<br/>Points',
        },

        delayedtitle: 'Go to event',
        footer: {
            copy1_1: 'What is BuzLink?',
            copy1_2: 'BuzLink, that provide<br/>rewards through sharing',
            copy1_4:
                'BuzLink is a service that embraces and applies<br/>the power of social networking sharing to reward all participants.',

            copy5_1: 'How it Works',
            copy5_2: 'Create a BuzLink',
            copy5_3: 'Share to friends',
            copy5_4: 'Get rewarded for actions',

            copy5_5:
                'Click the “BuzLink” button to create your own BuzLink. Your own BuzLink will be created in the blockchain.',
            copy5_6:
                'Share BuzLink via SNS, or copy BuzLink to share with friends. BuzLink connects people who share to each other through a blockchain.',
            copy5_7:
                'When you sign up or make a purchase through sharing, rewards are paid to people connected by BuzLink. The rewards generated will be received transparently, and there is no limit on the rewards.',

            copy2_1: 'What is Different?',
            copy2_2: '#1 Limit<b>less</b> Sharing, and Impact',
            copy2_3:
                'Rewards that grow as the radius of influence of your shared links grow.',

            copy3_1: '#2 BP (Buz Point), Cash-like Rewards',
            copy3_1_2: '#2 BP (Buz Point),<br/>Cash-like Rewards',
            copy3_2:
                'Redeem your points for money anytime, anywhere!<br/>Or send points directly to your friends',
            copy3_4:
                '*Rewards are given in BP(Buz Point).<br/>1BP is treated as 1 KRT, and can be freely exchanged for cash.',

            copy4_1: '#3 BaaS (Blockchain as a Service)',
            copy4_1_2: '#3 BaaS<br/>(Blockchain as a Service)',
            copy4_2:
                'BuzLink aims to revolutionize sharing through the transparency of blockchain smart contracts.<br/><b>BuzLink</b> provides services through the Terra Blockchain.',
            copy4_3: 'Go to Terra',

            bass1: {
                copy1: '<b>BuzLink Generate</b>',
                copy2:
                    'When creating BuzLink, a blockchain wallet and data are immediately generated.<br/>Then, inœformation such as blockchain data, your personal BuzLink URL, and product information are combined to create a BuzLink.',
            },

            bass2: {
                copy1: '<b>Share & Regenerate</b>',
                copy2:
                    'When you enter a merchants page through a BuzLink,<br/>a second BuzLink can be generated within the product page by clicking on the BuzLink share button.<br/>The newly generated BuzLink is immediately connected to the previously shared link.',
            },

            bass3: {
                copy1: '<b>Data Structure</b>',
                copy2:
                    'A data structure is created by connecting the relationships between related BuzLinks.<br/>This data structure has the following advantages:',
                copy3: 'Visualization of Data',
                copy4: 'Network-effect and<br/>Information Flow Analysis',
                copy5: 'Performance-based<br/>Payment and Settlement',
            },
            bass4: {
                copy1: '<b>Distribution</b>',
                copy2:
                    'Rewards to be distributed are defined algorithmically within the BuzLink smart contracts.<br/><br/>By analyzing the relationships between various generated Buzlinks, purchases and link generations can be tracked to see the contribution of each user towards a given purchase, and rewards are distributed accordingly.<br/><br/>Data is also protected from manipulation as a by-product of using blockchain technology.',
            },
            bass5: {
                copy1: '<b>Visualization of Virality</b>',
                copy2:
                    'New information structures can be garnered by analyzing the blockchain data.<br/><br/>Data such as the number of shared links, the impact between related links, spatio-temporal network patterns, and network effects of shared contents can be more easily visualized and understood. ',
            },

            copy_footer: 'Easy to share but get super rewards',
            copy_footer2: 'Easy to share but get super rewards',
        },
    },

    buzpointIntro: {
        copy1: 'We inform you about<br/>BuzLink <b>Rewards.</b>',
        copy2:
            'Rewards received through BuzLink<br/>have been prepared to be freely used<br/>with reduced restrictions.<br/>*Withdrawal is possible through<br/>Cash, Chai, and Terra',
        button: 'Sign Up',
    },
    buzpoint: {
        total_reward: 'Total Rewards',
        used_reward: 'Used Rewards',
        history: 'History',
        amountpaid: 'Amount to be paid',
        warning:
            'What is BP to be paid?<br/>This is the BP that is paid when the purchase of products shared through BuzLink, and will be paid after the purchase is confirmed. However, if there is a cancellation or return after purchase, the expected BP and the actual paid BP may be different. ',

        desc: 'If you want, feel free to <br/>redeem rewards anytime.',
        myreward: 'My Rewards',
        point_exchange: 'Point exchange',
        tooltip: '(1BP is treated as 1 won)',
        filter: 'You can withdraw over 10,000 BP',
        filter2: 'You can withdraw over 5,000 BP',
        exchange_way: 'Choose an exchange type',
        cash: {
            english:
                'Cash service is only provided by banks in korea<br/>Please change to korean',
            not_registered: {
                bank: 'Choose your bank',
                copy1:
                    'After registering an account in your name, you can use the service.',
                copy2: "Number only. without '-'(dash)",
                copy3: 'Notice',
                copy4: 'Register',
                copy5: 'Done',

                warning1: '',
            },
            registered: {
                copy1: 'Account Info',
                copy2: 'Bank',
                copy3: 'Account Holder',
                copy4: 'Bank Account',
                success:
                    '출금 신청이 완료되었습니다. 은행 점검시간 외에 몇분 이내로 출금될 예정입니다.',
                warning:
                    '정산을 위해 매일 23:00~07:00까지 현금 교환 신청이 불가능합니다.',
            },
        },
        chai: {
            title: 'COMING SOON',
        },
        terra: {
            coin: 'Select Asset',
            copy1: 'Asset',
            copy2: 'Wallet address',
            copy3: 'Memo(optional)',
            copy4: 'Amount',
            copy5: 'Paste',

            addressplaceholder:
                'Terra cannot be transferred to the exchange wallet.',

            warningtitle: 'Terra exchange notice',
            warning1:
                '<ol><li><b>There are currently exchange transaction recognition issues. For Terra withdrawal, please use your personal wallet(<a id="terraview_warning_a" target="_blank" href="https://terra.money">Terra Station https://terra.money</a>).</b></li><li>Cancellation is not possible after requesting for exchange (due to the characteristics of cryptocurrency), When exchanging, please check the wallet address before entering it.</li><li>Since the exchange request is processed on the blockchain network, the exchange time may be delayed depending on the blockchain network condition. In the event of an abnormality in the blockchain network, BuzLink cannot intervene in the blockchain network, so additional actions cannot be taken in case of delay or abnormal situation.</li><li>Terra withdrawal is only possible with KRT for system stability.</li></ol>',
            success: '로 출금이 완료 되었습니다.',

            withdrawtitle: 'Withdrawal is complete.',
            withdrawtitle2: 'Address',
            withdrawtitle3: 'Amount',
            withdrawtitle4: 'Done',
        },
        confirm: 'Apply',
    },

    history: {
        title: 'History',
        used_point: 'Total BuzPoint',
        total_reward: 'Total Rewards',
        used_reward: 'Used Rewards',
        detail: 'Detail',

        filter0: 'Received Rewards',
        filter1: 'Used Rewards',

        reason1: 'Reward / Deposit',
        reason2: 'Cash / Withdraw',
        reason3: 'Terra / Withdraw',
        reason4: 'BuzLink Bonus',

        pending: 'Pending',
        success: 'success',
        fail: 'fail',
        cancel: 'cancel',

        withdraw_terra: 'Withdraw Terra',
        withdraw_cash: 'Withdraw Cash',
    },

    footer: {
        copy1: 'Terms of Use',
        copy2: 'Privacy Policy',
        copy4: 'Copyright © Buzlink Co.,Ltd. All Rights Reserved.',
        copy5: 'Contact US',
        copy6:
            'BreezeLabs Inc. ㅣ  CEO 이상화  ㅣ  Company Registration Number : 260-87-02130<br/>3rd floor, 225-15, Pangyoyeok-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea<br/>Copyright © BreezeLabs Co.,Ltd. All Rights Reserved.',
        copy6_mobile:
            'BreezeLabs Inc. ㅣ  CEO 이상화  ㅣ  Company Registration Number : 260-87-02130<br/>3rd floor, 225-15, Pangyoyeok-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea<br/>Copyright © BreezeLabs Co.,Ltd. All Rights Reserved.',
        copy7: 'Notice',
        copy8: 'Blog',
    },

    agreement: {
        copy0: 'BuzLink Rule',
        copy1: 'Terms of Use',
        copy2: 'Privacy Policy',
    },

    toonheader: {
        copy1:
            "BuzLink earns rewards through<br/><b>'share with BuzLink'</b><br/>So, shall we see how to share BuzLink?",
    },

    toon1: {
        msg1: 'Share<br/>to BuzLink',
        msg2: '# Click and get your BuzLink ',
    },
    toon2: {
        msg1: 'Click<br/>the link!!',
    },
    toon3: {
        msg1: 'I Like it❤︎',
        msg2: 'Share with<br/>BuzLink!',
        msg3: '# If you share the link with your friends',
    },
    toon6: {
        msg1: '+$10',
        msg2: '+$15',
        msg3: '+$30',
        msg4: 'Purchase!',
        msg5:
            '#You receive reward depending on the BuzLink<br/>that is directly connected to the purchase.',
    },
    toon7: {
        msg1: 'My reward is',
        price1: 123,
        price2: 112,
        prefix: '$ ',
        suffix: '',
    },

    main_popup_common4: 'Close',
    main_popup_common5: 'Done',
    main_popup_common6: 'Do not watch for the day',

    login: {
        login: 'Welcome',
        login_btn: 'Log in',
        enter_email: 'Enter Email',
        enter_pw1: 'Password',
        enter_pw2: 'Confirm Password',
        logout: 'Logout',
        welcome1_1: 'Reset Password',
        welcome1_2: 'Change the password',
        signup: 'Sign-up',
        forgot: 'Forgot your password?',
        forgot_title: 'Reset Password',
        forgot_desc:
            'When the password is lost, We are helping you reset your password by email.',
        forgot_send: 'Send email',
        forgot_login: 'Login',
        forgot_msg:
            'An email has been sent. Check your email and reset your password.',
        reset_pw_button: 'Complete',

        changepw1: 'Current password',
        changepw2: 'Enter new password',
        changepw3: 'Confirm Password',

        pwchange_done: 'Password has been changed.<br/>Please login again.',

        abusetitle1: 'Notice<br/>for abnormal activity',
        abusetitle1_mobile: 'Notice<br/>for abnormal activity',
        abusetitle2: 'Done',
    },
    support: {
        title: 'FAQ',
        title2: 'What do you need help with?',
        subtitle1: 'If you have an unresolved question, Please contact us at',
        subtitle2: 'support@buzlink.io',
        subtitle3: '',

        contents: {
            faq1: {
                title: 'What is BuzLink?',
                body:
                    "<ol><li>'BuzLink' is a user-generated link that stores the information of both the linked website as well as corresponding blockchain data.<br/></li><li>When you <b>share</b> or <b>recommend</b> a product to a friend through a Buzlink, <b>everyone involved</b> earns rewards depending on the interactions between new users (registering for BuzLink, new purchases, views, etc.)<br/></li><li>Rewards are distributed according to the degree of contribution by a given user in the total purchase process. For example, User A shares to User B, User B then shares to User C, and someone purchases something through User C's BuzLink. Then Users C, B, and A will receive rewards, with C receiving the largest share, followed by B and A respectively. Similarly, if a purchase was made through User B's link, then B and A will receive rewards with B receiving a larger portion of the rewards.</li></ol>",
            },
            faq2: {
                title: 'What is the pre-launch event?',
                body:
                    '<ol><li>The pre-launch event is open for users to try BuzLink before the official launch.<br/></li><li>The only way to join the event is to join by entering your email address. Once you register, you will be immediately awarded 3,000 bonus BPs. <b>The most important thing to remember is that active sharing provides more rewards, and rewards that can be earned through sharing are limitless.</b></li></ol>',
            },
            faq3: {
                title: 'How do I create a BuzLink?',
                body:
                    '<ol><li>You will be only able to make a BuzLink through your <b>email address</b>. (In order for rewards to be able to be received, you will need to verify your email.)<br/></li><li>Currently, you will only be able to generate a link on the product page by pressing on the BuzLink button.  Once BuzLink officially launches, there will be further information on how to receive rewards.</li></ol>',
            },
            faq4: {
                title: 'When will I receive rewards?',
                body:
                    "<ol><li>After entering and verifying your email address, you will be immediately credited <b>3,000 won</b> worth of BP rewards. In addition, through your personal BuzLink, you will be able to keep earning rewards through sharing. For more specific information, please refer to the event page.<br/></li><li>Under most circumstances, you will be able to create your BuzLink, and through <b>'specific tasks'</b>, you will be rewarded BPs. Once BuzLink officially launches, there will be further information on how to receive awards.</li></ol>",
            },
            faq5: {
                title: 'Where do I check my rewards, and how can I use them?',
                body:
                    '<ol><li>You can see your rewards in <b>"My Buz" > "Rewards"</b> tab.<br/></li><li>You can redeem these points directly for cash deposit (minimum of 10,000 BP), Terra stable coins, or transfer them to CHAI points (TBA).</li></ol>',
            },
            faq6: {
                title: 'How do I claim my rewards?',
                body:
                    "<ol><li><b>1 BP</b> is worth <b>1 won</b>.<br/></li><li>After logging in, you will be able to redeem your points at any time in the 'Rewards' tab. You can withdraw your points directly for won into your linked bank account. If you decide to receive your rewards in Terra stablecoin, you can send any amount by entering your Terra wallet address.</li></ol>",
            },
            faq7: {
                title: 'Can I send my rewards to friends?',
                body:
                    '<ol><li>Yes, you will be able to send your rewards to friends in the official launch version. The launch is scheduled to be released in the first quarter of 2021.</li></ol>',
            },
            faq8: {
                title:
                    "I shared my BuzLink to my friends, but I didn't receive any rewards.",
                body:
                    '<ol><li>First double check whether or not your friend joined the pre-launch event. If they have done so, you should be able to see your rewards in <b>"My Buz>" -> "Rewards"</b> page.</li><li>After signing up for the event, your rewards will be credited to your account within 10 minutes (dependent on the speed of the blockchain transaction).</li></ol>',
            },
            faq9: {
                title: 'Why does BuzLink use blockchain technology?',
                body:
                    "<ol><li>By utilizing blockchain technology, information about transactions and shares are <b>transparent and immutable</b>. This allows the distribution of BuzLink rewards to be <b>fair</b> and <b>unmanipulatable</b> by anyone. <br/></li><li>BuzLink utilizes Terra's blockchain to record all relevant transaction information.</li></ol>",
            },
            faq10: {
                title: 'I want to be a BuzLink affiliate partner!',
                body:
                    '<ol><li>Let us know by clicking on the <b>Contact Us</b> button at the bottom of the page. We will get back to you as soon as possible!</li></ol>',
            },
            faq11: {
                title: 'Rewards cannot be paid.',
                body:
                    "<ol><li>Rewards may be paid slowly, but are not unpaid.</li><li>BuzLink operates as a blockchain. Blockchain internally performs a verification process, Therefore, it is slower than the existing server model. However, once a request has been processed, it cannot be reversed, and it is processed sequentially according to the order of distribution request.</li><li>Sudden increase in traffic may delay reward payments. There is no non-payment, so please wait for a while.</li><li>The number of connected Buzlinks refers to the number of all Buzlinks connected to your own Buzlink. This is a number that includes all links of links of links of links. Rewards for this pre-participation event will be awarded up to 'Link- Link - Link(3 Step).Therefore, there is a possibility that the number of connected BuzLink and reward payments may not be proportional.</li></ol> ",
            },
            faq12: {
                title: 'I cannot withdraw.',
                body:
                    '<ol><li>We apologize for any inconvenience.<br/>Currently, BuzLink is only opening pre-events.</li><li>Membership withdrawal function is supported after the official service is opened.</li><ol>',
            },
            faq13: {
                title:
                    'When is the abnormal access detection guidance displayed?',
                body:
                    '<ol><li>Abnormal access is detected by our detection system when access is made in an abnormal way rather than using the normal service.“I participated in the normal way?” If you do, please wait a bit. Those detected will be examined once more, and those who have no abnormality will be able to use the service again. For detailed inquiries, please contact the support email.</li></ol>',
            },
            faq14: {
                title: 'What is the abnormal activity?',
                body:
                    '<ol><li>The BuzLink team operates an abnormality detection system for smooth sharing. The following sharing behaviors may be detected as abnormal behaviors and may be penalized. </li><ol style="list-style-type: none"><li>1. Don\'t be reckless or repeatedly share!</li><li>2. Stop posting indiscriminately that doesn\'t match the topic or content!</li><li>3. Do not create an account indiscriminately through an abnormal method!</li></ol><li>If it is detected as an abnormal activity account, please reply to the email by following the instructions. Please remember that in some cases, activity restrictions and rewards may not be available!</li></ol>',
            },
            faq15: {
                title:
                    'Terra (luna) withdrawn to the exchange is not in the exchange wallet.',
                body:
                    '<ol><li>Did you withdraw to the exchange? Withdrawals to the exchange can only be processed by the exchange.</li><li>Withdrawal from BuzLink to the exchange is not possible. If you do not know how to deal with the wrong withdrawal of Terra, please send the withdrawal exchange, wallet address and amount to <a href="mailto://support@buzlink.io">support@buzlink.io</a>. We will help. Thank you</li></ol>',
            },
        },
    },
    contactus: {
        title:
            'The optimal choice for exponential<br/> spread and viral growth',
        title2:
            'The optimal choice for exponential<br/> spread and viral growth',
        subtitle1: 'BuzLink is',
        subtitle2:
            "Create powerful viral effects and visually provide data on the world's first viral effects",
        subtitle3:
            'Offers transparent performance tracking and performance-based marketing costs through blockchain.',
        subtitle2_2:
            "Create powerful viral effects and visually provide data on the world's first viral effects",
        subtitle3_2:
            'Offers transparent performance tracking and performance-based marketing costs through blockchain.',
        msg1: 'Contact Us',
        msg2:
            'Thank you for your interest in BuzLink.<br/>Please fill in the information to be contacted and we will contact you as soon as possible.',
        msg2_2:
            'Thank you for your interest in BuzLink.<br/>Please fill in the information to be contacted and we will contact you<br/>as soon as possible.',
        agreetitle: 'I Agree',
        agreesubtitle1: 'I agree to <b>Terms of use</b>',
        agreesubtitle2: 'and <b>Privacy Policy.</b>',
        complete: 'Complete',

        registered: 'registration completed',
    },
    initpw: {
        title: 'Welcome to Buzzer!',
        subtitle: 'Please set your password.',
        msg1: 'Set password',
        msg2: 'Password',
        msg3: 'Confirm Password',
        tooltip1:
            'The password must be at least 10 letters including special symbols.',
        msg4: 'Enter mobile number',
        tooltip2: 'Password is incorrect.',
        msg5: 'Resend',
        msg6: 'Send Code',
        msg7: 'Enter Code',
        msg8: 'I agree to <b>Terms of use</b>',
        msg9: 'and <b>Privacy Policy.</b>',
        msg10: 'Receive promotional information(Option)',
        msg11: 'Agree to all of the following.',

        complete: 'Complete',

        country: 'Country',

        loading1: 'Generating blockchain wallet and data',
        loading2: 'Registering for BuzLink smart contract',
        loading3: 'Now Distributing...',

        join_done: 'Sign up is complete.',
        showpw: 'Show password',

        delayed:
            'Rewards payment is currently being delayed.<br/>Please share the created Bizlink first.<br/>Rewards will be paid in up to 24 hours.<br/>We apologize for the inconvenience.',
    },

    event: {
        resend_success:
            'An email has been sent. Check your email and reset your password.',
        resend_cancel: 'Cancel',
        resend_send: 'Email resend',
    },

    sdkPopup: {
        step0: {
            title1: '<b>Easy</b> to share but get <b>super rewards</b>',
            title2: 'Create a BuzLink just by entering an email.',
            placeholder: 'please, enter email.',
            title3: 'change',
            button: 'Create BuzLink',
        },
        step1: {
            title1: '<b>Share</b> the created BuzLink <br /> to your friends',
            copy: 'Copy',
            title2: 'Welcome to the Buzzer',
            title4: 'Email was sent.',
            title5: 'Please check your email.',
            title6: '<b<Wait!</b> Share your BuzLink with your friends!',
        },

        footer1: {
            msg1: 'If you wonder about BuzLink?&nbsp;',
            msg2: 'Go to BuzLink Home',
        },
        footer2: {
            msg1: 'If you wonder about received rewards?&nbsp;',
            msg2: 'Check My Rewards',
        },
    },
    unsubscribe: {
        title: 'Unsubscribe e-mail',
        subtitle1: 'This is the unsubscribe page<br/>for the BuzLink email.',
        subtitle2: 'Do you want to unsubscribe e-mails ?',
        yes: 'Yes',
        no: 'No',
        done: 'Unsubscribed.',
    },

    buzfriends: {
        more: 'More',
        hide: 'Hide',
    },

    join: {
        title: 'Hi! New Buzzer',
        body1: 'Create a new account',
        body2: 'Enter your email and be a buzzer',
        enter_email: 'Enter Email',
        button: 'Sign-up',
        login: 'Login',

        done_title: 'Congratulation',
        done_message1: 'Welcome to the buzzer.',
        done_message2:
            'Please complete the registration<br/>through the email sent.',
        done_button: 'Done',

        already_title: 'Why?',
        already_body1: 'This mail is already in use.',
        already_body2: 'Forgot your password?',
        already_button1: 'Back',
        already_button2: 'Reset Password',

        resend_title: 'Why?',
        resend_body1: 'This email has not been<br/>completed',
        resend_body2:
            'If you have not received the email,<br/>please click the resend.',

        resend_body3:
            'This email has not been completed. If you have not received the email, please click the resend.',

        resend_button1: 'Back',
        resend_button2: 'Resend',
    },

    buzmapIntro: {
        copy1: 'Go to the BuzLink<br/>I shared',
        copy2:
            'In BuzMap,<br/>you can check how the shared<br/>BuzLink is linked Experience the shared map of BuzLink.',
        button: 'Sign Up',
    },
    buzmaplist: {
        desc: 'Share My BuzLink and<br/>get limitless rewards.',
        copy2: 'share',
        copy3: 'ended',
        share: 'Connected BuzLink',
        reward: 'reward',
        people: 'people',
        total_reward: 'Total Rewards',
        nolist: 'There is no BuzLink shared yet.',
        genbutton: 'Create BuzLink',
        all: 'All',
        favorite: 'Favorite',
        nofav: 'There is no favorite.',
    },
    buzmap: {
        share: 'Share',
        people: 'people',
        connected: 'Connected BuzLink',
        people2: 'people',
        people3: 'people',
        me: 'Me',
        friend1: 'Friend',
        friend2: 'F-Friend',
        friend3: 'F-F-Friend',
        friend4: 'Others',

        warning1:
            '<ol><li>It shows the connection of BuzLink and may be different from the reward payment amount.</li></ol>',
        warning2:
            '<ol><li>There is a difference between the number of all linked links and the amount of the reward paid.</li><li>Rewards are paid when a user performs a specified action, Distributed through connected Buzlink.</li></ol>',
    },

    myinfo: {
        info: 'General',
        name: 'Name',
        id: 'ID',
        phone: 'Phone',
        pw: 'Password',
        bank: 'Bank Account',
        change: 'Change',
        promotion: 'Notification',
        promotionallow: 'Promotion & Marketing',

        copy1:
            'When changing your mobile phone number, you need to verify your identity.',
        copy3:
            'Email has been sent. Please check your email and set a new password.',
    },
}

export const ko = {
    titles: {
        main: '버즈링크',
        login: '로그인 - 버즈링크',
        login_forgot: '비밀번호 찾기 - 버즈링크',
        change_password: '비밀번호 변경 - 버즈링크',

        join: '회원가입 - 버즈링크',

        reset_complete: '비밀번호 재설정 - 버즈링크',
        event: '사전 모집 이벤트 - 버즈링크',

        buzfriends: '버즈프렌즈 - 버즈링크',
        buzpoint: '버즈포인트 - 버즈링크',

        whatisbuz: '버즈링크란? - 버즈링크',
        buzmap: '버즈맵 - 버즈링크',

        history: '히스토리 - 버즈링크',

        agree1: '이용약관 - 버즈링크',
        agree2: '개인정보처리방침 - 버즈링크',
        contactus: 'Contact Us - 버즈링크',
        support: '고객센터 - 버즈링크',
        myinfo: '내 정보 - 버즈링크',
        share: '버즈링크',

        unsubscribe: '버즈링크',
        error: 'Oops - 버즈링크',
        fixing: '점검중 - 버즈링크',
    },

    nav: {
        whatisbuz: '버즈링크란?',
        event: '이벤트',
        buzmap: '버즈맵',
        buzfriends: '버즈프렌즈',
        buzpoint: '버즈 포인트',
        myinfo: '회원정보',
        support: '고객지원',
    },
    copied: '버즈링크 복사가 완료되었습니다',
    copied2: '링크를 복사하였습니다.',

    main: {
        card1: {
            copy1:
                '앱테크보다 간단하게 알바보다 짭짤하게<br/>일상적인 공유로 파격적인 혜택을 나누세요',
            copy2:
                '앱테크보다 간단하게<br/>알바보다 짭짤하게<br/>일상적인 공유로<br/>파격적인 혜택을 나누세요',
            button: 'Start',
        },
        card2: {
            copy1: '먼저 <b>버즈링크</b>를 알아볼까요?',
            copy2: '먼저 <b>버즈링크</b>를<br/>알아볼까요?',
            button: '버즈링크가<br/>뭐야?',
        },
        card3: {
            copy1: '어디서 버즈링크를 <b>만들 수</b> 있을까?',
            copy2: '어디서 버즈링크를<br/><b>만들 수</b> 있을까?',
            button: 'Buz<br/>Friends',
        },
        card4: {
            copy1: '나의 버즈링크는 얼마나 퍼졌을까?',
            copy2: '나의 버즈링크는 얼마나 퍼졌을까?',
            button: 'Buz<br/>Map',
        },
        card5: {
            copy1: '<b>바로 사용</b>할 수 있는, 버즈포인트!',
            copy2: '<b>바로 사용</b>할 수 있는,<br/>버즈포인트!',
            button: 'Buz<br/>Points',
        },

        delayedtitle: '이벤트 참가하기',
        footer: {
            header: '웹툰 보러 가기',

            copy1_1: 'What is BuzLink?',
            copy1_2: '<b>버즈링크, 공유를 통해<br/>혜택을 나누는 링크</b>',
            copy1_4:
                '버즈링크는 공유에 따른 확산효과를 기반으로 발생되는 혜택을 참여자 모두와 함께 나누는 서비스입니다.',

            copy5_1: 'How it works',
            copy5_2: '버즈링크 생성하기',
            copy5_3: '지인에게 공유하기',
            copy5_4: '액션에 따라 리워드 받기',

            copy5_5:
                '"버즈링크" 버튼을 클릭하여 나의 버즈링크를 만드세요. 블록체인 정보와 조합된 나만의 버즈링크가 생성됩니다.',
            copy5_6:
                'SNS를 통해 공유하거나, 버즈링크를 복사해서 친구에게 공유하세요. 버즈링크는 공유한 사람들끼리 블록체인으로 연결됩니다.',
            copy5_7:
                '공유를 통해 가입을 하거나 구매가 발생하면, 버즈링크로 연결된 사람들에게 리워드가 지급됩니다. 발생된 리워드는 블록체인 신뢰를 바탕으로 투명하게 지급되며 리워드의 제한은 없습니다.',

            copy2_1: 'What is different?',
            copy2_2: '#1 Limit<b>less</b> 공유, 그리고 확산',
            copy2_3:
                '내가 공유한 링크로 서로 연결되고,<br/>더 많이 <b>퍼질수록</b> 더 커지는,<br/>제한없는 리워드',

            copy3_1: '#2 BP(BuzPoint), 자유로운 현금성 리워드',
            copy3_1_2: '#2 BP(BuzPoint),<br/>자유로운 현금성 리워드',
            copy3_2:
                '언제 어디서든 현금등으로 환전! 은 물론<br/>친구에게 보낼 수 있는 자유로운 리워드',
            copy3_4:
                '*공유에 따른 리워드는 BuzPoint(BP)로 지급됩니다.<br/>1BP는 1원으로 취급되며, 자유롭게 현금등으로 바꿔서 사용할 수 있습니다.',

            copy4_1: '#3 BaaS (Blockchain as a Service)',
            copy4_1_2: '#3 BaaS<br/>(Blockchain as a Service)',
            copy4_2:
                '블록체인의 투명성을 기반으로, 스마트 컨트랙트의 공정함을 통해,<br/>공유의 혁신을 추구합니다.<br/><b>버즈링크</b>는 Terra Blockchain을 통해 서비스를 제공합니다.',
            copy4_3: 'Terra 바로가기',

            bass1: {
                copy1: '<b>버즈링크 생성</b>',
                copy2:
                    '공유를 위한 BuzLink를 만들때, 먼저 <b>블록체인 지갑 및 데이터</b>를 생성합니다.<br/>이후 공유하고자 하는 컨텐츠의 정보와 관련 블록체인 데이터, 공유 URL 등을 조합하여 <b>BuzLink를 생성</b>합니다.',
            },

            bass2: {
                copy1: '<b>공유 & 링크연결</b>',
                copy2:
                    'BuzLink를 통해 해당 페이지에 방문합니다.<br/>페이지 내에서 다시 BuzLink를 생성할수 있으며, 이전과 동일한 프로세스로 진행됩니다.<br/><br/>이때, <b>공유받은 BuzLink와 새로운 BuzLink가 연결되어</b> BuzLink간의 관계가 만들어 집니다.',
            },

            bass3: {
                copy1: '<b>데이터 구조</b>',
                copy2:
                    '만들어진 BuzLink의 관계는 <b>시스템 내부에서 데이터가 구조화 되고</b>,구조화된 데이터를 통해서 더 많은 일을 할 수 있습니다.',
                copy3: '공유 데이터 시각화',
                copy4: '공유 행태 분석 및 추적',
                copy5: '성과기반 정산 및 결제',
            },
            bass4: {
                copy1: '<b>리워드 분배 방식</b>',
                copy2:
                    '리워드는 <b>스마트 컨트랙트상 정의된 분배 로직</b>을 통해 자동으로 분배됩니다.<br/><br/>BuzLink간의 관계를 파악하고, 구매나 회원가입 같은 특정 행동을 추적하여, 각각 연결된 BuzLink에게 기여도(누가 영향을 많이 미쳤는가)에 따라 정확하고 공정하게 분배됩니다.<br/><br/>블록체인상에 구현된 스마트 컨트랙트 로직은 임의의 변경이 불가능하며, 조작이나 탈취 등의 <b>부정 행위가 원천적으로 불가능</b>합니다.',
            },
            bass5: {
                copy1: '<b>입소문 시각화</b>',
                copy2:
                    '구조화된 데이터를 통해 이전에는 얻을 수 없었던 새로운 데이터를 분석 할 수 있습니다.<br/><br/>공유된 링크의 수, 공유 관계간 영향, 시간별 공유 행태, 컨텐츠 별 확산력 비교 등 전에 없던 새로운 데이터를 <b>그래프로 시각화하여 손쉽게 분석</b>할 수 있습니다.',
            },

            copy_footer: '앱테크보다 <b>간단하게,</b> 알바보다 <b>짭짤하게</b>',
            copy_footer2:
                '앱테크보다 <b>간단하게,</b><br/>알바보다 <b>짭짤하게</b>',
        },
    },

    buzpointIntro: {
        copy1:
            '버즈링크는 좋은 <b>리워드(포인트)</b>가<br />무엇인지 고민해보았어요',
        copy2:
            '버즈링크를 통해 얻은 소중한 포인트, BP!<br /><b>제약은 줄이고</b>(1만 BP 이상부터)<br /><b>자유도는 최대로</b> (언제 어디서든 사용 가능)<br /><small>* 버즈포인트를 현금으로 출금 원하시는 분은<br/>support@buzlink.io로 문의 바랍니다.</small>',
        button: '지금 바로 버저되기!',
    },

    buzpoint: {
        total_reward: '총 지급',
        used_reward: '사용한 리워드',
        history: '히스토리',
        amountpaid: '지급 예정',
        warning:
            '지급예정 BP란?<br/>버즈링크를 통해 공유한 상품등의 구매가 실제 일어난 경우에 발생되는 BP로 구매 확정 후 지급될 BP입니다. 다만, 구매 이후에 취소 및 반품이 있는 경우는 예상BP와 실제 지급되는 BP가 다를 수 있습니다.',

        desc: '원한다면, 언제든 자유롭게<br/>리워드를 교환하세요.',
        myreward: '내 리워드',
        point_exchange: '포인트교환',
        tooltip: '(1BP는 1원으로 교환 됩니다)',
        filter: '1만 BP 이상 출금 가능합니다.',
        filter2: '5,000 BP 이상 출금 가능합니다.',
        exchange_way: '교환방법 선택',
        cash: {
            english:
                'Cash service is only provided by banks in korea<br/>Please change to korean',
            not_registered: {
                bank: '은행 선택',
                copy1: '본인 명의 계좌등록 후 서비스 이용이 가능합니다.',
                copy2: '- 을 제외한 숫자만 입력하세요',
                copy3: '계좌 등록 주의 사항',
                copy4: '계좌등록',
                copy5: '계좌 등록이 완료됐습니다.',

                warning1:
                    '1. 계좌 등록은 본인 명의 계좌만 등록 할 수 있습니다.',
            },
            registered: {
                copy1: '교환 받을 계좌 정보',
                copy2: '은행',
                copy3: '예금주',
                copy4: '계좌번호',
                success:
                    '출금 신청이 완료되었습니다. 은행 점검시간 외에 몇분 이내로 출금될 예정입니다.',
                warning:
                    '정산을 위해 매일 23:00~07:00까지 현금 교환 신청이 불가능합니다.',
            },
        },
        chai: {
            title: 'COMING SOON',
        },
        terra: {
            coin: '코인 선택',
            copy1: '환전 코인',
            copy2: '지갑주소',
            copy3: '메모 (선택사항)',
            copy4: '받는 수량',
            copy5: 'Paste',

            addressplaceholder: '거래소 지갑으로는 전송이 되지 않습니다.',

            warningtitle: 'Terra 교환 유의 사항',
            warning1:
                '<ol><li><b>거래소 트랜잭션 인식 문제로 인해 Terra 출금은 개인지갑(<a id="terraview_warning_a" target="_blank" href="https://terra.money">테라스테이션 https://terra.money</a>) 으로 부탁드립니다.</b></li><li>암호화폐 특성상 교환 신청 이후 취소가 불가합니다. 교환 시 지갑 주소를 꼭 확인 후 입력해 주시기 바랍니다.</li><li>교환 신청 이후 블록체인 네트워크에서 처리되기 때문에 블록체인 네트워크 상태에 따라 교환 시간이 지연될 수 있습니다. 블록체인 네트워크 이상 시, 버즈링크는 블록체인 네트워크에 개입할 수 없으므로, 지연 및 이상 현상 발생 시 추가 조치가 불가합니다.</li><li>테라 출금은 시스템 안정을 위해 KRT로만 가능합니다.</li></ol>',
            success: '로 출금이 완료 되었습니다.',

            withdrawtitle: '출금이 완료되었습니다.',
            withdrawtitle2: '지갑 주소',
            withdrawtitle3: '받는 수량',
            withdrawtitle4: '확인',
        },
        confirm: '교환 신청',
    },

    history: {
        title: '히스토리',
        used_point: '이용 포인트',
        total_reward: '내가 받은 총 리워드',
        used_reward: '사용한 리워드',
        detail: '상세 내역',

        filter0: '받은 리워드',
        filter1: '사용한 리워드',

        reason1: '리워드 보상 / 입금',
        reason2: '현금 수령 / 출금',
        reason3: '테라로 전송 / 출금',
        reason4: '버즈링크 보너스',

        pending: '지급예정',
        success: '완료',
        fail: '실패',
        cancel: '취소',

        withdraw_terra: '테라 출금',
        withdraw_cash: '원화 출금',
    },

    footer: {
        header: 'Show Webtoon',

        copy1: '이용약관',
        copy2: '개인정보처리방침',
        copy4: 'Copyright © Buzlink Co.,Ltd. All Rights Reserved.',
        copy5: 'Contact US',
        copy6:
            '(주)BreezeLabs  ㅣ  대표이사 이상화  ㅣ  사업자 등록번호 : 260-87-02130<br/>경기도 성남시 분당구 판교역로 225-15 우암빌딩 3층<br/>통신판매업신고번호: 2020-서울성동-20805<br/>Copyright © BreezeLabs Co.,Ltd. All Rights Reserved.',
        copy6_mobile:
            '(주)BreezeLabs  ㅣ  대표이사 이상화  ㅣ  사업자 등록번호 : 260-87-02130<br/>경기도 성남시 분당구 판교역로 225-15 우암빌딩 3층<br/>통신판매업신고번호: 2020-서울성동-20805<br/>Copyright © BreezeLabs Co.,Ltd. All Rights Reserved.',
        copy7: '공지사항',
        copy8: '블로그',
    },

    agreement: {
        copy0: '버즈링크 정책',
        copy1: '이용약관',
        copy2: '개인정보처리방침',
    },

    toonheader: {
        copy1:
            '버즈링크는 <b>‘링크로 공유하기’</b>를 통해<br/>리워드를 버는 서비스 입니다.<br/>그럼 어떻게 이용하는 지 알아볼까요?',
    },

    toon1: {
        msg1: 'Buz<br/>눌러서 공유',
        msg2: '# 버즈링크 버튼을 눌러서 버즈링크 생성하기!',
    },
    toon2: {
        msg1: '링크를<br/>누르면!',
    },
    toon3: {
        msg1: '맘에 들어❤︎',
        msg2: '버즈로<br/>공유!',
        msg3: '# 친구, 친친구들에게 퍼뜨리면',
    },
    toon6: {
        msg1: '1,000원',
        msg2: '2,500원',
        msg3: '4,000원',
        msg4: '구매확정!',
        msg5:
            '# 친구가 (구매) 했을 때<br/>연결된 버즈링크를 따라 가까운 연결순으로 리워드받기!',
    },
    toon7: {
        msg1: '나의 누적 리워드',
        price1: 10000,
        price2: 25000,
        prefix: '',
        suffix: ' 원',
    },

    main_popup_common4: '닫기',
    main_popup_common5: '확인',
    main_popup_common6: '오늘 하루동안 보지 않기',

    login: {
        login: 'Welcome',
        login_btn: '로그인',
        enter_email: '메일 입력',
        enter_pw1: '비밀번호 입력',
        enter_pw2: '비밀번호 재입력',
        logout: '로그아웃',
        welcome1_1: '비밀번호 찾기',
        welcome1_2: '비밀번호 변경',
        signup: '회원가입',
        forgot: '비밀번호를 잊어 버리셨나요?',
        forgot_title: '비밀 번호 찾기',
        forgot_desc:
            '비밀번호 분실 시, 메일을 통해 비밀번호 재설정을 도와드립니다.',
        forgot_send: '메일 발송',
        forgot_login: '로그인',
        forgot_msg:
            '메일을 발송 하였습니다. 메일을 확인 하시고 새로운 비밀번호를 설정하세요.',
        reset_pw_button: '변경하기',

        changepw1: '현재 비밀번호 입력',
        changepw2: '새로운 비밀번호 입력',
        changepw3: '비밀번호 확인',

        pwchange_done: '비밀번호가 변경되었습니다.<br/>다시 로그인 해주세요.',

        abusetitle1: '이상활동 감지 회원 안내',
        abusetitle1_mobile: '이상활동<br/>감지 회원 안내',
        abusetitle2: '확인',
        // abuse: '넌 어뷰징',
    },
    support: {
        title: 'FAQ',
        title2: '무엇을 도와 드릴까요?',
        subtitle1: '해결되지 않은 질문이 있으시다면',
        subtitle2: 'support@buzlink.io',
        subtitle3: '로 언제든 연락 주세요.',

        contents: {
            faq1: {
                title: "'버즈 링크(BuzLink)'는 어떤 서비스 인가요?",
                body:
                    "<ol><li>'버즈 링크(BuzLink)'란 웹페이지의 정보와 블록체인 정보가 결합된 URL 링크입니다.<br/></li><li>버즈링크로 친구나 지인에게 <b>공유를</b> 하면, 확산을 통해서 발생되는 여러가지 행동(회원가입, 구매, 조회 등등)에 따른 혜택을 <b>공유한 사람들에게 리워드로</b> 제공하는 서비스 입니다.<br/></li><li>리워드는 연결되어 있는 공유자 분들에게 <b>기여도에 따라 공정하게</b> 지급됩니다.</br>예를 들어, A 유저가 B유저에게, B유저가 C유저에게 공유를 했을 때, C유저의 링크를 통해 누군가가 구매를 한 경우, C유저의 기여도가 높아 C유저에게 가장 많은 리워드가 지급됩니다. (C>B>A 순서) 마찬가지로 B유저의 링크를 통해 구매가 일어난다면, B유저의 기여도가 높아 B유저에게 가장 많은 리워드가 지급됩니다. (B>A 순서)</li></ol>",
            },
            faq2: {
                title: '사전가입 이벤트는 무엇인가요?',
                body:
                    '<ol><li>사전가입 이벤트는 버즈링크 서비스의 정식 오픈 전에 미리 <b>버즈링크 서비스를 체험</b>하기 위해 준비한 이벤트입니다.<br/></li><li>이메일 입력 만으로도 참여하실 수 있고, 즉시 3,000원 상당의 포인트 (3,000BP)를 받으실 수 있습니다. 그리고 <b>무엇보다 중요한 것! 바로 생성된 버즈링크를 공유할수록 무제한 리워드를 받을 수 있습니다.</b></li></ol>',
            },
            faq3: {
                title: '어떻게 버즈링크를 만드나요?',
                body:
                    '<ol><li>사전 이벤트에서는 <b>이메일을 입력만으로</b> 버즈링크가 생성됩니다. <br/>(리워드를 받기 위해서는 이메일 인증을 통해 가입이 완료되어야 합니다.)</li><li>일반적으로 버즈링크를 만드는 방법은 제휴된 사이트에서 버즈링크 공유 버튼을 눌러 만들 수 있습니다. 정식 서비스 출시와 함께 상세한 방법은 추후 공지를 통해 알려 드리겠습니다.</li></ol>',
            },
            faq4: {
                title: '어떤 경우에 리워드를 받나요?',
                body:
                    "<ol><li>사전 이벤트에서는 이메일 입력 후에 입력하신 이메일을 통해 가입을 완료하시면 <b>3,000원의 리워드가 즉시</b> 지급됩니다. 그리고 생성된 나의 버즈링크를 통해 친구나 지인이 이벤트 참여하면 <b>무제한 리워드</b>를 받게 됩니다. 좀 더 자세한 내용은 이벤트 페이지를 참고해주세요.<br/></li><li>일반적으로 버즈링크를 통해 리워드를 받는 방법은 제휴된 사이트에서 버즈링크를 생성하고 이를 확산하면서 발생하는 <b>'트리거 액션'</b>을 기준으로 리워드를 받을 수 있습니다. 정식 서비스 출시와 함께 상세한 방법은 추후 공지를 통해 알려 드리겠습니다.</li></ol>",
            },
            faq5: {
                title: '받은 리워드는 어떻게 확인하고 사용하나요?',
                body:
                    '<ol><li>받은 리워드는 <b>"마이 버즈" > "리워드”</b> 탭에서 확인하고, 자유롭게 사용 하실 수 있습니다.<br/></li><li>내 계좌로 입금하기(1만 BP 부터 가능), 스테이블 코인 Terra로 출금하기, 차이머니로 전환(서비스 예정)이 가능합니다.</li></ol>',
            },
            faq6: {
                title: '리워드로 받은 BP를 어떻게 사용하나요?',
                body:
                    "<ol><li>리워드로 받은 <b>BP는 1BP당 1원의</b> 가치입니다.<br/></li><li>로그인 하신 후, \"리워드\" 탭에서 언제든 사용가능하며 '내 계좌로 입금' 의 경우 1만 BP 부터 등록된 계좌로 송금이 가능합니다. 'Terra로 출금하기'는 받으실 Terra 주소를 입력하면 금액과 상관 없이 보낼 수 있습니다.</li></ol>",
            },
            faq7: {
                title: '받은 리워드를 친구에게 보낼 수도 있나요?',
                body:
                    '<ol><li>네, 정식 버전에서 가능합니다. 정식버전은 2021년 1분기에 나올 예정입니다.</li></ol>',
            },
            faq8: {
                title: '친구에게 공유했는데 리워드를 받지 못했어요',
                body:
                    '<ol><li>친구가 사전 이벤트에 참여했는지 확인해보세요. 친구분이 사전 이벤트에 참여하셨다면, <b>"마이 버즈" -> "리워드”</b> 에서 리워드를 확인할 수 있습니다. <br/></li><li> 이벤트 참여 완료 후, 블록체인 전송속도에 따라 리워드 지급까지 최대 10분 이내로 걸릴 수 있습니다.</li></ol>',
            },
            faq9: {
                title: '왜 블록체인을 사용하나요?',
                body:
                    '<ol><li>블록체인의 가장 큰 특징은, <b>투명성과 조작 불가능함</b> 입니다. 버즈링크의 리워드는 정확하고 투명하게 분배되고, 어느 누구도 개입할수 없도록 <b>블록체인 위에서</b> 동작 됩니다.<br/></li><li>버즈링크는 이러한 블록체인의 장점을 활용하기 위하여 Terra의 블록체인을 사용합니다.</li></ol>',
            },
            faq10: {
                title: '버즈링크를 통해 마케팅/홍보하고 싶어요',
                body:
                    '<ol><li>지금 바로 <b>Contact Us</b>를 눌러주세요! 설레는 마음으로 연락 드리겠습니다.</li></ol>',
            },
            faq11: {
                title: '왜 리워드 지급이 안 되나요?',
                body:
                    '<ol><li>리워드는 지급이 느리게 될순 있으나, 미지급 되진 않습니다.</li><li>버즈링크는 블록체인으로 동작하고 있습니다. 블록체인은 내부적으로 검증 절차를 진행하고, 그 때문에 기존 서버모델에 비해서 속도가 느립니다. 하지만, 한번 진행된 요청은 되돌릴수 없으며, 분배 요청된 순서에 따라 순차적으로 진행됩니다.</li><li>갑자기 트래픽 상승으로 리워드 지급이 지연이 될수 있습니다. 미지급은 되지 않으니 안심하시고 조금만 기다려 주세요.</li><li>연결된 버즈링크 숫자는 자신의 버즈링크와 연결이 된 모든 버즈링크의 수를 말합니다. 친친친친...친친구 까지 말이죠. 이번 사전 참여 이벤트의 리워드는 ‘친친친구‘까지 지급됩니다. 따라서 연결된 버즈링크 숫자와 리워드 지급은 비례하지 않을 수 있습니다.</li></ol>',
            },
            faq12: {
                title: '탈퇴가 안되요.',
                body:
                    '<ol><li>불편을 드려서 죄송합니다.<br/>현재 버즈링크는 사전 이벤트만 오픈 중입니다.</li><li>탈퇴기능은 정식 서비스 오픈 후 지원됩니다.</li></ol>',
            },
            faq13: {
                title: '이상활동 안내 팝업은 어떤 경우에 나오나요?',
                body:
                    '<ol><li>이상 활동은 정상적인 서비스 이용이 아닌 비정상적인 방법으로 접근 하였을때 저희 감지 시스템이 찾아냅니다. “난 정상적인 방법으로 참여했는데?” 하시는 분들은 <a href="mailto://support@buzlink.io">support@buzlink.io</a> 로 문의 해주세요.</li></ol>',
            },
            faq14: {
                title: '이상 활동은 무엇인가요?',
                body:
                    '<ol><li>버즈링크 팀은 건전하고 원활한 공유를 위하여 이상감지 시스템을 운용하고 있습니다. 아래와 같은 공유행동은 이상활동에 탐지되어 불이익이 발생할 수 있어요. </li><ol style="list-style-type: none"><li>1. 무분별한 도배나 반복적인 공유 하면 안되요!</li><li>2. 주제나 컨텐츠와 맞지 않는 무차별 포스팅은 그만 해주세요!</li><li>3. 비 정상적인 방법을 통해 무분별한 계정 생성하는 행위는 안되요!</li></ol><li>이상 활동계정으로 탐지된 경우, 안내에 따라 메일 회신해 주세요, 경우에 따라 활동의 제약 및 리워드 이용이 불가할 수 있다는 점 꼭 기억해주세요!</li></ol>',
            },
            faq15: {
                title: '거래소로 출금한 유저에게 안내를 위한 FAQ추가',
                body:
                    '<ol><li>실수로 거래소로 출금 하셨나요? 거래소로의 출금은 거래소에서만 처리 할 수 있습니다.</li><li>버즈링크에서 거래소로의 출금은 불가합니다. 처리 방법을 모르신다면, 출금한 거래소, 출금한 지갑 주소, 금액을 <a href="mailto:support@buzlink.io">support@buzlink.io</a>로 보내주세요.저희가 도와 드리겠습니다.</li></ol>',
            },
        },
    },
    contactus: {
        title: '기하급수적인 확산과<br/>빠른 입소문을 위한 최적의 선택',
        title_2: '기하급수적인 확산과<br/>빠른 입소문을 위한<br/>최적의 선택',
        subtitle1: '버즈링크는',
        subtitle2:
            '강력한 입소문 효과를 만들며, 세계 최초로 입소문 효과에 대한<br/>데이터를 제공합니다.',
        subtitle3:
            '블록체인을 통해 투명한 성과 추적과 성과 기반의 합리적인 마케팅 비용을<br/>제안합니다.',
        subtitle2_2:
            '강력한 입소문 효과를 만들며,<br/>세계 최초로 입소문 효과에 대한 데이터를 제공합니다.',
        subtitle3_2:
            '블록체인을 통해 투명한 성과 추적과<br/>성과 기반의 합리적인 마케팅 비용을 제안합니다.',
        msg1: 'Contact Us',
        msg2:
            '버즈링크에 관심 가져주셔서 감사합니다.<br/>연락받으실 정보를 남겨주시면 담당자가 빠른 시일 내에 연락드리겠습니다',
        msg2_2:
            '버즈링크에 관심 가져주셔서 감사합니다.<br/>연락받으실 정보를 남겨주시면 담당자가 빠른 시일 내에<br/>연락드리겠습니다',
        agreetitle: '아래 항목에 동의 합니다.',
        agreesubtitle1: 'Buzlink <b>서비스 이용약관</b> 및',
        agreesubtitle2: '<b>개인정보 취급 및 이용</b> 에 모두 동의 합니다.',
        complete: '완료',

        registered: '등록 되었습니다.',
    },
    initpw: {
        title: '버저가 되신것을 환영합니다',
        subtitle: '비밀번호를 설정하여 가입을 완료해주세요.',
        msg1: '비밀번호 설정',
        msg2: '비밀번호',
        msg3: '비밀번호 확인',
        // tooltip1: '비밀번호는 대문자, 소문자, 숫자 포함 10자 이상의 문자로 구성됩니다.',
        tooltip1: '특수기호 포함 10글자 이상 입력하세요.',
        msg4: '휴대폰 번호 입력',
        tooltip2: '비밀번호가 다릅니다.',
        msg5: '재발송',
        msg6: '인증번호 발송',
        msg7: '인증번호 입력',
        msg8: 'Buzlink <b>서비스 이용약관</b> 및',
        msg9: '<b>개인정보 취급 및 이용</b> 에 모두 동의 합니다.',
        msg10: '프로모션 정보 수신에 동의합니다.(선택)',
        msg11: '아래 내용에 모두 동의합니다.',
        complete: '완료',

        country: '국가 선택',

        loading1: '블록체인 지갑 및 데이터 생성 중',
        loading2: '버즈링크 스마트 컨트랙트에 등록 중',
        loading3: '리워드 지급 중',

        join_done: '회원가입이 완료되었습니다!',
        showpw: 'Show password',

        delayed:
            '현재 리워드 지급이 지연되고 있습니다.<br/>비즈링크를 통해 생성된 링크 공유를 진행해 주세요.<br/>지급 받지 못한 리워드는 최대 24시간 안에 지급될 예정입니다.<br/>불편을 끼쳐드려 죄송합니다.',
    },

    event: {
        resend_success:
            '메일을 발송 하였습니다.<br/>메일을 확인 하시고 새로운 비밀번호를 설정하세요.',
        resend_cancel: '취소',
        resend_send: '이메일 재발송',
    },

    sdkPopup: {
        step0: {
            title1: '앱테크보다 <b>간단하게</b><br/>알바보다 <b>짭짤하게',
            title2: '이메일 입력만으로 버즈링크 생성해 보세요.',
            placeholder: '이메일을 입력해 주세요',
            title3: '계정 변경',
            button: '공유 링크 생성',
        },
        step1: {
            title1: '생성된 <b>버즈링크를</b><br />친구들에게 <b>공유</b>',
            copy: '링크 복사',
            title2: '버저가 되신 것을 환영합니다.',
            title4: '메일 발송하였습니다.',
            title5: '발송된 메일을 통해 리워드를 확인해 보세요.',
            title6:
                '<b>잠깐!</b> 생성된 버즈링크는 친구들에게 공유 먼저 하세요!',
        },

        footer1: {
            msg1: '버즈링크가 궁금하신가요?&nbsp;',
            msg2: '홈페이지로 이동',
        },
        footer2: {
            msg1: '내가 받은 리워드가 궁금하신가요?&nbsp;',
            msg2: '리워드 확인하러가기',
        },
    },
    unsubscribe: {
        title: '메일 수신거부',
        subtitle1: '버즈링크 메일 수신거부 페이지 입니다.',
        subtitle2: '이 발송인의 이메일을 수신거부 하시겠습니까?',
        yes: '예',
        no: '아니오',
        done: '수신거부가 완료됐습니다.',
    },

    buzfriends: {
        more: '더보기',
        hide: '줄이기',
    },

    join: {
        title: 'Hi! New Buzzer',
        body1: '새 계정 만들기',
        body2: '이메일 입력만 해도 버저가 될 수 있어요!',
        enter_email: '메일 입력',
        button: '가입 하기',
        login: '로그인',

        done_title: 'Congratulation',
        done_message1: '버저가 되신 것을 환영합니다.',
        done_message2: '발송된 메일을 통해<br/>회원가입을 완료해주세요.',
        done_button: '확인',

        already_title: 'Why?',
        already_body1: '이미 사용중인 메일입니다.',
        already_body2: '비밀번호를 잊어 버리셨나요?',
        already_button1: '돌아가기',
        already_button2: '비밀번호 찾기',

        resend_title: 'Why?',
        resend_body1: '회원가입이 아직 완료되지 않은<br/>메일입니다.',
        resend_body2: '메일을 받지 못하셨다면<br/>재발송 버튼을 눌러주세요.',

        resend_body3:
            '회원가입이 아직 완료되지 않은 메일입니다. 메일을 받지 못하셨다면 재발송 버튼을 눌러주세요.',

        resend_button1: '돌아가기',
        resend_button2: '재발송',
    },

    buzmapIntro: {
        copy1:
            '내가 공유한 <b>링크가<br />어떻게 퍼졌는지</b> 눈으로 본적있어요?',
        copy2:
            '버즈맵은 버저가 발급하고<br />공유한 버즈링크가 어떻게  퍼졌는지 <b>그래프로</b> 보여드립니다.<br />지금까지 없었던 <b>공유지도</b>, 한번 경험해 보세요!',
        button: '지금 바로 버저되기!',
    },
    buzmaplist: {
        desc: '지금까지 없었던 공유지도,<br/>한번 경험해 보세요!',
        copy2: '공유하기',
        copy3: '종료',
        share: '연결된 버즈링크',
        reward: '리워드',
        people: '명',
        total_reward: '내가 받은 총 리워드',
        nolist: '아직 공유한 버즈링크가 없네요 ;;',
        genbutton: '버즈링크 만들기',
        all: '전체',
        favorite: '즐겨찾기',
        nofav: '즐겨찾기한 버즈링크가 없습니다.',
    },
    buzmap: {
        share: '공유',
        people: '명',
        connected: '연결된 버즈링크',
        people2: '명',
        people3: '건',
        me: '나',
        friend1: '친구',
        friend2: '친친구',
        friend3: '친친친구',
        friend4: '그외',

        warning1:
            '<ol><li>버즈링크의 서로 연결된 모습을 보여주며, 리워드 지급 금액과 다를 수 있습니다.</li></ol>',
        warning2:
            '<ol><li>연결된 전체 링크의 숫자와 리워드 지급 금액은 다를 수 있습니다.</li><li>리워드는 사용자가 지정된 행동을 했을 때 지급되며, 연결된 링크를 통해 분배 됩니다.</li></ol>',
    },

    myinfo: {
        info: '기본정보',
        name: '이름',
        id: 'ID',
        phone: '휴대폰',
        pw: '비밀번호',
        bank: '계좌 정보',
        change: '변경하기',
        promotion: '정보 수신',
        promotionallow: '프로모션 정보 수신 여부',

        copy1: '휴대폰 변경 시 본인확인 절차가 필요합니다.',
        copy3:
            '메일을 발송 하였습니다. 메일을 확인 하시고 새로운 비밀번호를 설정하세요.',
    },
}
