import React, { useContext, useEffect } from "react";
import { ConfigContext } from "../../App/ConfigProvider";
import * as Utils from '../../App/Utils'
import "./MainCard3.css";

export default function MainCard3({ page }) {
    const {
        language,
        formattedTranslations,
    } = useContext(ConfigContext);

    useEffect(() => {
        const copy = document.getElementById('maincard3_copy')
        const copy_mobile = document.getElementById('maincard3_copy_mobile')

        const bg = document.querySelectorAll('.maincard3_bg')

        const icons = document.querySelectorAll('.maincard3_icon')

        const icon1 = document.getElementById('maincard3_icon1')
        const icon2 = document.getElementById('maincard3_icon2')
        const icon3 = document.getElementById('maincard3_icon3')
        const icon4 = document.getElementById('maincard3_icon4')
        const icon5 = document.getElementById('maincard3_icon5')
        const icon6 = document.getElementById('maincard3_icon6')
        const icon7 = document.getElementById('maincard3_icon7')
        const icon8 = document.getElementById('maincard3_icon8')
        const icon9 = document.getElementById('maincard3_icon9')
        const icon10 = document.getElementById('maincard3_icon10')
        const icon11 = document.getElementById('maincard3_icon11')
        const icon12 = document.getElementById('maincard3_icon12')
        const icon13 = document.getElementById('maincard3_icon13')
        const icon14 = document.getElementById('maincard3_icon14')
        const icon15 = document.getElementById('maincard3_icon15')
        const icon16 = document.getElementById('maincard3_icon16')
        const icon17 = document.getElementById('maincard3_icon17')
        const icon18 = document.getElementById('maincard3_icon18')
        const icon19 = document.getElementById('maincard3_icon19')
        const icon20 = document.getElementById('maincard3_icon20')
        const icon21 = document.getElementById('maincard3_icon21')
        const icon22 = document.getElementById('maincard3_icon22')
        const icon23 = document.getElementById('maincard3_icon23')

        try {
            if (page === 2) {
                setTimeout(() => {
                    copy.style.opacity = 1
                    copy_mobile.style.opacity = 1
                }, 1500);

                setTimeout(() => {
                    for (let i = 0; i < icons.length; i++) {
                        icons[i].style.transform = 'scaleX(1) scaleY(1)'
                        icons[i].style.opacity = 1
                    }

                    if (!Utils.isMobile()) {

                        icon1.style.left = 839 + 'px'
                        icon1.style.top = (699 / 1080 * window.innerHeight) + 'px'
                        icon2.style.left = 1013 + 'px';
                        icon2.style.top = (684 / 1080 * window.innerHeight) + 'px'
                        icon3.style.left = 1291 + 'px'
                        icon3.style.top = (745.6 / 1080 * window.innerHeight) + 'px'
                        icon4.style.left = 587 + 'px'
                        icon4.style.top = (336.6 / 1080 * window.innerHeight) + 'px'
                        icon5.style.left = 694 + 'px'
                        icon5.style.top = (615.6 / 1080 * window.innerHeight) + 'px'
                        icon6.style.left = 1268 + 'px'
                        icon6.style.top = (526.6 / 1080 * window.innerHeight) + 'px'
                        icon7.style.left = 772 + 'px'
                        icon7.style.top = (479.6 / 1080 * window.innerHeight) + 'px'
                        icon8.style.left = 1101 + 'px'
                        icon8.style.top = (483.6 / 1080 * window.innerHeight) + 'px'
                        icon9.style.left = 794 + 'px'
                        icon9.style.top = (255.6 / 1080 * window.innerHeight) + 'px'
                        icon10.style.left = 716 + 'px'
                        icon10.style.top = (783.6 / 1080 * window.innerHeight) + 'px'
                        icon11.style.left = 853 + 'px'
                        icon11.style.top = (342.6 / 1080 * window.innerHeight) + 'px'
                        icon12.style.left = 586 + 'px'
                        icon12.style.top = (483.6 / 1080 * window.innerHeight) + 'px'
                        icon13.style.left = 1133 + 'px'
                        icon13.style.top = (236.6 / 1080 * window.innerHeight) + 'px'
                        icon14.style.left = 1257 + 'px'
                        icon14.style.top = (606.6 / 1080 * window.innerHeight) + 'px'
                        icon15.style.left = 1171 + 'px'
                        icon15.style.top = (701.6 / 1080 * window.innerHeight) + 'px'
                        icon16.style.left = 1045 + 'px'
                        icon16.style.top = (283.6 / 1080 * window.innerHeight) + 'px'
                        icon17.style.left = 1157 + 'px'
                        icon17.style.top = (614.6 / 1080 * window.innerHeight) + 'px'
                        icon18.style.left = 681 + 'px'
                        icon18.style.top = (313.6 / 1080 * window.innerHeight) + 'px'
                        icon19.style.left = 828 + 'px'
                        icon19.style.top = (595.6 / 1080 * window.innerHeight) + 'px'
                        icon20.style.left = 568 + 'px'
                        icon20.style.top = (682.6 / 1080 * window.innerHeight) + 'px'
                        icon21.style.left = 1112 + 'px'
                        icon21.style.top = (757.6 / 1080 * window.innerHeight) + 'px'
                        icon22.style.left = 1253 + 'px'
                        icon22.style.top = (336.6 / 1080 * window.innerHeight) + 'px'
                        icon23.style.left = 1253 + 'px'
                        icon23.style.top = (469.6 / 1080 * window.innerHeight) + 'px'
                    } else {
                        icon1.style.left = (110 / 320 * window.innerWidth) + 'px'
                        icon1.style.top = (343 / 551 * window.innerHeight) + 'px'
                        icon2.style.left = (179 / 320 * window.innerWidth) + 'px'
                        icon2.style.top = (337 / 551 * window.innerHeight) + 'px'
                        icon3.style.left = (290 / 320 * window.innerWidth) + 'px'
                        icon3.style.top = (361 / 551 * window.innerHeight) + 'px'
                        icon4.style.left = (9 / 320 * window.innerWidth) + 'px'
                        icon4.style.top = (198 / 551 * window.innerHeight) + 'px'
                        icon5.style.left = (52 / 320 * window.innerWidth) + 'px'
                        icon5.style.top = (310 / 551 * window.innerHeight) + 'px'
                        icon6.style.left = (281 / 320 * window.innerWidth) + 'px'
                        icon6.style.top = (274 / 551 * window.innerHeight) + 'px'
                        icon7.style.left = (83 / 320 * window.innerWidth) + 'px'
                        icon7.style.top = (255 / 551 * window.innerHeight) + 'px'
                        icon8.style.left = (214 / 320 * window.innerWidth) + 'px'
                        icon8.style.top = (257 / 551 * window.innerHeight) + 'px'
                        // icon9.style.left = 794 + 'px'
                        // icon9.style.top = (255.6 / 551 * window.innerHeight) + 'px'
                        icon10.style.left = (60 / 320 * window.innerWidth) + 'px'
                        icon10.style.top = (377 / 551 * window.innerHeight) + 'px'
                        // icon11.style.left = 853 + 'px'
                        // icon11.style.top = (342.6 / 551 * window.innerHeight) + 'px'
                        icon12.style.left = (8 / 320 * window.innerWidth) + 'px'
                        icon12.style.top = (257 / 551 * window.innerHeight) + 'px'
                        icon13.style.left = (227 / 320 * window.innerWidth) + 'px'
                        icon13.style.top = (158 / 551 * window.innerHeight) + 'px'
                        icon14.style.left = (277 / 320 * window.innerWidth) + 'px'
                        icon14.style.top = (306 / 551 * window.innerHeight) + 'px'
                        icon15.style.left = (242 / 320 * window.innerWidth) + 'px'
                        icon15.style.top = (344 / 551 * window.innerHeight) + 'px'
                        icon16.style.left = (192 / 320 * window.innerWidth) + 'px'
                        icon16.style.top = (176 / 551 * window.innerHeight) + 'px'
                        icon17.style.left = (237 / 320 * window.innerWidth) + 'px'
                        icon17.style.top = (308 / 551 * window.innerHeight) + 'px'
                        icon18.style.left = (47 / 320 * window.innerWidth) + 'px'
                        icon18.style.top = (188 / 551 * window.innerHeight) + 'px'
                        icon19.style.left = (105 / 320 * window.innerWidth) + 'px'
                        icon19.style.top = (301 / 551 * window.innerHeight) + 'px'
                        icon20.style.left = (1 / 320 * window.innerWidth) + 'px'
                        icon20.style.top = (336 / 551 * window.innerHeight) + 'px'
                        icon21.style.left = (219 / 320 * window.innerWidth) + 'px'
                        icon21.style.top = (366 / 551 * window.innerHeight) + 'px'
                        icon22.style.left = (275 / 320 * window.innerWidth) + 'px'
                        icon22.style.top = (198 / 551 * window.innerHeight) + 'px'
                        icon23.style.left = (275 / 320 * window.innerWidth) + 'px'
                        icon23.style.top = (251 / 551 * window.innerHeight) + 'px'
                    }

                }, 2000);


                setTimeout(() => {
                    for (let i = 0; i < bg.length; i++) {
                        bg[i].style.opacity = 1
                    }
                }, 2500);


            } else {
                setTimeout(() => {
                    copy.style.opacity = 0
                    copy_mobile.style.opacity = 0
                }, 500);

                for (let i = 0; i < bg.length; i++) {
                    bg[i].style.opacity = 0
                }

                const wrapper = document.getElementById('maincard3_wrapper')
                for (let i = 0; i < icons.length; i++) {
                    icons[i].style.left = wrapper.clientWidth / 2 + 'px';
                    icons[i].style.top = window.innerHeight / 2 + 'px';
                    icons[i].style.transform = 'scaleX(0.01) scaleY(0.01)'
                    icons[i].style.opacity = 0
                }

            }
        } catch (e) {

        }
    }, [page])

    return (
        <div id='maincard3_bg'>

            <div id='maincard3_wrapper'>

                <div id='maincard3_bg1' className='maincard3_bg' />
                <div id='maincard3_bg2' className='maincard3_bg' />
                <div id='maincard3_bg3' className='maincard3_bg' />


                <div id='maincard3_icon1' className='maincard3_icon' />
                <div id='maincard3_icon2' className='maincard3_icon' />
                <div id='maincard3_icon3' className='maincard3_icon' />
                <div id='maincard3_icon4' className='maincard3_icon' />
                <div id='maincard3_icon5' className='maincard3_icon' />
                <div id='maincard3_icon6' className='maincard3_icon' />
                <div id='maincard3_icon7' className='maincard3_icon' />
                <div id='maincard3_icon8' className='maincard3_icon' />
                <div id='maincard3_icon9' className='maincard3_icon' />
                <div id='maincard3_icon10' className='maincard3_icon' />
                <div id='maincard3_icon11' className='maincard3_icon' />
                <div id='maincard3_icon12' className='maincard3_icon' />
                <div id='maincard3_icon13' className='maincard3_icon' />
                <div id='maincard3_icon14' className='maincard3_icon' />
                <div id='maincard3_icon15' className='maincard3_icon' />
                <div id='maincard3_icon16' className='maincard3_icon' />
                <div id='maincard3_icon17' className='maincard3_icon' />
                <div id='maincard3_icon18' className='maincard3_icon' />
                <div id='maincard3_icon19' className='maincard3_icon' />
                <div id='maincard3_icon20' className='maincard3_icon' />
                <div id='maincard3_icon21' className='maincard3_icon' />
                <div id='maincard3_icon22' className='maincard3_icon' />
                <div id='maincard3_icon23' className='maincard3_icon' />

                <div id='maincard3_copy'>
                    {formattedTranslations(language.translations.main.card3.copy1)}
                </div>

                <div id='maincard3_copy_mobile'>
                    {formattedTranslations(language.translations.main.card3.copy2)}
                </div>
            </div>
        </div >
    )
}