import React, { useEffect, useContext } from "react";
import "./CommonPopup.css";
import { PopupContext } from "../PopupProvider";
import { ConfigContext } from "../ConfigProvider";

export default function CommonPopup() {
  const { language, formattedTranslations } = useContext(ConfigContext);
  const { popupInfo, hidePopup } = useContext(PopupContext);
  useEffect(() => {
    if (popupInfo && popupInfo.type <= 1) {
      const obj = document.querySelector(".common_popup_wrapper");
      if (obj) {
        obj.style.opacity = 0.8;
        setTimeout(() => {
          if (obj !== null) {
            obj.style.opacity = 0.0;
          }
          setTimeout(() => {
            hidePopup();
          }, 500);
        }, 1500);
      }
    }
  }, [popupInfo, hidePopup]);

  if (popupInfo === null || popupInfo === undefined) {
    return <div />;
  } else if (popupInfo.type === 0) {
    //자동으로 사라지는 success
    return (
      <div id="common_popup_bg">
        <div className="common_popup_wrapper">
          <img id="common_popup_icon" src={require("./resources/icon-success.png")} alt="" />
          <div id="common_popup_label">{popupInfo.message}</div>
        </div>
      </div>
    );
  } else if (popupInfo.type === 1) {
    //자동으로 사라지는 fail
    return (
      <div id="common_popup_bg">
        <div className="common_popup_wrapper">
          <img id="common_popup_icon" src={require("./resources/icon-error.png")} alt="" />
          <div id="common_popup_label">{popupInfo.message}</div>
        </div>
      </div>
    );
  } else if (popupInfo.type === 2) {
    //계속 떠있는 '확인'
    return (
      <div id="common_popup_bg" onClick={() => {
        console.log('popup')
      }}>
        <div className="common_popup_wrapper2">
          <div id="common_popup_label2">{formattedTranslations(popupInfo.message)}</div>
          <div
            id="common_popup_button2"
            onClick={() => {
              if (popupInfo.callback) {
                popupInfo.callback()
                hidePopup();
              } else {
                hidePopup();
              }
            }}
          >
            {language.translations.main_popup_common4}
          </div>
        </div>
      </div>
    );
  } else if (popupInfo.type === 3) {
    //copy '확인'
    const title = popupInfo.message.title
    const link = popupInfo.message.link

    return (
      <div id="common_popup_bg" onClick={() => {
        console.log('popup')
      }}>
        <div className="common_popup_wrapper3">
          <div id="common_popup_label3_1">{formattedTranslations(language.translations.copied2)}</div>
          <div id='common_popup_label3_dummy' />
          <div id="common_popup_label3_2">{title}</div>
          <div id="common_popup_label3_3">{link}</div>
          <div
            id="common_popup_button3"
            onClick={() => {
              if (popupInfo.callback) {
                popupInfo.callback()
                hidePopup();
              } else {
                hidePopup();
              }
            }}
          >
            {language.translations.main_popup_common4}
          </div>
        </div>
      </div>
    );
  }
}
