import React, { createContext, useState } from 'react'
import { en, ko, getTranslation } from './Localization'
import * as Utils from './Utils'

export const EN = 'en'
export const KR = 'ko-KR'

export function ConfigProvider({ children }) {
    // 9

    const [navTheme, setNavTheme] = useState(0)
    //0 default
    //1 main1, main4에서 사용. bg: transparent, logo: white,  login: white, highlight: orange, language: white
    //2 main2, main5에서 사용. bg: transparent, logo: orange, login: gray,  highlight: orange, language: gray
    //3 main3에서 사용.        bg: transparent, logo: white,  login: white, highlight: white,  language: white

    const [language, setLanguage] = useState({
        type: loadLanguage(),
        translations: loadLanguage() === KR ? ko : en,
    }) //0:en, 1:ko

    function setlan(lan) {
        saveLanguage(lan)

        setLanguage({
            type: lan,
            translations: lan === EN ? en : ko,
        })
    }

    function formattedTranslations(text) {
        return getTranslation(text)
    }

    function getTranslations() {
        const language = loadLanguage()
        if (language === EN) {
            return en
        } else if (language === KR) {
            return ko
        }

        return ko
    }

    return (
        <ConfigContext.Provider
            value={{
                formattedTranslations: formattedTranslations,
                getTranslations: getTranslations,
                language: language,
                setLanguage: setlan,
                navTheme: navTheme,
                setNavTheme: setNavTheme,
            }}
        >
            {children}
        </ConfigContext.Provider>
    )
}

export const ConfigContext = createContext({
    formattedTranslations: (text) => {},
    getTranslations: () => {},
    language: {},
    setLanguage: (lan) => {},
    navTheme: 0,
    setNavTheme: (t) => {},
})

function saveLanguage(lan) {
    Utils.saveLocalStorage('language', { language: lan })
}

function loadLanguage() {
    let navLanguage =
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage

    if (navLanguage !== EN && navLanguage !== KR) {
        navLanguage = KR
    }

    let obj = Utils.loadLocalStorage('language', { language: navLanguage })
    return obj.language
}
