import React, { useEffect, useContext, useState } from 'react'
import './BuzFriends.css'

import { ConfigContext } from '../App/ConfigProvider.js'
import { Helmet } from 'react-helmet'

export default function BuzFriends({ ...props }) {
    const { language, formattedTranslations } = useContext(ConfigContext)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div id="buzfriends_container">
            <Helmet>
                <title>{language.translations.titles.buzfriends}</title>
                <meta
                    name="description"
                    content="공유를 통해 혜택을 나누는 버즈링크"
                />
            </Helmet>

            <div id="buzfriends_wrapper">
                <div id="buzfriends_bg3">
                    <div id="buzfriends_bg3_1" />
                </div>
                <div id="buzfriends_bg4" />

                <div className={'buzfriends_img'} />
                <div className={'buzfriend_title1'}>
                    {'“버즈링크와 함께해 주신 고객분들에게 감사드리며'}
                    <br />
                    {'향후 더 좋은 모습으로 찾아뵙겠습니다.”'}
                </div>
                <div className={'buzfriend_title2'}>
                    {'“버즈링크와 함께해 주신'}
                    <br />
                    {'고객분들에게 감사드리며 향후 더'}
                    <br />
                    {'좋은 모습으로 찾아뵙겠습니다.”'}
                </div>

                <div className={'buzfriend_title3'}>{'감사합니다.'}</div>
            </div>
        </div>
    )
}
