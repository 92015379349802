import React, { createContext, useState } from "react";

export function PopupProvider({ children }) {
  // 9

  const [popupInfo, setPopupInfo] = useState(null);

  function show(type, message, callback) {
    setPopupInfo({
      type: type,
      message: message,
      callback: callback
    });

    if (type >= 2) {
      document.querySelector("body").style.overflow = "hidden";
    }
  }

  function hide() {
    setPopupInfo(null);
    document.querySelector("body").style.overflow = "auto";
  }

  return (
    <PopupContext.Provider
      value={{
        showPopup: show,
        hidePopup: hide,
        popupInfo: popupInfo,
      }}
    >
      {children}
    </PopupContext.Provider>
  );
}

export const PopupContext = createContext({
  showPopup: (type, message, callback) => { },
  hidePopup: () => { },
  popupInfo: { type: 0, message: "" },
});
